import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import { Link, Outlet, useNavigate } from "react-router-dom";
import {
  Button,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import batukLogo from "../assets/images/Batuk Logo.png";
import BatukLogo from "../images/Batuk-logo.png";
import notificationLogo from "../assets/Icons/Notification.png";
import faqLogo from "../assets/Icons/Faq.png";
import MenuBar from "../assets/Icons/menuicon.png";
import SignOutBlueLogo from "../assets/Icons/Sign out 2.png";
import ProfileLogo from "../assets/Icons/ProfileIcon.png";
import GoldImg from "../assets/Icons/GoldIcon.png";
import SilverImg from "../assets/Icons/silverIcon.png";
import Home from "../assets/Icons/Home.png";
import HomeHover from "../assets/Icons/Home 2.png";
import Earning from "../assets/Icons/3d earnings.png";
import EarningHover from "../assets/Icons/3d earnings 3.png";
import Client from "../assets/Icons/Client lists.png";
import ClientHover from "../assets/Icons/Client Lists 2.png";
import Employees from "../assets/Icons/Employees.png";
import EmployeesHover from "../assets/Icons/Employees 2.png";
import SIP from "../assets/Icons/Sip.png";
import SIPHover from "../assets/Icons/Sip 2.png";
import FD from "../assets/images/almera_gold.png";
import FDHover from "../assets/images/almera_gold.png";
import Proposal from "../assets/Icons/Proposal.png";
import ProposalHover from "../assets/Icons/Proposal 2.png";
import Reports from "../assets/Icons/Report.png";
import ReportsHover from "../assets/Icons/Report 2.png";
import ImpFiles from "../assets/Icons/Bulk import.png";
import ImpFilesHover from "../assets/Icons/Bulk import 2.png";
import SpotGold from "../assets/Icons/spotgoldcoin.png";
import SpotGoldHover from "../assets/Icons/spotgoldcoin.png";

import { baseUrl } from "../utils/baseUrl";

const settings = ["Profile", "Reset Password", "Logout"];

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const ListItemButtonStyled = styled(ListItemButton)(({ theme }) => ({
  "&:hover .icon": {
    display: "none",
  },
  "&:hover .icon-hover": {
    display: "block",
  },
  ".icon-hover": {
    display: "none",
  },
}));

const defaultTheme = createTheme();

export default function DashBoard() {
  const [open, setOpen] = React.useState(true);
  const [silverData, setSilverData] = React.useState(null);
  const [goldData, setGoldData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [profileData, setProfileData] = React.useState({
    name: "",
    email: "",
  });
  const isSmallScreen = useMediaQuery("(max-width:900px)");

  const toggleDrawer = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    setOpen(!isSmallScreen);
  }, [isSmallScreen]);

  const Token = sessionStorage.getItem("token");

  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
    if (open === true) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const getProfileData = async () => {
    try {
      const url = `${baseUrl}/profile`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      const profile = response.data.data[0];
      console.log("Profile data", profile);
      setProfileData(profile);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const id = "gold";
  const fetchLiveData = async (id, setData) => {
    try {
      const url = `${baseUrl}/liveprice/${id}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      const data = response.data.data;
      console.log(`Data for ${id}=`, data);
      setData(data);
      setLoading(false);
    } catch (error) {
      console.error(`Error fetching data for ${id}`, error);
      setError("Failed to fetch data. Please try again later.");
      setLoading(false);
    }
  };

  const callLogoutAPI = async () => {
    try {
      const url = `${baseUrl}/partner/logout`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };

      const response = await fetch(url, {
        method: "POST",
        headers: headers,
      });

      const data = await response.json();
      console.log("Logout API", data);
      if (data.message === "user logged out successfully") {
        navigate("/login");
      } else {
        alert("Issue in logout");
      }
      return data;
    } catch (error) {
      console.error("Error connecting to API", error);
      throw error;
    }
  };

  const handleLogout = () => {
    callLogoutAPI();
    navigate("/");
    sessionStorage.removeItem("token");
  };

  const [hoverStates, setHoverStates] = React.useState({
    home: false,
    earning: false,
    clients: false,
    employees: false,
    purposal: false,
    reports: false,
  });

  const [activeItem, setActiveItem] = React.useState("");
  const [hover, setHover] = React.useState(false);
  const handleListItemClick = (item) => {
    setActiveItem(item);
  };

  const handleMouseEnter = (item) => {
    setHoverStates((prevState) => ({
      ...prevState,
      [item]: true,
    }));
  };

  const handleMouseLeave = (item) => {
    setHoverStates((prevState) => ({
      ...prevState,
      [item]: false,
    }));
  };

  React.useEffect(() => {
    fetchLiveData("silver", setSilverData);
    fetchLiveData("gold", setGoldData);
    getProfileData();
  }, [id]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open} onClose={toggleDrawer}>
          <Toolbar disableGutters sx={{ backgroundColor: "#472878" }}>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex" },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <img
                  src={MenuBar}
                  alt="batuk"
                  height="30px"
                  width="45px"
                  style={{
                    display: "flex",
                    marginRight: 1,
                  }}
                />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              ></Menu>
              {!open && (
                <img
                  src={BatukLogo}
                  alt="batuk"
                  width="62px"
                  style={{ display: "flex", marginRight: "1rem" }}
                />
              )}
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <h6 style={{ display: "contents", color: "red" }}>Live</h6>
                {loading ? (
                  <div>Loading...</div>
                ) : error ? (
                  <div>Loading...</div>
                ) : (
                  <div className="livedata">
                    <pre
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "0",
                      }}
                    >
                      <img
                        src={GoldImg}
                        alt=""
                        srcSet=""
                        style={{ width: "1rem" }}
                      />{" "}
                      ₹{goldData}/g
                    </pre>
                    <pre
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "0",
                      }}
                    >
                      <img
                        src={SilverImg}
                        alt=""
                        srcSet=""
                        style={{ width: "1rem" }}
                      />{" "}
                      ₹{silverData}/g
                    </pre>
                  </div>
                )}
              </div>
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              {/* <Link to="/faq">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <img
                    src={faqLogo}
                    alt="faq"
                    width="35px"
                    style={{ marginRight: "1rem" }}
                  />
                </IconButton>
              </Link>
              <Link to="/notification">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <img
                    src={notificationLogo}
                    alt="notification"
                    width="35px"
                    style={{ marginRight: "1rem" }}
                  />
                </IconButton>
              </Link> */}
              {/* <Link to="/profile">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <img src={ProfileLogo} alt="Profile Logo" width="37px" />
                </IconButton>
              </Link> */}
              {/* <Button
                variant="text"
                onClick={() => {
                  handleLogout();
                }}
              >
                <img
                  src={SignOutBlueLogo}
                  alt="SignOut Logo"
                  width="35px"
                  style={{ marginLeft: "0.5rem", marginRight: "1rem" }}
                />
              </Button> */}
            </Box>
            <Box sx={{ flexGrow: 0, marginRight: "1rem" }}>
              <Tooltip title="Open settings">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <div className="welcome-name">
                    <Typography>Welcome</Typography>
                    <Typography sx={{ fontSize: "12px" }}>
                      {profileData.name}
                    </Typography>
                  </div>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <img src={ProfileLogo} alt="Profile Logo" width="37px" />
                  </IconButton>
                </div>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <div style={{ padding: "8px" }}>
                  <Typography>{profileData.name}</Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                    }}
                  >
                    Partner Code ( {profileData.userName} )
                  </Typography>
                </div>
                <hr style={{ padding: "0px", margin: "0px" }} />
                {settings.map((setting) => (
                  <MenuItem
                    key={setting}
                    sx={{
                      "&:hover": {
                        backgroundColor: "#4d16a4",
                        color: "white",
                      },
                    }}
                    onClick={() => {
                      if (setting === "Logout") {
                        handleLogout();
                      } else if (setting === "Profile") {
                        navigate("/profile");
                        handleCloseUserMenu();
                      } else if (setting === "Reset Password") {
                        navigate("/reset-password");
                        handleCloseUserMenu();
                      } else {
                        handleCloseUserMenu();
                      }
                    }}
                  >
                    <Typography
                      textAlign="center"
                      sx={{
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    >
                      {setting}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open} sx={{ height: "100vh" }}>
          <Box sx={{ backgroundColor: " #DCDAFF", height: "100vh" }}>
            <Toolbar
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                px: [1],
                height: "50px",
                backgroundColor: "#472878",
              }}
            >
              <IconButton onClick={toggleDrawer}>
                <img
                  src={batukLogo}
                  alt="batuk"
                  width="160px"
                  style={{ display: "flex", marginRight: "2rem" }}
                />
              </IconButton>
            </Toolbar>
            <Divider />
            <List
              sx={{
                marginTop: "5px",
                height: "35rem",
              }}
            >
              <Link
                to="/"
                onMouseEnter={() => handleMouseEnter("home")}
                onMouseLeave={() => handleMouseLeave("home")}
              >
                <ListItem
                  disablePadding
                  sx={{
                    color: "white",
                    backgroundColor:
                      hoverStates.home || activeItem == "Home"
                        ? "#4a2a7d"
                        : "inherit",
                  }}
                >
                  <ListItemButtonStyled
                    onClick={() => handleListItemClick("Home")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      color: "white",
                      // border: "1px solid white",
                    }}
                  >
                    <ListItemIcon
                      className={
                        hoverStates.home || activeItem === "Home"
                          ? "activecolor"
                          : ""
                      }
                      sx={{
                        minWidth: 0,
                        mr: open ? 2 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={
                          hoverStates.home || activeItem === "Home"
                            ? HomeHover
                            : Home
                        }
                        alt="Home Logo"
                        width="35px"
                        style={{ marginLeft: "1.5rem", marginRight: "1rem" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      // primary="Home"
                      // className={activeItem === "Home" ? "active" : ""}
                      disableTypography
                      primary={
                        <Typography
                          variant="body2"
                          style={{
                            color:
                              hoverStates.home || activeItem === "Home"
                                ? "white"
                                : "#4A2A7D",
                          }}
                        >
                          Home
                        </Typography>
                      }
                    />
                  </ListItemButtonStyled>
                </ListItem>
              </Link>

              <Link
                to="/client_table"
                onMouseEnter={() => handleMouseEnter("clients")}
                onMouseLeave={() => handleMouseLeave("clients")}
              >
                <ListItem
                  disablePadding
                  sx={{
                    color: activeItem == "clients" ? "white" : "white",
                    backgroundColor:
                      activeItem == "clients" ? "#4a2a7d" : "inherit",
                  }}
                >
                  <ListItemButtonStyled
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      color: "white",
                    }}
                    onClick={() => handleListItemClick("clients")}
                  >
                    <ListItemIcon
                      className={activeItem === "clients" ? "activecolor" : ""}
                      sx={{
                        minWidth: 0,
                        mr: open ? 2 : "auto",
                        justifyContent: "center",
                        color: activeItem == "clients" ? "white" : "white",
                      }}
                    >
                      <img
                        src={
                          hoverStates.clients || activeItem === "clients"
                            ? ClientHover
                            : Client
                        }
                        alt="clients Logo"
                        width="35px"
                        style={{ marginLeft: "1.5rem", marginRight: "1rem" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant="body2"
                          style={{
                            color:
                              hoverStates.clients || activeItem === "clients"
                                ? "white"
                                : "#4A2A7D",
                          }}
                        >
                          Clients
                        </Typography>
                      }
                    />
                  </ListItemButtonStyled>
                </ListItem>
              </Link>
              <Link
                to="/employee_table"
                onMouseEnter={() => handleMouseEnter("employees")}
                onMouseLeave={() => handleMouseLeave("employees")}
              >
                <ListItem
                  disablePadding
                  sx={{
                    color: "white",
                    backgroundColor:
                      activeItem == "Employees" ? "#4a2a7d" : "inherit",
                  }}
                >
                  <ListItemButtonStyled
                    onClick={() => handleListItemClick("Employees")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      color: "white",
                    }}
                  >
                    <ListItemIcon
                      className={
                        activeItem === "Employees" ? "activecolor" : ""
                      }
                      sx={{
                        minWidth: 0,
                        mr: open ? 2 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={
                          hoverStates.employees || activeItem === "Employees"
                            ? EmployeesHover
                            : Employees
                        }
                        alt="Employees Logo"
                        width="35px"
                        style={{ marginLeft: "1.5rem", marginRight: "1rem" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant="body2"
                          style={{
                            color:
                              hoverStates.employees ||
                              activeItem === "Employees"
                                ? "white"
                                : "#4A2A7D",
                          }}
                        >
                          Employees
                        </Typography>
                      }
                    />
                  </ListItemButtonStyled>
                </ListItem>
              </Link>
              {/* <Link
                to="/sip"
                onMouseEnter={() => handleMouseEnter("sip")}
                onMouseLeave={() => handleMouseLeave("sip")}
              >
                <ListItem
                  disablePadding
                  sx={{
                    color: "white",
                    backgroundColor:
                      activeItem == "SIP" ? "#4a2a7d" : "inherit",
                  }}
                >
                  <ListItemButtonStyled
                    onClick={() => handleListItemClick("SIP")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      color: "white",
                    }}
                  >
                    <ListItemIcon
                      className={activeItem === "SIP" ? "activecolor" : ""}
                      sx={{
                        minWidth: 0,
                        mr: open ? 2 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={
                          hoverStates.sip || activeItem === "SIP"
                            ? SIPHover
                            : SIP
                        }
                        alt="Profile Logo"
                        width="35px"
                        style={{ marginLeft: "1.5rem", marginRight: "1rem" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant="body2"
                          style={{
                            color:
                              hoverStates.sip || activeItem === "SIP"
                                ? "white"
                                : "#4A2A7D",
                          }}
                        >
                          SIP
                        </Typography>
                      }
                    />
                  </ListItemButtonStyled>
                </ListItem>
              </Link>
              <Link
                to="/gold-fd"
                onMouseEnter={() => handleMouseEnter("goldfd")}
                onMouseLeave={() => handleMouseLeave("goldfd")}
              >
                <ListItem
                  disablePadding
                  sx={{
                    color: "white",
                    backgroundColor:
                      activeItem == "GOLDFD" ? "#4a2a7d" : "inherit",
                  }}
                >
                  <ListItemButtonStyled
                    onClick={() => handleListItemClick("GOLDFD")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      color: "white",
                    }}
                  >
                    <ListItemIcon
                      className={activeItem === "GOLDFD" ? "activecolor" : ""}
                      sx={{
                        minWidth: 0,
                        mr: open ? 2 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={
                          hoverStates.goldfd || activeItem === "GOLDFD"
                            ? FDHover
                            : FD
                        }
                        alt="Profile Logo"
                        width="35px"
                        style={{ marginLeft: "1.5rem", marginRight: "1rem" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant="body2"
                          style={{
                            color:
                              hoverStates.goldfd || activeItem === "GOLDFD"
                                ? "white"
                                : "#4A2A7D",
                          }}
                        >
                          Gold FD
                        </Typography>
                      }
                    />
                  </ListItemButtonStyled>
                </ListItem>
              </Link> */}
              {/* <Link to="/tracker">
              <ListItem
                disablePadding
                sx={{
                  color: "white",
                  backgroundColor:
                    activeItem == "Tracker" ? "#4a2a7d" : "inherit",
                }}
              >
                <ListItemButtonStyled
                  onClick={() => handleListItemClick("Tracker")}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    color: "white",
                  }}
                >
                  <ListItemIcon
                    className={activeItem === "Tracker" ? "activecolor" : ""}
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={Tracker}
                      alt="Profile Logo"
                      width="35px"
                      style={{ marginLeft: "1.5rem", marginRight: "1rem" }}
                       className={activeItem == "icon" ? "icon-hover" : ""}
                    />
                    <img
                      src={TrackerHover}
                      alt="Profile Logo Hover"
                      width="35px"
                      style={{ marginLeft: "1.5rem", marginRight: "1rem" }}
                       className={activeItem == "icon" ? "icon-hover" : ""}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Tracker"
                    className={activeItem === "Tracker" ? "active" : ""}
                  />
                </ListItemButtonStyled>
              </ListItem>
            </Link> */}
              <Link
                to="/proposal"
                onMouseEnter={() => handleMouseEnter("purposal")}
                onMouseLeave={() => handleMouseLeave("purposal")}
              >
                <ListItem
                  disablePadding
                  sx={{
                    color: "white",
                    backgroundColor:
                      activeItem == "purposal" ? "#4a2a7d" : "inherit",
                  }}
                >
                  <ListItemButtonStyled
                    onClick={() => handleListItemClick("purposal")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      color: "white",
                    }}
                  >
                    <ListItemIcon
                      className={activeItem === "purposal" ? "activecolor" : ""}
                      sx={{
                        minWidth: 0,
                        mr: open ? 2 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={
                          hoverStates.purposal || activeItem === "purposal"
                            ? ProposalHover
                            : Proposal
                        }
                        alt="purposal Logo"
                        width="35px"
                        style={{ marginLeft: "1.5rem", marginRight: "1rem" }}
                        className={activeItem === "icon" ? "icon-hover" : ""}
                      />
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant="body2"
                          style={{
                            color:
                              hoverStates.purposal || activeItem === "purposal"
                                ? "white"
                                : "#4A2A7D",
                          }}
                        >
                          Orders
                        </Typography>
                      }
                    />
                  </ListItemButtonStyled>
                </ListItem>
              </Link>
              <Link
                to="/earning"
                onMouseEnter={() => handleMouseEnter("earning")}
                onMouseLeave={() => handleMouseLeave("earning")}
              >
                <ListItem
                  disablePadding
                  sx={{
                    color: "white",
                    backgroundColor:
                      activeItem == "earning" ? "#4a2a7d" : "inherit",
                  }}
                >
                  <ListItemButtonStyled
                    onClick={() => handleListItemClick("earning")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      color: "white",
                    }}
                  >
                    <ListItemIcon
                      className={activeItem === "earning" ? "activecolor" : ""}
                      sx={{
                        minWidth: 0,
                        mr: open ? 2 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={
                          hoverStates.earning || activeItem === "earning"
                            ? EarningHover
                            : Earning
                        }
                        alt="earning Logo"
                        width="35px"
                        style={{ marginLeft: "1.5rem", marginRight: "1rem" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant="body2"
                          style={{
                            color:
                              hoverStates.earning || activeItem === "earning"
                                ? "white"
                                : "#4A2A7D",
                          }}
                        >
                          Earnings
                        </Typography>
                      }
                    />
                  </ListItemButtonStyled>
                </ListItem>
              </Link>
              <Link
                to="/reports"
                onMouseEnter={() => handleMouseEnter("reports")}
                onMouseLeave={() => handleMouseLeave("reports")}
              >
                <ListItem
                  disablePadding
                  sx={{
                    color: "white",
                    backgroundColor:
                      activeItem == "Sales" ? "#4a2a7d" : "inherit",
                  }}
                >
                  <ListItemButtonStyled
                    onClick={() => handleListItemClick("Sales")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      color: "white",
                    }}
                  >
                    <ListItemIcon
                      className={activeItem === "Sales" ? "activecolor" : ""}
                      sx={{
                        minWidth: 0,
                        mr: open ? 2 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={
                          hoverStates.reports || activeItem === "Sales"
                            ? ReportsHover
                            : Reports
                        }
                        alt="Sales Logo"
                        width="35px"
                        style={{ marginLeft: "1.5rem", marginRight: "1rem" }}
                        className={activeItem === "icon" ? "icon-hover" : ""}
                      />
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant="body2"
                          style={{
                            color:
                              hoverStates.reports || activeItem === "Sales"
                                ? "white"
                                : "#4A2A7D",
                          }}
                        >
                          Sales
                        </Typography>
                      }
                    />
                  </ListItemButtonStyled>
                </ListItem>
              </Link>
              <Link
                to="/upload-file"
                onMouseEnter={() => handleMouseEnter("impfiles")}
                onMouseLeave={() => handleMouseLeave("impfiles")}
              >
                <ListItem
                  disablePadding
                  sx={{
                    color: "white",
                    backgroundColor:
                      activeItem == "ImpFiles" ? "#4a2a7d" : "inherit",
                  }}
                >
                  <ListItemButtonStyled
                    onClick={() => handleListItemClick("ImpFiles")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      color: "white",
                    }}
                  >
                    <ListItemIcon
                      className={activeItem === "ImpFiles" ? "activecolor" : ""}
                      sx={{
                        minWidth: 0,
                        mr: open ? 2 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={
                          hoverStates.impfiles || activeItem === "ImpFiles"
                            ? ImpFilesHover
                            : ImpFiles
                        }
                        alt="ImpFiles Logo"
                        width="35px"
                        style={{ marginLeft: "1.5rem", marginRight: "1rem" }}
                        className={activeItem === "icon" ? "icon-hover" : ""}
                      />
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant="body2"
                          style={{
                            color:
                              hoverStates.impfiles || activeItem === "ImpFiles"
                                ? "white"
                                : "#4A2A7D",
                          }}
                        >
                          Import File
                        </Typography>
                      }
                    />
                  </ListItemButtonStyled>
                </ListItem>
              </Link>
              <Link
                to="/spot-gold"
                onMouseEnter={() => handleMouseEnter("spotgold")}
                onMouseLeave={() => handleMouseLeave("spotgold")}
              >
                <ListItem
                  disablePadding
                  sx={{
                    color: "white",
                    backgroundColor:
                      activeItem == "SpotGold" ? "#4a2a7d" : "inherit",
                  }}
                >
                  <ListItemButtonStyled
                    onClick={() => handleListItemClick("SpotGold")}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      color: "white",
                    }}
                  >
                    <ListItemIcon
                      className={activeItem === "SpotGold" ? "activecolor" : ""}
                      sx={{
                        minWidth: 0,
                        mr: open ? 2 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={
                          hoverStates.spotgold || activeItem === "SpotGold"
                            ? SpotGoldHover
                            : SpotGold
                        }
                        alt="SpotGold Logo"
                        width="35px"
                        style={{ marginLeft: "1.5rem", marginRight: "1rem" }}
                        className={activeItem === "icon" ? "icon-hover" : ""}
                      />
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant="body2"
                          style={{
                            color:
                              hoverStates.spotgold || activeItem === "SpotGold"
                                ? "white"
                                : "#4A2A7D",
                          }}
                        >
                          Spot Gold
                        </Typography>
                      }
                    />
                  </ListItemButtonStyled>
                </ListItem>
              </Link>
            </List>
          </Box>
        </Drawer>

        <Box
          component="main"
          sx={{
            backgroundColor: "white",
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Main open={open} className="main">
            <Outlet />
          </Main>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
