import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { baseUrl } from "../utils/baseUrl";

const ResetPassword = () => {
  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [authError, setAuthError] = useState("");

  const validate = () => {
    let oldPasswordError = "";
    let newPasswordError = "";
    let confirmPasswordError = "";

    if (!oldPassword) {
      oldPasswordError = "Old Password is required";
    }

    if (!newPassword) {
      newPasswordError = "New Password is required";
    }

    if (!confirmPassword) {
      confirmPasswordError = "Confirm Password is required";
    } else if (newPassword !== confirmPassword) {
      confirmPasswordError = "Passwords do not match";
    }

    if (oldPasswordError || newPasswordError || confirmPasswordError) {
      setErrors({
        oldPassword: oldPasswordError,
        newPassword: newPasswordError,
        confirmPassword: confirmPasswordError,
      });
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = validate();
    if (!isValid) return;

    try {
      if (!token) {
        throw new Error("JWT token must be provided");
      }
      const url = `${baseUrl}/profile/resetPassword`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      console.log(headers);
      const requestBody = {
        old_password: oldPassword,
        new_password: newPassword,
        confirmPassword,
      };

      const response = await axios.post(url, requestBody, { headers });
      const data = response.data;
      console.log(data);
      console.log("Reset Password API response", data);
      if (response.status === 200 && !data.error) {
        toast.success(data.message);
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        navigate("/reset-password");
      }
    } catch (error) {
      console.error("Error connecting to API", error);
      setAuthError(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
      toast.error(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="container-fluid">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="card" style={{ width: "54rem" }}>
            <Container fluid className="reset-form-container">
              <Row
                className="d-flex align-items-center justify-content-center vh-100"
                style={{ marginTop: "-8rem" }}
              >
                <div
                  className="form-center"
                  style={{ marginTop: "11rem", marginBottom: "3rem" }}
                >
                  <h2 className="center-anything">Reset Password</h2>
                  <p className="center-anything">Enter your new password.</p>
                  <div
                    className="p-4 mx-auto"
                    style={{ width: "100%", maxWidth: "24rem" }}
                  >
                    <form onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <input
                          style={{
                            border: "2px solid #4a2a7d",
                            borderRadius: "8px",
                            fontSize: "15px",
                            width: "100%",
                          }}
                          type="password"
                          placeholder="Enter old password"
                          value={oldPassword}
                          onChange={(e) => setOldPassword(e.target.value)}
                          id="old_password"
                        />
                        {errors.oldPassword && (
                          <div className="text-danger">
                            {errors.oldPassword}
                          </div>
                        )}
                      </div>
                      <div className="mb-3">
                        <input
                          style={{
                            border: "2px solid #4a2a7d",
                            borderRadius: "8px",
                            fontSize: "15px",
                            width: "100%",
                          }}
                          type="password"
                          placeholder="Enter new password"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          id="new_password"
                        />
                        {errors.newPassword && (
                          <div className="text-danger">
                            {errors.newPassword}
                          </div>
                        )}
                      </div>
                      <div className="mb-3">
                        <input
                          style={{
                            border: "2px solid #4a2a7d",
                            borderRadius: "8px",
                            fontSize: "15px",
                            width: "100%",
                          }}
                          type="password"
                          placeholder="Confirm new password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          id="confirmPassword"
                        />
                        {errors.confirmPassword && (
                          <div className="text-danger">
                            {errors.confirmPassword}
                          </div>
                        )}
                      </div>
                      {authError && (
                        <div className="text-danger">{authError}</div>
                      )}
                      <button
                        className="btn py-2 w-100"
                        style={{
                          backgroundColor: "#4a2a7d",
                          color: "white",
                          marginTop: "20px",
                          borderRadius: "30px",
                        }}
                        type="submit"
                      >
                        Reset Password
                      </button>
                    </form>
                  </div>
                </div>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;

// import React, { useState } from "react";
// import { Card, CardBody, CardHeader, Form } from "react-bootstrap";
// import { useForm } from "react-hook-form";
// import axios from "axios";
// import Button from "@mui/material/Button";
// import { Link, useNavigate } from "react-router-dom";

// function ResetPassword() {
//   const Token = sessionStorage.getItem("token");
//   const [email, setEmail] = useState("");
//     const navigate = useNavigate();
//   const handleSubmit = async () => {
//       console.log("email", email);
//       const emailaddress = email;
//     try {
//       const response = await axios.post(`${baseUrl}/forgotpassword", {
//         email: emailaddress,
//       });
//         console.log(response);
//         if (response.status == 200) {

//             navigate('/reset-password/:id')

//         }    // Optionally log the response
//       // Redirect or handle successful registration here
//       // navigate("/login"); // Assuming you're using react-router-dom
//     } catch (error) {
//       console.error("Error registering user:", error);
//       // Handle error (display message, etc.)
//     }
//   };

//   // Inside the Register component

//   return (
//     <div className="container-fluid">
//           <div className="col-lg-4 col-md-4 mx-auto" style={{ marginTop: "10rem" }}>
//               <Link to="/login" style={{fontSize:'16px',marginBottom:"20px"}}><Button variant="contained">Go Back</Button></Link>
//         <Card style={{ boxShadow: "1px 3px 5px 0px rgba(0, 0, 0, 0.2)" ,marginTop:"20px"}}>
//           <CardHeader
//             style={{
//               backgroundColor: "#4a2a7d",
//               display: "flex",
//               color: "white",
//               alignItems: "center",
//             }}>
//             <h6
//               style={{ marginTop: "6px", fontSize: "15px", fontWeight: "400" }}>
//               Forget Password
//             </h6>
//           </CardHeader>
//           <CardBody>
//             <form className="mt-2">
//               <div class="mb-3">
//                 <label
//                   style={{ color: "#4a2a7d" }}
//                   for="name"
//                   class="form-label">
//                   Email address
//                 </label>
//                 <input
//                   style={{
//                     border: "2px solid #4a2a7d",
//                     borderRadius: "8px",
//                     fontSize: "15px",
//                   }}
//                   type="email"
//                   placeholder="Enter email"
//                   value={email}
//                   onChange={(e) => setEmail(e.target.value)}
//                   id="name"
//                 />
//               </div>

//               <div className="mt-4">
//                 <Button
//                   type="button"
//                   onClick={handleSubmit}
//                   handleSubmit
//                   variant="contained"
//                   className="btn text-light"
//                   style={{ backgroundColor: "#10A37F" }}>
//                   Submit
//                 </Button>
//               </div>
//             </form>
//           </CardBody>
//         </Card>
//       </div>
//     </div>
//   );
// }

// export default ResetPassword;
