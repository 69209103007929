import React from "react";
import Login from "../pages/Login";
import Register from "../pages/Register";
import IndRegister from "../pages/IndiRegister";

const Header = () => {
  const renderComponent = () => {
    switch (window.location.pathname) {
      case "/login":
        return <Login />;
      case "/register":
        return <Register />;
      case "/indRegister":
        return <IndRegister />;
      default:
        return <Login />;
    }
  };

  return (
    <>
      
      {renderComponent()}
    </>
  );
};

export default Header;

// import React from "react";
// import { Navbar, Nav, Dropdown } from "react-bootstrap";
// import Logo from "../assets/images/Batuk Logo.png";
// import "../assets/css/header.css";
// import Login from "../pages/Login";
// import Register from "../pages/Register";
// import IndRegister from "../pages/IndiRegister";
// import { useParams } from "react-router-dom";

// const Header = () => {

//   return (
//     <>
//       <Navbar expand="lg" className="header-navbar">
//         <Navbar.Brand
//           href="/"
//           className="header-brand"
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <img
//             src={Logo}
//             width="200"
//             height="auto"
//             className="d-inline-block align-top"
//             alt="LOGO"
//           />
//         </Navbar.Brand>
//         <Navbar.Toggle aria-controls="basic-navbar-nav" />
//         <Navbar.Collapse
//           id="basic-navbar-nav"
//           style={{ justifyContent: "center" }}
//         >
//           <Nav className="ml-auto">
//             <Nav.Link href="#about" className="header-link">
//               About
//             </Nav.Link>
//             <Nav.Link href="#blogs" className="header-link">
//               Blogs
//             </Nav.Link>
//             <Nav.Link href="#faq" className="header-link">
//               FAQ
//             </Nav.Link>
//             <Nav.Link href="#insights" className="header-link">
//               Insights
//             </Nav.Link>
//             <Nav.Link href="#contact" className="header-link">
//               Contact us
//             </Nav.Link>
//           </Nav>
//         </Navbar.Collapse>
//         <div className="reg-log">
//           <Nav.Link href="/login" className="header-login">
//             Login
//           </Nav.Link>
//           <Dropdown>
//             <Dropdown.Toggle
//               variant="link"
//               id="dropdown-basic"
//               className="header-register"
//               style={{ textDecoration: "none" }}
//             >
//               Register
//             </Dropdown.Toggle>
//             <Dropdown.Menu className="dropdown-menu-custom">
//               <Dropdown.Item href="/register">Individual Partner</Dropdown.Item>
//               <Dropdown.Item href="/IndRegister">Entity Partner</Dropdown.Item>
//             </Dropdown.Menu>
//           </Dropdown>
//         </div>
//       </Navbar>
//       <Login />
//       <Register/>
//       <IndRegister/>
//     </>
//   );
// };

// export default Header;
