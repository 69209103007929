import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";
import { IconButton } from "@mui/material";
import {
  Delete as DeleteIcon,
  GroupAdd as GroupAddIcon,
  RemoveRedEye,
} from "@mui/icons-material";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box } from "@mui/material";
import { mkConfig, generateCsv, download } from "export-to-csv"; //or use your library of choice here
import { data } from "./makeData";
import { baseUrl } from "../utils/baseUrl";

const columnHelper = createMRTColumnHelper();

const columns = [
  columnHelper.accessor("proposal_no", {
    header: "Order Number",
    size: 40,
  }),
  columnHelper.accessor("client_name", {
    header: "Client",
    size: 40,
  }),
  columnHelper.accessor("metal_type", {
    header: "Metal Type",
    size: 40,
  }),
  columnHelper.accessor("status", {
    header: "Status",
    size: 40,
    Cell: (cell) => (
      <span
        style={{
          backgroundColor: "rgb(74 42 125)",
          padding: " 7px 14px",
          borderRadius: "12px",
          fontSize: "13px",
          color: "white",
        }}
      >
        {cell.row.original.status}
      </span>
    ),
  }),
  columnHelper.accessor("amount", {
    header: "Amount",
    size: 40,
    Cell: (cell) => "₹ " + cell.row.original.amount,
  }),
];

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});
const ExpiredTable = () => {
  const Token = sessionStorage.getItem("token");
  const [ExpiredProposals, setExpiredPurposal] = useState();
  const [employees, setEmployees] = useState([]);
  useEffect(() => {
    GetExpiredAPI();
  }, []);
  const [isExpiredTable, setisExpiredTable] = useState(false);
  const GetExpiredAPI = async () => {
    console.log("get expired");
    try {
      const url = `${baseUrl}/getProposal`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      // const employees = response.data.data;
      console.log("Expired  Api data", response.data.data.ExpiredProposals);
      console.log("fr:", response.data.data.ExpiredProposals);

      if (response.data.data.ExpiredProposals) {
        setisExpiredTable(true);
      }

      setExpiredPurposal(response.data.data.ExpiredProposals);
      const employees = response.data.data.ExpiredProposals;
      // setprogressData(response.data.data.PendingProposals);
      employees?.map((index, item) => {
        // console.log("Employee list=", employees.index.id);
        //  setclientCreatedDate(item.createdAt);

        // employees.set("slno",++item)
        index.slno = ++item;
      });

      setEmployees(employees);
      // console.log("employee")
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  console.log("adfsdg", isExpiredTable);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [query, setQuery] = useState("");

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    console.log("data input search", query);
  };
  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => ({
      proposal_no: row.original.proposal_no,
      client_name: row.original.client_name,
      metal_type: row.original.metal_type,
      amount: row.original.amount,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const rowData = employees.map((data) => ({
      proposal_no: data.proposal_no,
      client_name: data.client_name,
      metal_type: data.metal_type,
      amount: data.amount,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };
  const table = useMaterialReactTable({
    columns,
    data: employees,
    enableRowSelection: true, //enable some features
    // enableColumnOrdering: true, //enable a feature for all columns
    enableGlobalFilter: true, //turn off a feature
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    enableRowActions: false,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      //simple styling with the `sx` prop, works just like a style prop in this example

      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          onClick={() => handleExportData(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>

        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });
  const deletePurposal = (row) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4A2A7D",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const url = `${baseUrl}/proposalDelete/${row._id}`;
          const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Token}`,
          };
          const response = await axios.delete(url, { headers });
          // const employees = response.data.data;
          console.log("In excuteds update Api data", response);
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Your Purposal has been deleted.",
            icon: "success",
            customClass: {
              confirmButton: "my-custom-button-class",
            },
          });
          GetExpiredAPI();

          // console.log("employee")
        } catch (error) {
          console.error("Error connecting to API", error);
        }
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Your Purposal is safe",
          icon: "error",
        });
      }
    });
  };

  return (
    <MaterialReactTable table={table} />
    //     <div className="conatiner-fluid  p-2">
    //       <div className="row">
    //         <div className="col-md-12">
    //           {/* Table Header */}
    //           <Paper>
    //             <TableContainer>
    //             <Table>
    //                 <TableHead sx={{ backgroundColor: "#F2F3F7", color: "black" }}>
    //                   <TableRow>
    //                   <TableCell align="center">#</TableCell>

    //                     <TableCell align="center">Proposal Number</TableCell>
    //                     <TableCell align="center">Client</TableCell>
    //                     <TableCell align="center">Metal Type</TableCell>

    //                     <TableCell align="center">Status</TableCell>

    //                     <TableCell align="center">Amount</TableCell>

    //                   </TableRow>
    //                 </TableHead>
    //                 <TableBody>
    //                   {ExpiredProposals &&
    //                     ExpiredProposals.map((row, index) => {
    //                       return (
    //                         <TableRow
    //                           // key={}
    //                           sx={{
    //                             "&:last-child td, &:last-child th": { border: 0 },
    //                             backgroundColor: "#ffff",
    //                           }}>
    //                           <TableCell align="center">
    //                             {index + 1}
    //                           </TableCell>
    //                           <TableCell align="center">
    //                             {row.proposal_no}
    //                           </TableCell>
    //                           <TableCell align="center">
    //                             {row.client_name}
    //                           </TableCell>
    //                           <TableCell align="center">
    //                             {row.metal_type
    // }
    //                           </TableCell>
    //                           <TableCell align="center">
    //                             <span
    //                               style={{
    //                                 backgroundColor: "rgb(74 42 125)",
    //                                 padding: " 7px 14px",
    //                                 borderRadius: "12px",
    //                                 fontSize: "13px",
    //                                 color: "white",
    //                               }}>
    //                               {row.status}
    //                             </span>
    //                           </TableCell>
    //                           <TableCell align="center">{row.amount}</TableCell>

    //                         </TableRow>
    //                       );
    //                     })}

    //                   {/* ================================================================ */}
    //                 </TableBody>
    //               </Table>

    //             </TableContainer>
    //             <TablePagination
    //               rowsPerPageOptions={[5, 10, 25]}
    //               component="div"
    //               count={15}
    //               rowsPerPage={rowsPerPage}
    //               page={page}
    //               onPageChange={handleChangePage}
    //               onRowsPerPageChange={handleChangeRowsPerPage}
    //             />
    //           </Paper>
    //         </div>
    //       </div>
    //     </div>
  );
};

export default ExpiredTable;
