import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../utils/baseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Form, Row } from "react-bootstrap";
import {
  CountrySelect,
  StateSelect,
  CitySelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import { Button } from "@mui/material";
import ProfileLogo from "../assets/Icons/ProfileIcon.png";

const PersonalDetails = () => {
  const token = sessionStorage.getItem("token");
  const [isEditing, setIsEditing] = useState(false);

  const [country, setCountryid] = useState(101);
  const [state, setStateid] = useState(0);
  const [city, setCityid] = useState(0);

  const handleCountryChange = (country) => {
    setCountryid(country.id);
    setStateid(0);
    setCityid(0);
  };

  const handleStateChange = (state) => {
    setStateid(state.id);
    profileData.state = state.name;
    setCityid(0);
  };

  const handleCityChange = (city) => {
    setCityid(city.id);
    profileData.city = city.name;
  };

  useEffect(() => {
    getProfileData();
  }, []);

  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
    mobileNo: "",
    city: "",
    state: "",
    Address: "",
    panNo: "",
    aadharNo: "",
    accHoldername: "",
    accNo: "",
    Ifsccode: "",
    dob: "",
    userName: "",
  });

  const getProfileData = async () => {
    try {
      const url = `${baseUrl}/profile`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { headers });
      const profile = response.data.data[0];
      console.log("Profile data", profile);
      setProfileData(profile);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = `${baseUrl}/profileUpdate`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.put(url, profileData, { headers });
      console.log("Profile updated successfully", response.status);
      toast.success("Profile updated successfully");
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating profile", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData({
      ...profileData,
      [name]: value,
    });
  };

  return (
    <div className="container-fluid">
      <ToastContainer />
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-8 col-md-10 col-sm-12" style={{ width: "100%" }}>
          <div style={{ marginTop: "3rem" }}>
            <div className="card-body">
              <div className="row">
                <div
                  className="col-lg-4 text-center"
                  style={{ marginBottom: "1rem" }}
                >
                  <div
                    className=""
                    style={{
                      border: "1px solid",
                      borderRadius: "8px",
                      padding: "1rem",
                    }}
                  >
                    <img
                      src={ProfileLogo}
                      alt="User Avatar"
                      className="img-fluid rounded-circle mb-3"
                      style={{ width: "10rem" }}
                    />
                    <div className="profile-div">
                      <h5>{profileData.name}</h5>
                      <h6>Username - {profileData.userName}</h6>
                      <h6>Register Type - {profileData.registerType}</h6>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 profile-bottom">
                  {!isEditing ? (
                    <div
                      className="profile-show"
                      style={{
                        padding: "1rem",
                        border: "1px solid",
                        borderRadius: "8px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          className="btn btn-primary"
                          onClick={() => setIsEditing(true)}
                          style={{
                            textTransform: "capitalize",
                            height: "2.5rem",
                            marginBottom: "1rem",
                          }}
                        >
                          Update Profile
                        </Button>
                      </div>
                      <h4
                        className="mb-4 text-color"
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#dcdaff",
                        }}
                      >
                        Personal Details
                      </h4>
                      <div className="profile-text">
                        <Row className="row-bottom">
                          <Col md={4}>
                            <Form.Group controlId="formName">
                              <Form.Label>Name</Form.Label>
                              <h5 style={{ fontSize: "16px" }}>
                                {profileData.name}
                              </h5>
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group controlId="formDOB">
                              <Form.Label>Date of Birth</Form.Label>
                              <h5 style={{ fontSize: "16px" }}>
                                {profileData.dob}
                              </h5>
                            </Form.Group>
                          </Col>

                          <Col md={4}>
                            <Form.Group controlId="formPhone">
                              <Form.Label>Mobile No.</Form.Label>
                              <h5 style={{ fontSize: "16px" }}>
                                {profileData.mobileNo}
                              </h5>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="row-bottom">
                          <Col md={4}>
                            <Form.Group controlId="formAadhar">
                              <Form.Label>Aadhar No.</Form.Label>
                              <h5 style={{ fontSize: "16px" }}>
                                {profileData.aadharNo}
                              </h5>
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group controlId="formEmail">
                              <Form.Label>Email</Form.Label>
                              <h5 style={{ fontSize: "16px" }}>
                                {profileData.email}
                              </h5>
                            </Form.Group>
                          </Col>
                        </Row>
                        <h4
                          className="mb-4 text-color "
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#dcdaff",
                          }}
                        >
                          Address Details
                        </h4>
                        <Row className="row-bottom">
                          <Col md={4}>
                            <Form.Group controlId="formAddress">
                              <Form.Label>Address</Form.Label>
                              <h5 style={{ fontSize: "16px" }}>
                                {profileData.Address}
                              </h5>
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group controlId="formState">
                              <Form.Label>State</Form.Label>
                              <h5 style={{ fontSize: "16px" }}>
                                {profileData.state}
                              </h5>
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group controlId="formCity">
                              <Form.Label>City</Form.Label>
                              <h5 style={{ fontSize: "16px" }}>
                                {profileData.city}
                              </h5>
                            </Form.Group>
                          </Col>
                        </Row>
                        <h4
                          className="mb-4 text-color"
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#dcdaff",
                          }}
                        >
                          Bank Detail
                        </h4>
                        <Row className="row-bottom">
                          <Col md={4}>
                            <Form.Group controlId="accountHolderName">
                              <Form.Label>Account Holder's Name</Form.Label>
                              <h5 style={{ fontSize: "16px" }}>
                                {profileData.accHoldername}
                              </h5>
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group controlId="accountNo">
                              <Form.Label>Account No.</Form.Label>
                              <h5 style={{ fontSize: "16px" }}>
                                {profileData.accNo}
                              </h5>
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group controlId="formPanNumber">
                              <Form.Label>PAN No.</Form.Label>
                              <h5 style={{ fontSize: "16px" }}>
                                {profileData.panNo}
                              </h5>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="row-bottom">
                          <Col md={4}>
                            <Form.Group controlId="ifscCode">
                              <Form.Label>IFSC Code</Form.Label>
                              <h5 style={{ fontSize: "16px" }}>
                                {profileData.Ifsccode}
                              </h5>
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="input-group"
                      style={{
                        padding: "1rem",
                        border: "1px solid",
                        borderRadius: "8px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Button
                          variant="contained"
                          className="btn btn-primary"
                          onClick={() => setIsEditing(false)}
                          style={{
                            textTransform: "capitalize",
                            height: "2.5rem",
                            marginBottom: "1rem",
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                      <h4
                        className="mb-4 text-color"
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#dcdaff",
                        }}
                      >
                        Personal Details
                      </h4>
                      <Form onSubmit={handleSubmit}>
                        <Row className="row-bottom">
                          <Col md={4}>
                            <Form.Group controlId="formName">
                              <Form.Label>Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Full Name"
                                name="name"
                                value={profileData.name}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group controlId="formDOB">
                              <Form.Label>Date of Birth</Form.Label>
                              <Form.Control
                                type="date"
                                placeholder="Enter Date of Birth"
                                name="dob"
                                value={profileData.dob}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group controlId="formPhone">
                              <Form.Label>Mobile No.</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Mobile No"
                                name="mobileNo"
                                value={profileData.mobileNo}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="row-bottom">
                          <Col md={4}>
                            <Form.Group controlId="formAadhar">
                              <Form.Label>Aadhar No.</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Aadhar No."
                                name="aadharNo"
                                value={profileData.aadharNo}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group controlId="formEmail">
                              <Form.Label>Email</Form.Label>
                              <Form.Control
                                type="email"
                                placeholder="Enter Email"
                                name="email"
                                value={profileData.email}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <h4
                          className="mb-4 text-color "
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#dcdaff",
                          }}
                        >
                          Address Details
                        </h4>
                        <Row className="row-bottom">
                          <Col md={4}>
                            <Form.Group controlId="formAddress">
                              <Form.Label>Address</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Your Address"
                                name="Address"
                                value={profileData.Address}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group controlId="formState">
                              <Form.Label>State</Form.Label>
                              <StateSelect
                                placeHolder={profileData.state}
                                type="text"
                                name="state"
                                countryid={country}
                                onChange={(state) => handleStateChange(state)}
                                value={profileData.state}
                                className="form-control"
                              />
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group controlId="formCity">
                              <Form.Label>City</Form.Label>
                              <CitySelect
                                placeHolder={profileData.city}
                                type="text"
                                name="city"
                                countryid={country}
                                stateid={state}
                                onChange={(city) => handleCityChange(city)}
                                value={profileData.city}
                                className="form-control"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <h4
                          className="mb-4 text-color"
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#dcdaff",
                          }}
                        >
                          Bank Detail
                        </h4>
                        <Row className="row-bottom">
                          <Col md={4}>
                            <Form.Group controlId="accountHolderName">
                              <Form.Label>Account Holder's Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Account Holder's Name"
                                name="accHoldername"
                                value={profileData.accHoldername}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group controlId="accountNo">
                              <Form.Label>Account No.</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Account No."
                                name="accNo"
                                value={profileData.accNo}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group controlId="formPanNumber">
                              <Form.Label>PAN No.</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter PAN No."
                                name="panNo"
                                value={profileData.panNo}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="row-bottom">
                          <Col md={4}>
                            <Form.Group controlId="ifscCode">
                              <Form.Label>IFSC Code</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter IFSC Code"
                                name="Ifsccode"
                                value={profileData.Ifsccode}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <div className="text-end">
                          <Button
                            type="submit"
                            variant="contained"
                            className="btn btn-primary"
                            style={{
                              textTransform: "capitalize",
                              height: "2.5rem",
                            }}
                          >
                            Update
                          </Button>
                        </div>
                      </Form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { baseUrl } from "../utils/baseUrl";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { Col, Form, Row } from "react-bootstrap";
// import {
//   CountrySelect,
//   StateSelect,
//   CitySelect,
// } from "react-country-state-city";
// import "react-country-state-city/dist/react-country-state-city.css";
// import { Button } from "@mui/material";

// const PersonalDetails = () => {
//   const token = sessionStorage.getItem("token");

//   const [country, setCountryid] = useState(101);
//   const [state, setStateid] = useState(0);
//   const [city, setCityid] = useState(0);

//   const handleCountryChange = (country) => {
//     setCountryid(country.id);
//     setStateid(0);
//     setCityid(0);
//   };

//   const handleStateChange = (state) => {
//     setStateid(state.id);
//     profileData.state = state.name;
//     setCityid(0);
//   };

//   const handleCityChange = (city) => {
//     setCityid(city.id);
//     profileData.city = city.name;
//   };

//   useEffect(() => {
//     getProfileData();
//   }, []);

//   const [profileData, setProfileData] = useState({
//     name: "",
//     email: "",
//     mobileNo: "",
//     city: "",
//     state: "",
//     Address: "",
//     panNo: "",
//     aadharNo: "",
//     accHoldername: "",
//     accNo: "",
//     Ifsccode: "",
//     dob: "",
//     userName: "",
//   });

//   const getProfileData = async () => {
//     try {
//       const url = `${baseUrl}/profile`;
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${token}`,
//       };
//       const response = await axios.get(url, { headers });
//       const profile = response.data.data[0];
//       console.log("Profile data", profile);
//       setProfileData(profile);
//     } catch (error) {
//       console.error("Error connecting to API", error);
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const url = `${baseUrl}/profileUpdate`;
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${token}`,
//       };
//       const response = await axios.put(url, profileData, { headers });
//       console.log("Profile updated successfully", response.status);
//       toast.success("Profile updated successfully");
//     } catch (error) {
//       console.error("Error updating profile", error);
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setProfileData({
//       ...profileData,
//       [name]: value,
//     });
//   };

//   return (
//     <div className="container-fluid">
//       <ToastContainer />
//       <div className="row justify-content-center align-items-center">
//         <div className="col-lg-8 col-md-10 col-sm-12" style={{ width: "100%" }}>
//           <div style={{ marginTop: "3rem" }}>
//             <div className="card-body">
//               <div className="row">
//                 <div
//                   className="col-lg-4 text-center"
//                   style={{ marginBottom: "1rem" }}
//                 >
//                   <div
//                     className=""
//                     style={{
//                       border: "1px solid",
//                       borderRadius: "8px",
//                       padding: "1rem",
//                     }}
//                   >
//                     <img
//                       src="https://bootdey.com/img/Content/avatar/avatar7.png"
//                       alt="User Avatar"
//                       className="img-fluid rounded-circle mb-3"
//                       style={{ width: "10rem" }}
//                     />
//                     <div className="profile-div">
//                       <h5>{profileData.name}</h5>
//                       <h6>Username - {profileData.userName}</h6>
//                       <h6>Register Type - {profileData.registerType}</h6>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-lg-8 profile-text">
//                   <div
//                     className="profile-show"
//                     style={{
//                       padding: "1rem",
//                       border: "1px solid",
//                       borderRadius: "8px",
//                     }}
//                   >
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <h4 className="mb-4 text-color">Personal Details</h4>
//                       <Button
//                         variant="contained"
//                         className="btn btn-primary"
//                         onClick={() => {}}
//                         style={{
//                           textTransform: "capitalize",
//                           height: "2.5rem",
//                         }}
//                       >
//                         Update Profile
//                       </Button>
//                     </div>
//                     <Row className="row-bottom">
//                       <Col md={4}>
//                         <Form.Group controlId="formName">
//                           <Form.Label>Name</Form.Label>
//                           <h5>{profileData.name}</h5>
//                         </Form.Group>
//                       </Col>
//                       <Col md={4}>
//                         <Form.Group controlId="formDOB">
//                           <Form.Label>Date of Birth</Form.Label>
//                           <h5>{profileData.dob}</h5>
//                         </Form.Group>
//                       </Col>

//                       <Col md={4}>
//                         <Form.Group controlId="formPhone">
//                           <Form.Label>Mobile No.</Form.Label>
//                           <h5>{profileData.mobileNo}</h5>
//                         </Form.Group>
//                       </Col>
//                     </Row>
//                     <Row className="row-bottom">
//                       <Col md={4}>
//                         <Form.Group controlId="formAadhar">
//                           <Form.Label>Aadhar No.</Form.Label>
//                           <h5>{profileData.aadharNo}</h5>
//                         </Form.Group>
//                       </Col>
//                       <Col md={4}>
//                         <Form.Group controlId="formEmail">
//                           <Form.Label>Email</Form.Label>
//                           <h5>{profileData.email}</h5>
//                         </Form.Group>
//                       </Col>
//                     </Row>
//                     <h4 className="mb-4 text-color ">Address Details</h4>
//                     <Row className="row-bottom">
//                       <Col md={4}>
//                         <Form.Group controlId="formAddress">
//                           <Form.Label>Address</Form.Label>
//                           <h5>{profileData.Address}</h5>
//                         </Form.Group>
//                       </Col>
//                       <Col md={4}>
//                         <Form.Group controlId="formState">
//                           <Form.Label>State</Form.Label>
//                           <h5>{profileData.state}</h5>
//                         </Form.Group>
//                       </Col>
//                       <Col md={4}>
//                         <Form.Group controlId="formCity">
//                           <Form.Label>City</Form.Label>
//                           <h5>{profileData.city}</h5>
//                         </Form.Group>
//                       </Col>
//                     </Row>
//                     <h4 className="mb-4 text-color">Bank Detail</h4>
//                     <Row className="row-bottom">
//                       <Col md={4}>
//                         <Form.Group controlId="accountHolderName">
//                           <Form.Label>Account Holder's Name</Form.Label>
//                           <h5>{profileData.accHoldername}</h5>
//                         </Form.Group>
//                       </Col>
//                       <Col md={4}>
//                         <Form.Group controlId="accountNo">
//                           <Form.Label>Account No.</Form.Label>
//                           <h5>{profileData.accNo}</h5>
//                         </Form.Group>
//                       </Col>
//                       <Col md={4}>
//                         <Form.Group controlId="formPanNumber">
//                           <Form.Label>PAN No.</Form.Label>
//                           <h5>{profileData.panNo}</h5>
//                         </Form.Group>
//                       </Col>
//                     </Row>
//                     <Row className="row-bottom">
//                       <Col md={4}>
//                         <Form.Group controlId="ifscCode">
//                           <Form.Label>IFSC Code</Form.Label>
//                           <h5>{profileData.Ifsccode}</h5>
//                         </Form.Group>
//                       </Col>
//                     </Row>
//                   </div>
//                   <div
//                     className="input-group"
//                     style={{
//                       padding: "1rem",
//                       border: "1px solid",
//                       borderRadius: "8px",
//                     }}
//                   >
//                     <h4 className="mb-4 text-color">Personal Details</h4>
//                     <Form onSubmit={handleSubmit}>
//                       <Row className="row-bottom">
//                         <Col md={4}>
//                           <Form.Group controlId="formName">
//                             <Form.Label>Name</Form.Label>
//                             <Form.Control
//                               type="text"
//                               placeholder="Enter Full Name"
//                               name="name"
//                               value={profileData.name}
//                               onChange={handleChange}
//                             />
//                           </Form.Group>
//                         </Col>
//                         <Col md={4}>
//                           <Form.Group controlId="formEmail">
//                             <Form.Label>Email</Form.Label>
//                             <Form.Control
//                               type="email"
//                               placeholder="Enter Email"
//                               name="email"
//                               value={profileData.email}
//                               onChange={handleChange}
//                             />
//                           </Form.Group>
//                         </Col>
//                         <Col md={4}>
//                           <Form.Group controlId="formPhone">
//                             <Form.Label>Mobile No.</Form.Label>
//                             <Form.Control
//                               type="text"
//                               placeholder="Enter Mobile No"
//                               name="mobileNo"
//                               value={profileData.mobileNo}
//                               onChange={handleChange}
//                             />
//                           </Form.Group>
//                         </Col>
//                       </Row>
//                       <Row className="row-bottom">
//                         <Col md={4}>
//                           <Form.Group controlId="formAadhar">
//                             <Form.Label>Aadhar No.</Form.Label>
//                             <Form.Control
//                               type="text"
//                               placeholder="Enter Aadhar No."
//                               name="aadharNo"
//                               value={profileData.aadharNo}
//                               onChange={handleChange}
//                             />
//                           </Form.Group>
//                         </Col>
//                         <Col md={4}>
//                           <Form.Group controlId="formDOB">
//                             <Form.Label>Date of Birth</Form.Label>
//                             <Form.Control
//                               type="date"
//                               placeholder="Enter Date of Birth"
//                               name="dob"
//                               value={profileData.dob}
//                               onChange={handleChange}
//                             />
//                           </Form.Group>
//                         </Col>
//                       </Row>
//                       <h4 className="mb-4 text-color ">Address Details</h4>
//                       <Row className="row-bottom">
//                         <Col md={4}>
//                           <Form.Group controlId="formAddress">
//                             <Form.Label>Address</Form.Label>
//                             <Form.Control
//                               type="text"
//                               placeholder="Enter Your Address"
//                               name="Address"
//                               value={profileData.Address}
//                               onChange={handleChange}
//                             />
//                           </Form.Group>
//                         </Col>
//                         <Col md={4}>
//                           <Form.Group controlId="formState">
//                             <Form.Label>State</Form.Label>
//                             <StateSelect
//                               placeHolder={profileData.state}
//                               type="text"
//                               name="state"
//                               countryid={country}
//                               onChange={(state) => handleStateChange(state)}
//                               value={profileData.state}
//                               className="form-control"
//                             />
//                           </Form.Group>
//                         </Col>
//                         <Col md={4}>
//                           <Form.Group controlId="formCity">
//                             <Form.Label>City</Form.Label>
//                             <CitySelect
//                               placeHolder={profileData.city}
//                               type="text"
//                               name="city"
//                               countryid={country}
//                               stateid={state}
//                               onChange={(city) => handleCityChange(city)}
//                               value={profileData.city}
//                               className="form-control"
//                             />
//                           </Form.Group>
//                         </Col>
//                       </Row>
//                       <h4 className="mb-4 text-color">Bank Detail</h4>
//                       <Row className="row-bottom">
//                         <Col md={4}>
//                           <Form.Group controlId="accountHolderName">
//                             <Form.Label>Account Holder's Name</Form.Label>
//                             <Form.Control
//                               type="text"
//                               placeholder="Enter Account Holder's Name"
//                               name="accHoldername"
//                               value={profileData.accHoldername}
//                               onChange={handleChange}
//                             />
//                           </Form.Group>
//                         </Col>
//                         <Col md={4}>
//                           <Form.Group controlId="accountNo">
//                             <Form.Label>Account No.</Form.Label>
//                             <Form.Control
//                               type="text"
//                               placeholder="Enter Account No."
//                               name="accNo"
//                               value={profileData.accNo}
//                               onChange={handleChange}
//                             />
//                           </Form.Group>
//                         </Col>
//                         <Col md={4}>
//                           <Form.Group controlId="formPanNumber">
//                             <Form.Label>PAN No.</Form.Label>
//                             <Form.Control
//                               type="text"
//                               placeholder="Enter PAN No."
//                               name="panNo"
//                               value={profileData.panNo}
//                               onChange={handleChange}
//                             />
//                           </Form.Group>
//                         </Col>
//                       </Row>
//                       <Row className="row-bottom">
//                         <Col md={4}>
//                           <Form.Group controlId="ifscCode">
//                             <Form.Label>IFSC Code</Form.Label>
//                             <Form.Control
//                               type="text"
//                               placeholder="Enter IFSC Code"
//                               name="Ifsccode"
//                               value={profileData.Ifsccode}
//                               onChange={handleChange}
//                             />
//                           </Form.Group>
//                         </Col>
//                       </Row>
//                       <div className="text-end">
//                         <button
//                           type="button"
//                           className="btn btn-secondary me-2"
//                         >
//                           Cancel
//                         </button>
//                         <button type="submit" className="btn btn-primary">
//                           Update
//                         </button>
//                       </div>
//                     </Form>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PersonalDetails;

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { Form, Button, Row, Col } from "react-bootstrap";
// import { baseUrl } from "../utils/baseUrl";
// import {
//   CountrySelect,
//   StateSelect,
//   CitySelect,
// } from "react-country-state-city";
// import "react-country-state-city/dist/react-country-state-city.css";

// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// const PersonalDetails = () => {
//   const token = sessionStorage.getItem("token");

//   const [country, setCountryid] = useState(101);
//   const [state, setStateid] = useState(0);
//   const [city, setCityid] = useState(0);

//   const handleCountryChange = (country) => {
//     setCountryid(country.id);
//     setStateid(0);
//     setCityid(0);
//   };

//   const handleStateChange = (state) => {
//     setStateid(state.id);
//     profileData.state = state.name;
//     setCityid(0);
//   };

//   const handleCityChange = (city) => {
//     setCityid(city.id);
//     profileData.city = city.name;
//   };

//   useEffect(() => {
//     getProfileData();
//   }, []);

//   const [profileData, setProfileData] = useState({
//     name: "",
//     email: "",
//     mobileNo: "",
//     city: "",
//     state: "",
//     Address: "",
//     panNo: "",
//     aadharNo: "",
//     accHoldername: "",
//     accNo: "",
//     Ifsccode: "",
//     dob: "",
//     userName: "",
//   });

//   const getProfileData = async () => {
//     try {
//       const url = `${baseUrl}/profile`;
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${token}`,
//       };
//       const response = await axios.get(url, { headers });
//       const profile = response.data.data[0];
//       console.log("Profile data", profile);
//       setProfileData(profile);
//     } catch (error) {
//       console.error("Error connecting to API", error);
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const url = `${baseUrl}/profileUpdate`;
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${token}`,
//       };
//       const response = await axios.put(url, profileData, { headers });
//       console.log("Profile updated successfully", response.status);
//       toast.success("Profile updated successfully");
//     } catch (error) {
//       console.error("Error updating profile", error);
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setProfileData({
//       ...profileData,
//       [name]: value,
//     });
//   };

//   return (
//     <div className="container-fluid">
//       <ToastContainer />
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <div className="card" style={{ width: "54rem" }}>
//           <div class="container">
//             <div class="row gutters">
//               <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
//                 <div class="card-personal h-100">
//                   <div class="card-body">
//                     <div class="account-settings">
//                       <div class="user-profile">
//                         <div class="user-avatar">
//                           <img
//                             src="https://bootdey.com/img/Content/avatar/avatar7.png"
//                             alt="Maxwell Admin"
//                           />
//                         </div>
//                         <h5 class="user-name">Yuki Hayashi</h5>
//                         <h6 class="user-email">yuki@Maxwell.com</h6>
//                       </div>
//                       <div class="about">
//                         <h5>About</h5>
//                         <p>
//                           I'm Yuki. Full Stack Designer I enjoy creating
//                           user-centric, delightful and human experiences.
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
//                 <div class="card-personal  h-100">
//                   <div class="card-body">
//                     <div class="row gutters">
//                       <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
//                         <h6 class="mb-2 text-primary">Personal Details</h6>
//                       </div>
//                       <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
//                         <div class="form-group">
//                           <label for="fullName">Full Name</label>
//                           <input
//                             type="text"
//                             class="form-control"
//                             id="fullName"
//                             placeholder="Enter full name"
//                           />
//                         </div>
//                       </div>
//                       <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
//                         <div class="form-group">
//                           <label for="eMail">Email</label>
//                           <input
//                             type="email"
//                             class="form-control"
//                             id="eMail"
//                             placeholder="Enter email ID"
//                           />
//                         </div>
//                       </div>
//                       <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
//                         <div class="form-group">
//                           <label for="phone">Phone</label>
//                           <input
//                             type="text"
//                             class="form-control"
//                             id="phone"
//                             placeholder="Enter phone number"
//                           />
//                         </div>
//                       </div>
//                       <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
//                         <div class="form-group">
//                           <label for="website">Website URL</label>
//                           <input
//                             type="url"
//                             class="form-control"
//                             id="website"
//                             placeholder="Website url"
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div class="row gutters">
//                       <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
//                         <h6 class="mt-3 mb-2 text-primary">Address</h6>
//                       </div>
//                       <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
//                         <div class="form-group">
//                           <label for="Street">Street</label>
//                           <input
//                             type="name"
//                             class="form-control"
//                             id="Street"
//                             placeholder="Enter Street"
//                           />
//                         </div>
//                       </div>
//                       <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
//                         <div class="form-group">
//                           <label for="ciTy">City</label>
//                           <input
//                             type="name"
//                             class="form-control"
//                             id="ciTy"
//                             placeholder="Enter City"
//                           />
//                         </div>
//                       </div>
//                       <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
//                         <div class="form-group">
//                           <label for="sTate">State</label>
//                           <input
//                             type="text"
//                             class="form-control"
//                             id="sTate"
//                             placeholder="Enter State"
//                           />
//                         </div>
//                       </div>
//                       <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
//                         <div class="form-group">
//                           <label for="zIp">Zip Code</label>
//                           <input
//                             type="text"
//                             class="form-control"
//                             id="zIp"
//                             placeholder="Zip Code"
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div class="row gutters">
//                       <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
//                         <div class="text-right">
//                           <button
//                             type="button"
//                             id="submit"
//                             name="submit"
//                             class="btn btn-secondary"
//                           >
//                             Cancel
//                           </button>
//                           <button
//                             type="button"
//                             id="submit"
//                             name="submit"
//                             class="btn btn-primary"
//                           >
//                             Update
//                           </button>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PersonalDetails;

/////////////////////////////////////////////////////////////////

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { Form, Button, Row, Col } from "react-bootstrap";
// import { GetCountries, GetState, GetCity } from "react-country-state-city";
// import "react-country-state-city/dist/react-country-state-city.css";
// import { baseUrl } from "../utils/baseUrl";

// const PersonalDetails = () => {
//   const token = sessionStorage.getItem("token");

//   // State variables for storing data and dropdown selections
//   const [profileData, setProfileData] = useState({
//     name: "",
//     email: "",
//     mobileNo: "",
//     city: "",
//     state: "",
//     Address: "",
//     panNo: "",
//     aadharNo: "",
//     accHoldername: "",
//     accNo: "",
//     Ifsccode: "",
//     dob: "",
//     userName: "",
//   });

//   const [countriesList, setCountriesList] = useState([]);
//   const [stateList, setStateList] = useState([]);
//   const [cityList, setCityList] = useState([]);

//   useEffect(() => {
//     // Fetch countries and set initial state
//     GetCountries().then((countries) => {
//       setCountriesList(countries);
//     });
//   }, []);

//   // Function to handle changes in country selection
//   const handleCountryChange = (e) => {
//     const country = countriesList[e.target.value];
//     setProfileData({
//       ...profileData,
//       country: country.name, // Assuming you want to store country name in profileData
//     });

//     GetState(country.id).then((states) => {
//       setStateList(states);
//     });
//   };

//   // Function to handle changes in state selection
//   const handleStateChange = (e) => {
//     const state = stateList[e.target.value];
//     setProfileData({
//       ...profileData,
//       state: state.name, // Assuming you want to store state name in profileData
//     });

//     GetCity(profileData.country, state.id).then((cities) => {
//       setCityList(cities);
//     });
//   };

//   // Function to handle changes in city selection
//   const handleCityChange = (e) => {
//     const city = cityList[e.target.value];
//     setProfileData({
//       ...profileData,
//       city: city.name, // Assuming you want to store city name in profileData
//     });
//   };

//   // Function to handle form submission
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const url = `${baseUrl}/profileUpdate`;
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${token}`,
//       };
//       const response = await axios.put(url, profileData, { headers });
//       console.log("Profile updated successfully", response.data);
//     } catch (error) {
//       console.error("Error updating profile", error);
//     }
//   };

//   // Function to handle input changes in the form fields
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setProfileData({
//       ...profileData,
//       [name]: value,
//     });
//   };

//   return (
//     <div className="container-fluid">
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <div className="card" style={{ width: "54rem" }}>
//           <div className="kyc-details">
//             <div className="registration-form" style={{ padding: "0rem" }}>
//               <h4 className="mb-4 center-text text-color">Profile Details</h4>
//               <h4 className="mb-4 text-color">Personal Details</h4>
//               <Form onSubmit={handleSubmit}>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="formName">
//                       <Form.Label>Name</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter Full Name"
//                         name="name"
//                         value={profileData.name}
//                         onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formEmail">
//                       <Form.Label>Email</Form.Label>
//                       <Form.Control
//                         type="email"
//                         placeholder="Enter Email"
//                         name="email"
//                         value={profileData.email}
//                         onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formPhone">
//                       <Form.Label>Mobile No.</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter Mobile No"
//                         name="mobileNo"
//                         value={profileData.mobileNo}
//                         onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="formAadhar">
//                       <Form.Label>Aadhar No.</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter Aadhar No."
//                         name="aadharNo"
//                         value={profileData.aadharNo}
//                         onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formDOB">
//                       <Form.Label>Date of Birth</Form.Label>
//                       <Form.Control
//                         type="date"
//                         placeholder="Enter Date of Birth"
//                         name="dob"
//                         value={profileData.dob}
//                         onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <div className="mt-4 text-center"></div>
//                 <h4 className="mb-4 center-text text-color ">
//                   Address Details
//                 </h4>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="formAddress">
//                       <Form.Label>Address</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter Your Address"
//                         name="Address"
//                         value={profileData.Address}
//                         onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="forCountry">
//                       <Form.Label>Country</Form.Label>
//                       <select
//                         className="form-control"
//                         onChange={handleCountryChange}
//                         value={profileData.country}
//                       >
//                         {countriesList.map((item, index) => (
//                           <option key={index} value={index}>
//                             {item.name}
//                           </option>
//                         ))}
//                       </select>
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formState">
//                       <Form.Label>State</Form.Label>
//                       <select
//                         className="form-control"
//                         onChange={handleStateChange}
//                         value={profileData.state}
//                       >
//                         {stateList.map((state, index) => (
//                           <option key={index} value={index}>
//                             {state.name}
//                           </option>
//                         ))}
//                       </select>
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formCity">
//                       <Form.Label>City</Form.Label>
//                       <select
//                         className="form-control"
//                         onChange={handleCityChange}
//                         value={profileData.city}
//                       >
//                         {cityList.map((city, index) => (
//                           <option key={index} value={index}>
//                             {city.name}
//                           </option>
//                         ))}
//                       </select>
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <div className="mt-4 text-center"></div>
//                 <h4 className="mb-4 center-text text-color">Bank Detail</h4>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="accountHolderName">
//                       <Form.Label>Account Holder's Name</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter Account Holder's Name"
//                         name="accHoldername"
//                         value={profileData.accHoldername}
//                         onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="accountNo">
//                       <Form.Label>Account No.</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter Account No."
//                         name="accNo"
//                         value={profileData.accNo}
//                         onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formPanNumber">
//                       <Form.Label>PAN No.</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter PAN No."
//                         name="panNo"
//                         value={profileData.panNo}
//                         onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="ifscCode">
//                       <Form.Label>IFSC Code</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter IFSC Code"
//                         name="Ifsccode"
//                         value={profileData.Ifsccode}
//                         onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <div
//                   style={{
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     marginTop: "4rem",
//                   }}
//                 >
//                   <Button
//                     className="submit-btn"
//                     type="submit"
//                     style={{
//                       backgroundColor: "#4a2a7d",
//                       borderRadius: "30px",
//                       width: "20rem",
//                     }}
//                   >
//                     Submit
//                   </Button>
//                 </div>
//               </Form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PersonalDetails;

////////////////////////////////////////////////////////////////

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { Form, Button, Row, Col } from "react-bootstrap";
// import { baseUrl } from "../utils/baseUrl";
// import {
//   CountrySelect,
//   StateSelect,
//   CitySelect,
// } from "react-country-state-city";
// import "react-country-state-city/dist/react-country-state-city.css";

// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// const PersonalDetails = () => {
//   const token = sessionStorage.getItem("token");

//   const [country, setCountryid] = useState(101);
//   const [state, setStateid] = useState(0);
//   const [city, setCityid] = useState(0);

//   const handleCountryChange = (country) => {
//     setCountryid(country.id);
//     setStateid(0);
//     setCityid(0);
//   };

//   const handleStateChange = (state) => {
//     setStateid(state.id);
//     profileData.state = state.name;
//     setCityid(0);
//   };

//   const handleCityChange = (city) => {
//     setCityid(city.id);
//     profileData.city = city.name;
//   };

//   useEffect(() => {
//     getProfileData();
//   }, []);

//   const [profileData, setProfileData] = useState({
//     name: "",
//     email: "",
//     mobileNo: "",
//     city: "",
//     state: "",
//     Address: "",
//     panNo: "",
//     aadharNo: "",
//     accHoldername: "",
//     accNo: "",
//     Ifsccode: "",
//     dob: "",
//     userName: "",
//   });

//   const getProfileData = async () => {
//     try {
//       const url = `${baseUrl}/profile`;
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${token}`,
//       };
//       const response = await axios.get(url, { headers });
//       const profile = response.data.data[0];
//       console.log("Profile data", profile);
//       setProfileData(profile);
//     } catch (error) {
//       console.error("Error connecting to API", error);
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const url = `${baseUrl}/profileUpdate`;
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${token}`,
//       };
//       const response = await axios.put(url, profileData, { headers });
//       console.log("Profile updated successfully", response.status);
//       toast.success("Profile updated successfully");
//     } catch (error) {
//       console.error("Error updating profile", error);
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setProfileData({
//       ...profileData,
//       [name]: value,
//     });
//   };

//   return (
//     <div className="container-fluid">
//       <ToastContainer />
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <div className="card" style={{ width: "54rem" }}>
//           <div className="kyc-details">
//             <div className="registration-form" style={{ padding: "0rem" }}>
//               <h4 className="mb-4 center-text text-color">Profile Details</h4>
//               <h4 className="mb-4 text-color">Personal Details</h4>
//               <Form onSubmit={handleSubmit}>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="formName">
//                       <Form.Label>Name</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter Full Name"
//                         name="name"
//                         value={profileData.name}
//                         onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formEmail">
//                       <Form.Label>Email</Form.Label>
//                       <Form.Control
//                         type="email"
//                         placeholder="Enter Email"
//                         name="email"
//                         value={profileData.email}
//                         onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formPhone">
//                       <Form.Label>Mobile No.</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter Mobile No"
//                         name="mobileNo"
//                         value={profileData.mobileNo}
//                         onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="formAadhar">
//                       <Form.Label>Aadhar No.</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter Aadhar No."
//                         name="aadharNo"
//                         value={profileData.aadharNo}
//                         onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formDOB">
//                       <Form.Label>Date of Birth</Form.Label>
//                       <Form.Control
//                         type="date"
//                         placeholder="Enter Date of Birth"
//                         name="dob"
//                         value={profileData.dob}
//                         onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <div className="mt-4 text-center"></div>
//                 <h4 className="mb-4 center-text text-color ">
//                   Address Details
//                 </h4>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="formAddress">
//                       <Form.Label>Address</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter Your Address"
//                         name="Address"
//                         value={profileData.Address}
//                         onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formState">
//                       <Form.Label>State</Form.Label>
//                       <StateSelect
//                         placeHolder={profileData.state}
//                         type="text"
//                         name="state"
//                         countryid={country}
//                         onChange={(state) => handleStateChange(state)}
//                         value={profileData.state}
//                         className="form-control"
//                       />
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formCity">
//                       <Form.Label>City</Form.Label>
//                       <CitySelect
//                         placeHolder={profileData.city}
//                         type="text"
//                         name="city"
//                         countryid={country}
//                         stateid={state}
//                         onChange={(city) => handleCityChange(city)}
//                         value={profileData.city}
//                         className="form-control"
//                       />
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <div className="mt-4 text-center"></div>
//                 <h4 className="mb-4 center-text text-color">Bank Detail</h4>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="accountHolderName">
//                       <Form.Label>Account Holder's Name</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter Account Holder's Name"
//                         name="accHoldername"
//                         value={profileData.accHoldername}
//                         onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="accountNo">
//                       <Form.Label>Account No.</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter Account No."
//                         name="accNo"
//                         value={profileData.accNo}
//                         onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formPanNumber">
//                       <Form.Label>PAN No.</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter PAN No."
//                         name="panNo"
//                         value={profileData.panNo}
//                         onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="ifscCode">
//                       <Form.Label>IFSC Code</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter IFSC Code"
//                         name="Ifsccode"
//                         value={profileData.Ifsccode}
//                         onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <div
//                   style={{
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     marginTop: "4rem",
//                   }}
//                 >
//                   <Button
//                     className="submit-btn"
//                     type="submit"
//                     style={{
//                       backgroundColor: "#4a2a7d",
//                       borderRadius: "30px",
//                       width: "20rem",
//                     }}
//                   >
//                     Update
//                   </Button>
//                 </div>
//               </Form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PersonalDetails;

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { Form, Button, Row, Col } from "react-bootstrap";
// import { Paper } from "@mui/material";

// const PersonalDetails = () => {
//   const token = sessionStorage.getItem("token");
//   console.log(token);

//   useEffect(() => {
//     getProfileData();
//   }, []);

//   const [profileData, setProfileData] = useState({
//     name: "",
//     email: "",
//     mobileNo: "",
//     city: "",
//     state: "",
//     address: "",
//     panNo: "",
//     aadharNo: "",
//     accHolderName: "",
//     accNo: "",
//     registerType: "",
//     ifscCode: "",
//     companyAddress: "",
//     userName: "",
//   });

//   const getProfileData = async () => {
//     try {
//       const url = `${baseUrl}/profile";
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${token}`,
//       };
//       const response = await axios.get(url, { headers });
//       const profile = response.data.data[0];
//       console.log("Profile data", profile);
//       setProfileData(profile);
//     } catch (error) {
//       console.error("Error connecting to API", error);
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const url = `https://app.batuk.in/profileUpdate/${profileData._id}`;
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${token}`,
//       };
//       const response = await axios.put(url, profileData, { headers });
//       console.log("Profile updated successfully", response.data);
//       // Optionally show success message or update state
//     } catch (error) {
//       console.error("Error updating profile", error);
//       // Optionally show error message or handle error state
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setProfileData({
//       ...profileData,
//       [name]: value,
//     });
//   };

//   return (
//     <div className="container-fluid">
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <div className="card" style={{ width: "54rem" }}>
//           <div className="kyc-details">
//             <div className="registration-form" style={{ padding: "0rem" }}>
//               <h4 className="mb-4 center-text text-color">Profile Details</h4>
//               <h4 className="mb-4 text-color">Personal Details</h4>
//               <Form onSubmit={handleSubmit}>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="formName">
//                       <Form.Label>Name</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter Full Name"
//                         name="name"
//                         value={profileData.name}
//                         onChange={handleChange}
//                         // disabled
//                       />
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formEmail">
//                       <Form.Label>Email</Form.Label>
//                       <Form.Control
//                         type="email"
//                         placeholder="Enter Email"
//                         name="email"
//                         value={profileData.email}
//                         onChange={handleChange}
//                         // disabled
//                       />
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formPhone">
//                       <Form.Label>Mobile No.</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter Mobile No"
//                         name="mobileNo"
//                         value={profileData.mobileNo}
//                         onChange={handleChange}
//                         // disabled
//                       />
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="formAadhar">
//                       <Form.Label>Aadhar No.</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter Aadhar No."
//                         name="aadharNo"
//                         value={profileData.aadharNo}
//                         onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <div className="mt-4 text-center"></div>
//                 <h4 className="mb-4 center-text text-color ">
//                   Address Details
//                 </h4>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="formAddress">
//                       <Form.Label>Address</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter Your Address"
//                         name="address"
//                         value={profileData.address}
//                         onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formState">
//                       <Form.Label>State</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter State"
//                         name="state"
//                         value={profileData.state}
//                         onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formCity">
//                       <Form.Label>City</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter City"
//                         name="city"
//                         value={profileData.city}
//                         onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="formZip">
//                       <Form.Label>PIN Code</Form.Label>
//                       <Form.Control
//                         type="number"
//                         placeholder="Enter PIN Code"
//                         name="zip"
//                         value={profileData.zip}
//                         onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formCompanyAddress">
//                       <Form.Label>Company Address</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter Company Address"
//                         name="companyAddress"
//                         value={profileData.companyAddress}
//                         onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <div className="mt-4 text-center"></div>
//                 <h4 className="mb-4 center-text text-color">Bank Detail</h4>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="accountHolderName">
//                       <Form.Label>Account Holder's Name</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter Account Holder's Name"
//                         name="accHolderName"
//                         value={profileData.accHolderName}
//                         onChange={handleChange}
//                         // disabled
//                       />
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="accountNo">
//                       <Form.Label>Account No.</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter Account No."
//                         name="accNo"
//                         value={profileData.accNo}
//                         onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formPanNumber">
//                       <Form.Label>PAN No.</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter PAN No."
//                         name="panNo"
//                         value={profileData.panNo}
//                         onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="ifscCode">
//                       <Form.Label>IFSC Code</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter IFSC Code"
//                         name="ifscCode"
//                         value={profileData.ifscCode}
//                         onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <div
//                   style={{
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     marginTop: "4rem",
//                   }}
//                 >
//                   <Button
//                     className="submit-btn"
//                     type="submit"
//                     style={{
//                       backgroundColor: "#4a2a7d",
//                       borderRadius: "30px",
//                       width: "20rem",
//                     }}
//                   >
//                     Submit
//                   </Button>
//                 </div>
//               </Form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PersonalDetails;

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { Form, Button, Row, Col } from "react-bootstrap";
// import { Paper } from "@mui/material";

// const PersonalDetails = () => {
//   const token = sessionStorage.getItem("token");

//   useEffect(() => {
//     getProfileData();
//   }, []);

//   const [profileData, setProfileData] = useState({
//     name: "",
//     email: "",
//     mobileNo: "",
//     city: "",
//     state: "",
//     address: "",
//     panNo: "",
//     aadharNo: "",
//     accHolderName: "",
//     accNo: "",
//     registerType: "",
//     ifscCode: "",
//     companyAddress: "",
//     userName: "",
//   });

//   const getProfileData = async () => {
//     try {
//       const url = `${baseUrl}/profile";
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${token}`,
//       };
//       const response = await axios.get(url, { headers });
//       const profile = response.data.data[0];
//       console.log("Profile data", profile);
//       setProfileData(profile);
//     } catch (error) {
//       console.error("Error connecting to API", error);
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const url = `https://app.batuk.in/profileUpdate/${profileData._id}`;
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${token}`,
//       };
//       const response = await axios.put(url, profileData, { headers });
//       console.log("Profile updated successfully", response.data);
//       // Optionally show success message or update state
//     } catch (error) {
//       console.error("Error updating profile", error);
//       // Optionally show error message or handle error state
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setProfileData({
//       ...profileData,
//       [name]: value,
//     });
//   };

//   return (
//     <div className="container-fluid">
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <div className="card" style={{ width: "54rem" }}>
//           <div className="kyc-details">
//             <div className="registration-form" style={{ padding: "0rem" }}>
//               <h4 className="mb-4 center-text text-color">Profile Details</h4>
//               <h4 className="mb-4 text-color">Personal Details</h4>
//               <Form onSubmit={handleSubmit}>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="formName">
//                       <Form.Label>Name</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter Full Name"
//                         name="name"
//                         // value={formData.name}
//                         // onChange={handleChange}
//                         // disabled
//                       />
//                       {/* {errors.name && ( */}
//                       {/* <small className="text-danger">{errors.name}</small> */}
//                       {/* )} */}
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formEmail">
//                       <Form.Label>Email</Form.Label>
//                       <Form.Control
//                         type="email"
//                         placeholder="Enter Email"
//                         name="email"
//                         // value={formData.email}
//                         // onChange={handleChange}
//                         // disabled
//                       />
//                       {/* {errors.email && ( */}
//                       {/* <small className="text-danger">{errors.email}</small> */}
//                       {/* )} */}
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formPhone">
//                       <Form.Label>Mobile No.</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter Mobile No"
//                         name="mobileNumber"
//                         // value={formData.mobileNumber}
//                         // onChange={handleChange}
//                         // disabled
//                       />
//                       {/* {errors.mobileNumber && ( */}
//                       {/* <small className="text-danger">
//                           {errors.mobileNumber}
//                         </small> */}
//                       {/* )} */}
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="formAddress1">
//                       <Form.Label>Aadhar No.</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter Your Address"
//                         name="aadhar"
//                         // value={formData.address_line_1}
//                         // onChange={handleChange}
//                       />
//                       {/* {errors.address_line_1 && ( */}
//                       {/* <small className="text-danger">
//                           {errors.address_line_1}
//                         </small> */}
//                       {/* )} */}
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <div className="mt-4 text-center"></div>
//                 <h4 className="mb-4 center-text text-color ">
//                   Address Details
//                 </h4>

//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="formAddress2">
//                       <Form.Label>Address</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter Your Address"
//                         name="address_line_2"
//                         // value={formData.address_line_2}
//                         // onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>

//                   <Col md={4}>
//                     <Form.Group controlId="formState">
//                       <Form.Label>State</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter State"
//                         name="state"
//                         // value={formData.state}
//                         // onChange={handleChange}
//                       />
//                       {/* {errors.state && ( */}
//                       {/* <small className="text-danger">{errors.state}</small> */}
//                       {/* )} */}
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formCity">
//                       <Form.Label>City</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter City"
//                         name="city"
//                         // value={formData.city}
//                         // onChange={handleChange}
//                       />
//                       {/* {errors.city && ( */}
//                       {/* <small className="text-danger">{errors.city}</small> */}
//                       {/* )} */}
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="formPin">
//                       <Form.Label>PIN Code</Form.Label>
//                       <Form.Control
//                         type="number"
//                         placeholder="Enter PIN Code"
//                         name="zip"
//                         // value={formData.zip}
//                         // onChange={handleChange}
//                       />
//                       {/* {errors.zip && ( */}
//                       {/* <small className="text-danger">{errors.zip}</small> */}
//                       {/* )} */}
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formPin">
//                       <Form.Label>Company Address</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter company address"
//                         name="comapny"
//                         // value={formData.zip}
//                         // onChange={handleChange}
//                       />
//                       {/* {errors.zip && ( */}
//                       {/* <small className="text-danger">{errors.zip}</small> */}
//                       {/* )} */}
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <div className="mt-4 text-center"></div>
//                 <h4 className="mb-4 center-text text-color">Bank Detail</h4>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="accountHolderName">
//                       <Form.Label>Account Holder's Name</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter Account Holder's Name"
//                         name="accountHolderName"
//                         // value={formData.accountHolderName}
//                         // onChange={handleChange}
//                         // disabled
//                       />
//                       {/* {errors.accountHolderName && ( */}
//                       {/* <small className="text-danger">
//                           {errors.accountHolderName}
//                         </small> */}
//                       {/* )} */}
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="accountNo">
//                       <Form.Label>Account No.</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter Account No."
//                         name="bank_account_no"
//                         // value={formData.bank_account_no}
//                         // onChange={handleChange}
//                       />
//                       {/* {errors.bank_account_no && ( */}
//                       {/* <small className="text-danger">
//                           {errors.bank_account_no}
//                         </small> */}
//                       {/* )} */}
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formPanNumber">
//                       <Form.Label>PAN No.</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter PAN No."
//                         name="PanNumber"
//                         // value={formData.PanNumber
//                         // onChange={handleChange}
//                       />
//                       {/* {errors.PanNumber && ( */}
//                       {/* <small className="text-danger">
//                           {errors.PanNumber}
//                         </small> */}
//                       {/* )} */}
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="ifscCode">
//                       <Form.Label>IFSC Code</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter IFSC Code"
//                         name="ifsc_code"
//                         // value={formData.ifsc_code}
//                         // onChange={handleChange}
//                       />
//                       {/* {errors.ifsc_code && ( */}
//                       {/* <small className="text-danger">
//                           {errors.ifsc_code}
//                         </small> */}
//                       {/* )} */}
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <div
//                   style={{
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     marginTop: "4rem",
//                   }}
//                 >
//                   <Button
//                     className="submit-btn"
//                     type="submit"
//                     style={{
//                       backgroundColor: "#4a2a7d",
//                       borderRadius: "30px",
//                       width: "20rem",
//                     }}
//                   >
//                     Submit
//                   </Button>
//                 </div>
//               </Form>
//             </div>
//           </div>
//         </div>
//       </div>

//     </div>
//   );
// };

// export default PersonalDetails;

// <div className="row mb-3">
//   <div className="col-sm-12">
//     <Paper elevation={3} sx={{ padding: "25px" }}>
//       <h3 className="profileHeader">Profile Details</h3>
//       <Form onSubmit={handleSubmit}>
//         <Row className="mb-3">
//           <Col md={6}>
//             <Form.Group controlId="name">
//               <Form.Label>Name</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter Name"
//                 name="name"
//                 value={profileData.name}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//           <Col md={6}>
//             <Form.Group controlId="email">
//               <Form.Label>Email</Form.Label>
//               <Form.Control
//                 type="email"
//                 placeholder="Enter Email"
//                 name="email"
//                 value={profileData.email}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//         </Row>
//         <Row className="mb-3">
//           <Col md={6}>
//             <Form.Group controlId="mobileNo">
//               <Form.Label>Mobile Number</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter Mobile Number"
//                 name="mobileNo"
//                 value={profileData.mobileNo}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//           <Col md={6}>
//             <Form.Group controlId="city">
//               <Form.Label>City</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter City"
//                 name="city"
//                 value={profileData.city}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//         </Row>
//         <Row className="mb-3">
//           <Col md={6}>
//             <Form.Group controlId="state">
//               <Form.Label>State</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter State"
//                 name="state"
//                 value={profileData.state}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//           <Col md={6}>
//             <Form.Group controlId="address">
//               <Form.Label>Address</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter Address"
//                 name="address"
//                 value={profileData.address}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//         </Row>
//         <Row className="mb-3">
//           <Col md={6}>
//             <Form.Group controlId="panNo">
//               <Form.Label>PAN Number</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter PAN Number"
//                 name="panNo"
//                 value={profileData.panNo}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//           <Col md={6}>
//             <Form.Group controlId="aadharNo">
//               <Form.Label>Aadhar Number</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter Aadhar Number"
//                 name="aadharNo"
//                 value={profileData.aadharNo}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//         </Row>
//         <Row className="mb-3">
//           <Col md={6}>
//             <Form.Group controlId="accHolderName">
//               <Form.Label>Account Holder's Name</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter Account Holder's Name"
//                 name="accHolderName"
//                 value={profileData.accHolderName}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//           <Col md={6}>
//             <Form.Group controlId="accNo">
//               <Form.Label>Account Number</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter Account Number"
//                 name="accNo"
//                 value={profileData.accNo}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//         </Row>
//         <Row className="mb-3">
//           <Col md={6}>
//             <Form.Group controlId="registerType">
//               <Form.Label>Register Type</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter Register Type"
//                 name="registerType"
//                 value={profileData.registerType}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//           <Col md={6}>
//             <Form.Group controlId="ifscCode">
//               <Form.Label>IFSC Code</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter IFSC Code"
//                 name="ifscCode"
//                 value={profileData.ifscCode}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//         </Row>
//         <Row className="mb-3">
//           <Col md={6}>
//             <Form.Group controlId="companyAddress">
//               <Form.Label>Company Address</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter Company Address"
//                 name="companyAddress"
//                 value={profileData.companyAddress}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//           <Col md={6}>
//             <Form.Group controlId="userName">
//               <Form.Label>Username</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter Username"
//                 name="userName"
//                 value={profileData.userName}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//         </Row>
//         <Button variant="primary" type="submit">
//           Submit
//         </Button>
//       </Form>
//     </Paper>
//   </div>
// </div>;
