import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import {
  CountrySelect,
  StateSelect,
  CitySelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";

const FormPart2 = ({ formData, handleChange, nextStep, prevStep }) => {
  const [validated, setValidated] = useState(false);

  const [country, setCountryid] = useState(101);
  const [state, setStateid] = useState(0);
  const [city, setCityid] = useState(0);

  const handleCountryChange = (country) => {
    setCountryid(country.id);
    setStateid(0);
    setCityid(0);
  };

  const handleStateChange = (state) => {
    setStateid(state.id);
    formData.state = state.name;
    setCityid(0); // Reset city selection when state changes
  };

  const handleCityChange = (city) => {
    setCityid(city.id);
    formData.city = city.name;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      nextStep();
    }
    setValidated(true);
  };

  return (
    <div className="registration-form">
      <h2 className="mb-4 center-text">Complete Your KYC</h2>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="row-bottom">
          <Col md={6}>
            <Form.Group controlId="formPan">
              <Form.Label>PAN no.</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter PAN"
                name="panNo"
                value={formData.panNo}
                onChange={handleChange}
                maxLength={10}
                pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid PAN number.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formGstin">
              <Form.Label>GSTIN (Optional)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter GSTIN"
                name="Gstin"
                value={formData.Gstin}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="row-bottom">
          <Col md={6}>
            <Form.Group controlId="formAuthorizedPersonName">
              <Form.Label>Authorized Person Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Full Name"
                name="authpersonName"
                value={formData.authpersonName}
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide the authorized person's name.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formMobileNo">
              <Form.Label>Mobile No.</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Mobile No."
                name="mobileNo"
                value={formData.mobileNo}
                onChange={handleChange}
                required
                maxLength={10}
                pattern="\d{10}"
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid 10-digit mobile number.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="row-bottom">
          <Col md={6}>
            <Form.Group controlId="formAddress">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Your Address"
                name="Address"
                value={formData.Address}
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide your address.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formState">
              <Form.Label>State</Form.Label>
              <StateSelect
                placeHolder="Select State"
                countryid={country}
                onChange={(state) => handleStateChange(state)}
                value={formData.state}
                className="form-control"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please select a state.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="row-bottom">
          <Col md={6}>
            <Form.Group controlId="formCity">
              <Form.Label>City</Form.Label>
              <CitySelect
                placeHolder="Select City"
                countryid={country}
                stateid={state}
                onChange={(city) => handleCityChange(city)}
                value={formData.city}
                className="form-control"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please select a city.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formPincode">
              <Form.Label>Pincode</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter 6-digit Pincode"
                name="pincode"
                value={formData.pincode}
                onChange={handleChange}
                required
                maxLength={6}
                pattern="\d{6}"
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid 6-digit pincode.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <div className="mt-4 text-center">
          <p style={{ marginBottom: "3rem" }}>
            Already have an account? <a href="/login">Login</a>
          </p>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Button
              variant="secondary"
              className="mr-2 prev-btn"
              onClick={prevStep}
            >
              Previous
            </Button>
            <Button
              className="submit-btn mr-2 prev-btn"
              style={{
                backgroundColor: "#4a2a7d",
                borderRadius: "30px",
              }}
              type="submit"
            >
              Next
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default FormPart2;

// import React from "react";
// import { Form, Button, Row, Col } from "react-bootstrap";

// const FormPart2 = ({ formData, handleChange, nextStep, prevStep }) => {
//   return (
//     <div className="registration-form">
//       <h2 className="mb-4 center-text">Complete Your KYC</h2>
//       <Form>
//         <Row className="row-bottom">
//           <Col md={6}>
//             <Form.Group controlId="formPan">
//               <Form.Label>PAN no.</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter PAN"
//                 name="pan"
//                 value={formData.pan}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//           <Col md={6}>
//             <Form.Group controlId="formGstin">
//               <Form.Label>GSTIN (Optional)</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter GSTIN"
//                 name="gstin"
//                 value={formData.gstin}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//         </Row>
//         <Row className="row-bottom">
//           <Col md={6}>
//             <Form.Group controlId="formAuthorizedPersonName">
//               <Form.Label>Authorized PersonName</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter Full Name"
//                 name="authorizedPersonName"
//                 value={formData.authorizedPersonName}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//           <Col md={6}>
//             <Form.Group controlId="formMobileNo">
//               <Form.Label>Mobile No.</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter Mobile No."
//                 name="mobileNo"
//                 value={formData.mobileNo}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//         </Row>
//         <Row className="row-bottom">
//           <Col md={6}>
//             <Form.Group controlId="formAddress">
//               <Form.Label>Address</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter Your Address"
//                 name="address"
//                 value={formData.address}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//           <Col md={6}>
//             <Form.Group controlId="formCity">
//               <Form.Label>City</Form.Label>
//               <Form.Control
//                 as="select"
//                 name="city"
//                 value={formData.city}
//                 onChange={handleChange}
//               >
//                 <option value="">Select City</option>
//                 <option value="City1">City1</option>
//                 <option value="City2">City2</option>
//               </Form.Control>
//             </Form.Group>
//           </Col>
//         </Row>
//         <Row className="row-bottom">
//           <Col md={6}>
//             <Form.Group controlId="formState">
//               <Form.Label>State</Form.Label>
//               <Form.Control
//                 as="select"
//                 name="state"
//                 value={formData.state}
//                 onChange={handleChange}
//               >
//                 <option value="">Select State</option>
//                 <option value="State1">State1</option>
//                 <option value="State2">State2</option>
//               </Form.Control>
//             </Form.Group>
//           </Col>
//           <Col md={6}>
//             <Form.Group controlId="formPincode">
//               <Form.Label>Pincode</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="6-digit Pincode"
//                 name="pincode"
//                 value={formData.pincode}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//         </Row>
//         <div className="mt-4 text-center">
//           <p>
//             Already have an account? <a href="/login">Login</a>
//           </p>
//           <Button
//             variant="secondary"
//             className="mr-2 prev-btn"
//             onClick={prevStep}
//           >
//             Previous
//           </Button>
//           <Button
//             className="submit-btn"
//             style={{ backgroundColor: "#4a2a7d", borderRadius: "30px" }}
//             onClick={nextStep}
//           >
//             Next
//           </Button>
//         </div>
//       </Form>
//     </div>
//   );
// };

// export default FormPart2;
