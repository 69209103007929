import React from "react";
import NavBar1 from "../../components/Navbar";

const Faq = () => {
  return (
    <>
      <NavBar1 />
      <div>Faq</div>
    </>
  );
};

export default Faq;
