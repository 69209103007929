import React, { useState } from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Ci from "../assets/images/Curated Icons.png";
import Cd from "../assets/images/Completely digitalised.png";
import Bs from "../assets/images/Bank Grade Security.png";
import Hc from "../assets/images/Help Center.png";
import image1 from "../assets/images/1.png";
import image2 from "../assets/images/2.png";
import image3 from "../assets/images/3.png";
import NavBar1 from "../components/Navbar";
import { baseUrl } from "../utils/baseUrl";
import "bootstrap/dist/css/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({ email: "" });
  const [authError, setAuthError] = useState("");

  const isEmail = (input) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(input);
  };

  const validate = () => {
    let emailError = "";

    if (!email) {
      emailError = "Email is required";
    } else if (!isEmail(email)) {
      emailError = "Invalid email format";
    }

    if (emailError) {
      setErrors({ email: emailError });
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = validate();
    if (!isValid) return;

    try {
      const response = await axios.post(`${baseUrl}/forgotpassword`, {
        email: email.toLowerCase(),
      });
      console.log(response, "response");
      if (response.error == true) {
        toast.error(response.message);
      } else {
        toast.success(response.message);
        navigate("/reset-password/:id");
      }

      // if (response.status === 200) {
      //   toast.success("Password reset link sent to your email");
      //   navigate("/reset-password/:id");
      // }
    } catch (error) {
      setAuthError("An error occurred. Please try again.");
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <>
      <NavBar1 />
      <ToastContainer />
      <Container fluid className="otp-form-container">
        <Row
          className="d-flex align-items-center justify-content-center vh-100"
          style={{ marginTop: "0.9rem" }}
        >
          <Col
            xl={5}
            lg={5}
            md={12}
            sm={12}
            className="text-center bg-light-primary full-height"
          >
            <Carousel
              controls={false}
              indicators={false}
              interval={3000}
              pause={false}
              className="mt-xl-10 mt-lg-5 mt-md-3 mt-sm-1"
            >
              <Carousel.Item>
                <img
                  className="d-block w-100 img-height"
                  src={image1}
                  alt="First slide"
                  style={{ marginTop: "10rem" }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100 img-height"
                  src={image2}
                  alt="Second slide"
                  style={{ marginTop: "10rem" }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100 img-height"
                  src={image3}
                  alt="Third slide"
                  style={{ marginTop: "10rem" }}
                />
              </Carousel.Item>
            </Carousel>
          </Col>
          <Col xl={7} lg={7} md={12} sm={12}>
            <div
              className="otp-form d-flex flex-column justify-content-center"
              style={{ height: "80vh" }}
            >
              <div
                className="form-center"
                style={{ marginTop: "11rem", marginBottom: "3rem" }}
              >
                <h2>Forgot Password</h2>
                <p>If you forgot your password please enter your email here</p>
                <div
                  className="p-4 mx-auto"
                  style={{ width: "100%", maxWidth: "24rem" }}
                >
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <input
                        style={{
                          border: "2px solid #4a2a7d",
                          borderRadius: "8px",
                          fontSize: "15px",
                          width: "100%",
                        }}
                        type="text"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        id="email"
                      />
                      {errors.email && (
                        <div className="text-danger">{errors.email}</div>
                      )}
                    </div>
                    {authError && (
                      <div className="text-danger">{authError}</div>
                    )}
                    <button
                      className="btn py-2 w-100"
                      style={{
                        backgroundColor: "#4a2a7d",
                        color: "white",
                        marginTop: "20px",
                        borderRadius: "30px",
                      }}
                      type="submit"
                    >
                      Forgot Password
                    </button>
                    <p style={{ marginTop: "1rem", fontSize: "15px" }}>
                      <Link to="/login" style={{ color: "#4a2a7d" }}>
                        Login Now
                      </Link>
                    </p>
                  </form>
                </div>
              </div>
              <Row className="features mt-4">
                <Col
                  xs={6}
                  sm={6}
                  md={3}
                  className="d-flex justify-content-center mb-3 mb-md-0"
                >
                  <img
                    src={Ci}
                    className="bottom-logo img-fluid"
                    alt="Curated Investing"
                  />
                </Col>
                <Col
                  xs={6}
                  sm={6}
                  md={3}
                  className="d-flex justify-content-center mb-3 mb-md-0"
                >
                  <img
                    src={Cd}
                    className="bottom-logo img-fluid"
                    alt="Completely Digitalised"
                  />
                </Col>
                <Col
                  xs={6}
                  sm={6}
                  md={3}
                  className="d-flex justify-content-center mb-3 mb-md-0"
                >
                  <img
                    src={Bs}
                    className="bottom-logo img-fluid"
                    alt="Bank Grade Security"
                  />
                </Col>
                <Col
                  xs={6}
                  sm={6}
                  md={3}
                  className="d-flex justify-content-center mb-3 mb-md-0"
                >
                  <img
                    src={Hc}
                    className="bottom-logo img-fluid"
                    alt="Help Center"
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ForgotPassword;
