// import React from "react";
// import { Navbar, Nav, Dropdown } from "react-bootstrap";
// import Logo from "../assets/images/Batuk Logo.png";
// import "../assets/css/navbar.css";
// import { Link } from "react-router-dom";

// const HeaderNavbar = () => {
//   return (
//     <Navbar
//       expand="lg"
//       className="header-navbar css-r6ewbb-MuiToolbar-root"
//       style={{ height: "5rem" }}
//     >
//       <Navbar.Brand href="/" className="header-brand">
//         <img
//           src={Logo}
//           width="200"
//           height="auto"
//           className="d-inline-block align-top"
//           alt="LOGO"
//         />
//       </Navbar.Brand>
//       <Navbar.Toggle aria-controls="basic-navbar-nav" />
//       <Navbar.Collapse
//         id="basic-navbar-nav"
//         style={{ justifyContent: "center" }}
//       >
//         <Nav className="ml-auto">
//           <Nav.Link>
//             <Link className="header-link" to="/about">
//               About
//             </Link>
//           </Nav.Link>
//           <Nav.Link>
//             <Link className="header-link" to="/blogs">
//               Blogs
//             </Link>
//           </Nav.Link>
//           <Nav.Link>
//             <Link className="header-link" to="/faq">
//               FAQ
//             </Link>
//           </Nav.Link>
//           <Nav.Link>
//             <Link className="header-link" to="/insights">
//               Insights
//             </Link>
//           </Nav.Link>
//           <Nav.Link>
//             <Link className="header-link" to="/contact">
//               Contact us
//             </Link>
//           </Nav.Link>
//         </Nav>
//       </Navbar.Collapse>
//       <div className="reg-log">
//         <Nav.Link>
//           <Link className="header-login" to="/login">
//             Login
//           </Link>
//         </Nav.Link>
//         <Dropdown>
//           <Dropdown.Toggle
//             variant="link"
//             id="dropdown-basic"
//             className="header-register"
//           >
//             Register
//           </Dropdown.Toggle>
//           <Dropdown.Menu className="dropdown-menu-custom">
//             <Dropdown.Item>
//               <Link to="/register">Individual Partner</Link>
//             </Dropdown.Item>
//             <Dropdown.Item>
//               <Link to="/indRegister"> Entity Partner</Link>
//             </Dropdown.Item>
//           </Dropdown.Menu>
//         </Dropdown>
//       </div>
//     </Navbar>
//   );
// };

// export default HeaderNavbar;

// import React, { useState, useEffect } from "react";
// import Logo from "../assets/images/Batuk Logo.png";
// import { Navbar, Nav, Dropdown } from "react-bootstrap";
// import { Link } from "react-router-dom";

// const NavBar1 = () => {
//   const [isMobile, setIsMobile] = useState(window.innerWidth < 991);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth < 991);
//     };

//     window.addEventListener("resize", handleResize);
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   return (
//     <Navbar
//       expand="lg"
//       className="header-navbar background-color"
//       style={{ height: "5rem", backgroundColor: "red" }}
//     >
//       <Navbar.Brand
//         href="/"
//         className="header-brand"
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <img
//           src={Logo}
//           width="200"
//           height="auto"
//           className="d-inline-block align-top"
//           alt="LOGO"
//         />
//       </Navbar.Brand>
//       <div className="menuToggle">
//         <input type="checkbox" />
//         <span></span>
//         <span></span>
//         <span></span>
//         <ul className="menuItem">
//           <li>
//             <a href="#">Home</a>
//           </li>
//           <li>
//             <a href="#">About</a>
//           </li>
//           <li>
//             <a href="#">Info</a>
//           </li>
//           <li>
//             <a href="#">Contact</a>
//           </li>
//           <li>
//             <a href="#">Show me more</a>
//           </li>
//         </ul>
//       </div>

//       <div className="reg-log">
//         <Nav.Link href="/login" className="header-login">
//           Login
//         </Nav.Link>
//         <Dropdown>
//           <Dropdown.Toggle
//             variant="link"
//             id="dropdown-basic"
//             className="header-register"
//             style={{ textDecoration: "none" }}
//           >
//             Register
//           </Dropdown.Toggle>
//           <Dropdown.Menu className="dropdown-menu-custom">
//             <Dropdown.Item>
//               <Link to="/register">Individual Partner</Link>
//             </Dropdown.Item>
//             <Dropdown.Item>
//               <Link to="/indRegister">Entity Partner</Link>
//             </Dropdown.Item>
//           </Dropdown.Menu>
//         </Dropdown>
//       </div>
//     </Navbar>
//   );
// };

// export default NavBar1;

// import React from "react";
// import "../assets/css/navbar.css";

// const NavBar1 = () => {
//   return (
//     <header>
//       <nav className="menu--right" role="navigation">
//         <div className="menuToggle">
//           <input type="checkbox" />
//           <span></span>
//           <span></span>
//           <span></span>
//           <ul className="menuItem">
//             <li>
//               <a href="#">Home</a>
//             </li>
//             <li>
//               <a href="#">About</a>
//             </li>
//             <li>
//               <a href="#">Info</a>
//             </li>
//             <li>
//               <a href="#">Contact</a>
//             </li>
//             <li>
//               <a href="#">Show me more</a>
//             </li>
//           </ul>
//         </div>
//       </nav>
//     </header>
//   );
// };

// export default NavBar1;

// import React, { useState } from "react";
// import "../assets/css/navbar.css";

// const Navbar1 = () => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <nav className="navbar">
//       <div className="navbar-logo">
//         <a href="/">Logo</a>
//       </div>
//       <ul className={isOpen ? "navbar-links active" : "navbar-links"}>
//         <li>
//           <a href="/about">About</a>
//         </li>
//         <li>
//           <a href="/blogs">Blogs</a>
//         </li>
//         <li>
//           <a href="/faq">FAQ</a>
//         </li>
//         <li>
//           <a href="/insights">Insights</a>
//         </li>
//         <li>
//           <a href="/contact">Contact</a>
//         </li>
//         <div>
//           <li>
//             <a href="/login">Login</a>
//           </li>
//           <li>
//             <a href="/register">Register</a>
//           </li>
//         </div>
//       </ul>
//       <div className="hamburger-menu" onClick={toggleMenu}>
//         <div className={isOpen ? "line line-1 open" : "line line-1"}></div>
//         <div className={isOpen ? "line line-2 open" : "line line-2"}></div>
//         <div className={isOpen ? "line line-3 open" : "line line-3"}></div>
//       </div>
//     </nav>
//   );
// };

// export default Navbar1;

///////////////////////////////////////////////////////

// import React, { useState } from "react";
// import "../assets/css/navbar.css";

// const Navbar1 = () => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <nav className="navbar">
//       <div className="col-4">
//         <div className="navbar-logo">
//           <a href="/">Logo</a>
//         </div>
//       </div>

//       {/* Hamburger Menu Section */}
//       <div className="hamburger-menu" onClick={toggleMenu}>
//         <div className={isOpen ? "line line-1 open" : "line line-1"}></div>
//         <div className={isOpen ? "line line-2 open" : "line line-2"}></div>
//         <div className={isOpen ? "line line-3 open" : "line line-3"}></div>
//       </div>

//       {/* Navigation Links (for Small Screens) */}
//       <ul className={isOpen ? "navbar-links active" : "navbar-links"}>
//         <li>
//           <a href="/about">About</a>
//         </li>
//         <li>
//           <a href="/blogs">Blogs</a>
//         </li>
//         <li>
//           <a href="/faq">FAQ</a>
//         </li>
//         <li>
//           <a href="/insights">Insights</a>
//         </li>
//         <li>
//           <a href="/contact">Contact</a>
//         </li>
//         <li className="right-align">
//           <a href="/login">Login</a>
//         </li>
//         <li className="dropdown">
//           <a href="">Register</a>
//           <div className="dropdown-content">
//             <a href="/register">Register1</a>
//             <a href="/register">Register2</a>
//           </div>
//         </li>
//       </ul>
//     </nav>
//   );
// };

// export default Navbar1;

/////////////////////////////////////////////////
import React, { useState } from "react";
import Logo from "../assets/images/Batuk Logo.png";
import "../assets/css/navbar.css";
import { Link } from "react-router-dom";

const Navbar1 = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <nav className="navbar">
      <div className="logo">
        <Link to="/">
          <img
            src={Logo}
            width="200"
            height="auto"
            className="d-inline-block align-top"
            alt="LOGO"
          />
        </Link>
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
      <ul className={`nav-links ${isOpen ? "open" : ""}`}>
        {/* <li>
          <Link to="/about">About</Link>
        </li>

        <li>
          <Link to="/blogs">Blogs</Link>
        </li>

        <li>
          <Link to="/faq">FAQ</Link>
        </li>

        <li>
          <Link to="/insights">Insights</Link>
        </li>

        <li>
          <Link to="/contact">Contact</Link>
        </li> */}
        <div className="auth-buttons-toggle">
          <button className="login">
            <Link to="/login" className="login-link-color">
              Login
            </Link>
          </button>
          <div className="dropdown" onClick={toggleDropdown}>
            <button className="register">Register</button>
            {isDropdownOpen && (
              <div className="dropdown-menu">
                <Link to="/register" className="dropdown-item">
                  Individual Partner
                </Link>
                <Link to="/indRegister" className="dropdown-item">
                  Entity Partner
                </Link>
              </div>
            )}
          </div>
        </div>
      </ul>
      <div className="auth-buttons">
        <button className="login">
          <Link to="/login" className="login-link-color">
            Login
          </Link>
        </button>
        <div className="dropdown" onClick={toggleDropdown}>
          <button className="register" style={{ marginLeft: "1.5rem" }}>
            Register
          </button>
          {isDropdownOpen && (
            <div className="dropdown-menu">
              <Link to="/register" className="dropdown-item">
                Individual Partner
              </Link>
              <Link to="/indRegister" className="dropdown-item">
                Entity Partner
              </Link>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar1;
