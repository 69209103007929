import axios from "axios";
import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../utils/baseUrl";

const CompleteKYC = () => {
  const token = sessionStorage.getItem("token");
  const { clientId, productId } = useParams();
  const [stateData, setStateData] = useState([0]);
  const [citiesData, setCitiesData] = useState([]);
  const [stateId, setStateId] = useState("");
  const [selectedStateName, setSelectedStateName] = useState("");
  const [states, setStates] = useState([]);
  const [clientList, setClientList] = useState({});
  const [formData, setFormData] = useState({
    PanNumber: "",
    dob: "",
    email: "",
    name: "",
    mobileNumber: "",
    address_line_1: "",
    address_line_2: "",
    state: "",
    city: "",
    zip: "",
    accountHolderName: "",
    bank_account_no: "",
    confirmAccountNo: "",
    ifsc_code: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    if (clientList) {
      setFormData({
        PanNumber: clientList.PanNumber || "",
        dob: clientList.dob || "",
        email: clientList.Email || "",
        name: clientList.name || "",
        mobileNumber: clientList.mobileNo || "",
        address_line_1: clientList.address_line_1 || "",
        address_line_2: clientList.address_line_2 || "",
        state: clientList.state || "",
        city: clientList.city || "",
        zip: clientList.zip || "",
        accountHolderName: clientList.accountHolderName || "",
        bank_account_no: clientList.bank_account_no || "",
        confirmAccountNo: clientList.confirmAccountNo || "",
        ifsc_code: clientList.ifsc_code || "",
      });
    }
  }, [clientList]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (e.target.name == "stateName") {
      setStateId(e.target.value);
    }
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData.PanNumber) tempErrors.PanNumber = "PAN No. is required";
    if (!formData.dob) tempErrors.dob = "DOB is required";
    if (!formData.email) tempErrors.email = "Email is required";
    if (!formData.name) tempErrors.name = "Name is required";
    if (!formData.mobileNumber)
      tempErrors.mobileNumber = "Mobile No. is required";
    if (!formData.address_line_1)
      tempErrors.address_line_1 = "Address Line 1 is required";
    if (!formData.stateName) tempErrors.state = "State is required";
    if (!formData.cityName) tempErrors.city = "City is required";
    if (!formData.zip) tempErrors.zip = "PIN Code is required";
    if (!formData.accountHolderName)
      tempErrors.accountHolderName = "Account Holder's Name is required";
    if (!formData.bank_account_no)
      tempErrors.bank_account_no = "Account No. is required";
    if (formData.bank_account_no !== formData.confirmAccountNo)
      tempErrors.confirmAccountNo = "Account numbers do not match";
    if (!formData.ifsc_code) tempErrors.ifsc_code = "IFSC Code is required";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      console.log("puullup submit", formData);
      kycApi();
    }
  };

  const fetchClients = async () => {
    try {
      const url = `${baseUrl}/Client_List`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { headers });
      const clientData = response.data.data;
      const filteredClient = clientData.find(
        (client) => client._id === clientId
      );
      console.log(filteredClient, "filter client data ??????????????");
      // setStatus()
      setClientList(filteredClient || {});
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const kycApi = async () => {
    try {
      const url = `${baseUrl}/client_kyc/${clientId}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const payload = {
        PanNumber: formData.PanNumber,
        dob: formData.dob,
        address_line_1: formData.address_line_1,
        address_line_2: formData.address_line_2,
        state: selectedStateName,
        mobileNumber: formData.mobileNumber,
        city: formData.cityName,
        zip: formData.zip,
        bank_account_no: formData.bank_account_no,
        bank_account_name: formData.accountHolderName,
        ifsc_code: formData.ifsc_code,
      };

      console.log("uuuuuuuuuuuuuuuuuuuuu", payload);
      const response = await axios.post(url, payload, { headers });
      if (clientList.status <= 1) {
        navigate(`/product/${clientId}/${productId}/address-details`);
      } else navigate("/spot-gold");
      if (response.data.message == "User not created") {
        toast.error(response.data.err.pincode[0].message);
      } else {
        toast.success("KYC successful");
      }
    } catch (error) {
      toast.error("KYC failed");
      console.error("Error connecting to API", error);
    }
  };

  useEffect(() => {
    const fetchStates = async (stateId) => {
      try {
        const url = `${baseUrl}/augmontGold/states`;
        const response = await axios.get(url);
        const statesData = response.data.data;
        setStateData(statesData);
        console.log(stateId, "///////////////");
        const filteredState = statesData.filter((state) => state.id == stateId);

        if (filteredState) {
          setSelectedStateName(filteredState[0].name);
        }
      } catch (error) {
        console.error("Error connecting to API", error);
      }
    };

    fetchStates(stateId);
  }, [stateId]);

  const fetchCity = async (id) => {
    try {
      const url = `${baseUrl}/augmontGold/city/${id}`;
      const response = await axios.get(url);
      const cityData = response.data.data;

      setCitiesData(cityData);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  useEffect(() => {
    if (stateId) {
      fetchCity(stateId);
    }
  }, [stateId]);

  return (
    <div className="registration-form" style={{ padding: "0rem" }}>
      <div className="col-lg-8 profile-bottom" style={{ width: "98%" }}>
        <Form
          onSubmit={handleSubmit}
          className="kyc-form"
          style={{
            padding: "1rem",
            border: "1px solid",
            borderRadius: "8px",
          }}
        >
          <h4 className="mb-4 center-text text-color">Complete KYC</h4>
          <h4
            className="mb-4 text-color"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#dcdaff",
            }}
          >
            PAN Details
          </h4>
          <Row className="row-bottom">
            <Col md={4}>
              <Form.Group controlId="formPanNumber">
                <Form.Label>PAN No.</Form.Label>
                <Form.Control
                  type="text"
                  name="PanNumber"
                  value={formData.PanNumber}
                  onChange={handleChange}
                  isInvalid={!!errors.PanNumber}
                  maxLength={10}
                  pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.PanNumber}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formDob">
                <Form.Label>DOB</Form.Label>
                <Form.Control
                  type="date"
                  name="dob"
                  value={formData.dob}
                  onChange={handleChange}
                  isInvalid={!!errors.dob}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.dob}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formMobileNumber">
                <Form.Label>Mobile No.</Form.Label>
                <Form.Control
                  type="text"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={handleChange}
                  maxLength={10}
                  pattern="\d{10}"
                  isInvalid={!!errors.mobileNumber}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.mobileNumber}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <h4
            className="mt-4 mb-4 text-color"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#dcdaff",
            }}
          >
            Address
          </h4>
          <Row>
            <Col md={4}>
              <Form.Group controlId="formAddress1">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  name="address_line_1"
                  value={formData.address_line_1}
                  onChange={handleChange}
                  isInvalid={!!errors.address_line_1}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.address_line_1}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formAddress2">
                <Form.Label>Landmark</Form.Label>
                <Form.Control
                  type="text"
                  name="address_line_2"
                  value={formData.address_line_2}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>State</Form.Label>
                <Form.Control
                  placeHolder={formData.state}
                  as="select"
                  name="stateName"
                  onChange={handleChange}
                  value={formData.stateName}
                >
                  <option value="">Select State</option>
                  {stateData.map((val, i) => (
                    <option key={i} value={val.id}>
                      {val.name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.state}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>City</Form.Label>
                <Form.Control
                  as="select"
                  name="cityName"
                  onChange={handleChange}
                  value={formData.cityName}
                  className="form-control"
                >
                  <option value="">Select City</option>
                  {citiesData.map((val) => (
                    <option key={val.id} value={val.name}>
                      {val.name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.city}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formZip">
                <Form.Label>PIN Code</Form.Label>
                <Form.Control
                  type="text"
                  name="zip"
                  value={formData.zip}
                  onChange={handleChange}
                  isInvalid={!!errors.zip}
                  maxLength={6}
                  pattern="\d{6}"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.zip}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <h4
            className="mt-4 mb-4 text-color"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#dcdaff",
            }}
          >
            Bank Details
          </h4>
          <Row>
            <Col md={4}>
              <Form.Group controlId="formAccountHolderName">
                <Form.Label>Account Holder Name</Form.Label>
                <Form.Control
                  type="text"
                  name="accountHolderName"
                  value={formData.accountHolderName}
                  onChange={handleChange}
                  isInvalid={!!errors.accountHolderName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.accountHolderName}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formBankAccountNo">
                <Form.Label>Bank Account No.</Form.Label>
                <Form.Control
                  type="text"
                  name="bank_account_no"
                  value={formData.bank_account_no}
                  onChange={handleChange}
                  isInvalid={!!errors.bank_account_no}
                  pattern="\d{9,18}"
                  maxLength={18}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.bank_account_no}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formConfirmAccountNo">
                <Form.Label>Confirm Account No.</Form.Label>
                <Form.Control
                  type="text"
                  name="confirmAccountNo"
                  value={formData.confirmAccountNo}
                  onChange={handleChange}
                  isInvalid={!!errors.confirmAccountNo}
                  pattern="\d{9,18}"
                  maxLength={18}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.confirmAccountNo}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formIfscCode">
                <Form.Label>IFSC Code</Form.Label>
                <Form.Control
                  type="text"
                  name="ifsc_code"
                  value={formData.ifsc_code}
                  onChange={handleChange}
                  isInvalid={!!errors.ifsc_code}
                  maxLength={11}
                  pattern="^[A-Za-z]{4}[a-zA-Z0-9]{7}$"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.ifsc_code}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <div
            style={{
              marginTop: "1rem",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button type="submit" variant="primary">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CompleteKYC;

// import axios from "axios";
// import React, { useState, useEffect } from "react";
// import { Form, Button, Row, Col } from "react-bootstrap";
// import { CountrySelect, CitySelect } from "react-country-state-city";
// import "react-country-state-city/dist/react-country-state-city.css";
// import { useNavigate, useParams } from "react-router-dom";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { baseUrl } from "../utils/baseUrl";

// const CompleteKYC = () => {
//   const token = sessionStorage.getItem("token");
//   const { clientId } = useParams();
//   const [country, setCountryid] = useState(101);
//   const [state, setStateid] = useState(0);
//   const [city, setCityid] = useState(0);
//   const [states, setStates] = useState([]);
//   const [clientList, setClientList] = useState([]);
//   const [formData, setFormData] = useState({
//     PanNumber: "",
//     dob: "",
//     email: clientList.Email,
//     name: clientList.name,
//     mobileNumber: clientList.mobileNo,
//     address_line_1: "",
//     address_line_2: "",
//     state: "Uttar Pradesh",
//     city: "Noida",
//     zip: "",
//     accountHolderName: "",
//     bank_account_no: "",
//     confirmAccountNo: "",
//     ifsc_code: "",
//   });
//   const [errors, setErrors] = useState({});
//   const navigate = useNavigate();

//   useEffect(() => {
//     fetchStates();
//   }, []);

//   const handleCountryChange = (country) => {
//     setCountryid(country.id);
//     setStateid(0);
//     setCityid(0);
//   };

//   const handleStateChange = (e) => {
//     const selectedState = states.find((s) => s.id === parseInt(e.target.value));
//     setStateid(selectedState.id);
//     setFormData({ ...formData, state: selectedState.name });
//     setCityid(0);
//   };

//   const handleCityChange = (city) => {
//     setCityid(city.id);
//     setFormData({ ...formData, city: city.name });
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const validate = () => {
//     let tempErrors = {};
//     if (!formData.PanNumber) tempErrors.PanNumber = "PAN No. is required";
//     if (!formData.dob) tempErrors.dob = "DOB is required";
//     if (!formData.email) tempErrors.email = "Email is required";
//     if (!formData.name) tempErrors.name = "Name is required";
//     if (!formData.mobileNumber)
//       tempErrors.mobileNumber = "Mobile No. is required";
//     if (!formData.address_line_1)
//       tempErrors.address_line_1 = "Address Line 1 is required";
//     if (!formData.state) tempErrors.state = "State is required";
//     if (!formData.city) tempErrors.city = "City is required";
//     if (!formData.zip) tempErrors.zip = "PIN Code is required";
//     if (!formData.accountHolderName)
//       tempErrors.accountHolderName = "Account Holder's Name is required";
//     if (!formData.bank_account_no)
//       tempErrors.bank_account_no = "Account No. is required";
//     if (formData.bank_account_no !== formData.confirmAccountNo)
//       tempErrors.confirmAccountNo = "Account numbers do not match";
//     if (!formData.ifsc_code) tempErrors.ifsc_code = "IFSC Code is required";

//     setErrors(tempErrors);
//     return Object.keys(tempErrors).length === 0;
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (validate()) {
//       kycApi();
//     }
//   };

//   useEffect(() => {
//     fetchClients();
//   }, []);

//   const fetchClients = async () => {
//     try {
//       const url = `${baseUrl}/Client_List`;
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${token}`,
//       };
//       const response = await axios.get(url, { headers });

//       // Check if the status is 1 and show the popup
//       const clientData = response.data.data;
//       console.log("Client list=", clientData);

//       const filteredClient = clientData.find(
//         (client) => client._id === clientId
//       );
//       console.log(filteredClient, "filter client data ??????????????????");

//       setClientList(filteredClient);
//     } catch (error) {
//       console.error("Error connecting to API", error);
//     }
//   };

//   const fetchStates = async () => {
//     try {
//       const url = `${baseUrl}/augmontGold/states`;
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${token}`,
//       };
//       const response = await axios.get(url, { headers });
//       console.log(response.data, "states[[[[[[[[[[[[[[[[[[[");
//       setStates(response.data.states || []);
//     } catch (error) {
//       toast.error("Failed to fetch states");
//       console.error("Error fetching states", error);
//     }
//   };

//   const kycApi = async () => {
//     try {
//       const url = `${baseUrl}/client_kyc/${clientId}`;
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${token}`,
//       };

//       const payload = {
//         PanNumber: formData.PanNumber,
//         dob: formData.dob,
//         address_line_1: formData.address_line_1,
//         address_line_2: formData.address_line_2,
//         state: formData.state,
//         mobileNumber: formData.mobileNumber,
//         city: formData.city,
//         zip: formData.zip,
//         bank_account_no: formData.bank_account_no,
//         bank_account_name: formData.accountHolderName,
//         ifsc_code: formData.ifsc_code,
//       };

//       await axios.post(url, payload, { headers });
//       toast.success("KYC successful");
//       navigate("/spot-gold");
//     } catch (error) {
//       toast.error("KYC failed");
//       console.error("Error connecting to API", error);
//     }
//   };

//   return (
//     <div className="registration-form" style={{ padding: "0rem" }}>
//       <div className="col-lg-8 profile-bottom" style={{ width: "98%" }}>
//         <Form
//           onSubmit={handleSubmit}
//           className="kyc-form"
//           style={{
//             padding: "1rem",
//             border: "1px solid",
//             borderRadius: "8px",
//           }}
//         >
//           <h4 className="mb-4 center-text text-color">Complete KYC</h4>
//           <h4
//             className="mb-4 text-color"
//             style={{
//               width: "100%",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               backgroundColor: "#dcdaff",
//             }}
//           >
//             PAN Details
//           </h4>
//           <Row className="row-bottom">
//             <Col md={4}>
//               <Form.Group controlId="formPanNumber">
//                 <Form.Label>PAN No.</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="PanNumber"
//                   value={formData.PanNumber}
//                   onChange={handleChange}
//                   isInvalid={!!errors.PanNumber}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   {errors.PanNumber}
//                 </Form.Control.Feedback>
//               </Form.Group>
//             </Col>
//             <Col md={4}>
//               <Form.Group controlId="formDob">
//                 <Form.Label>DOB</Form.Label>
//                 <Form.Control
//                   type="date"
//                   name="dob"
//                   value={formData.dob}
//                   onChange={handleChange}
//                   isInvalid={!!errors.dob}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   {errors.dob}
//                 </Form.Control.Feedback>
//               </Form.Group>
//             </Col>
//             <Col md={4}>
//               <Form.Group controlId="formEmail">
//                 <Form.Label>Email</Form.Label>
//                 <Form.Control
//                   type="email"
//                   name="email"
//                   value={formData.email}
//                   onChange={handleChange}
//                   isInvalid={!!errors.email}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   {errors.email}
//                 </Form.Control.Feedback>
//               </Form.Group>
//             </Col>
//           </Row>
//           <h4
//             className="mb-4 text-color"
//             style={{
//               width: "100%",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               backgroundColor: "#dcdaff",
//             }}
//           >
//             Personal Details
//           </h4>
//           <Row className="row-bottom">
//             <Col md={4}>
//               <Form.Group controlId="formName">
//                 <Form.Label>Name</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="name"
//                   value={formData.name}
//                   onChange={handleChange}
//                   isInvalid={!!errors.name}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   {errors.name}
//                 </Form.Control.Feedback>
//               </Form.Group>
//             </Col>
//             <Col md={4}>
//               <Form.Group controlId="formMobileNumber">
//                 <Form.Label>Mobile No.</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="mobileNumber"
//                   value={formData.mobileNumber}
//                   onChange={handleChange}
//                   isInvalid={!!errors.mobileNumber}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   {errors.mobileNumber}
//                 </Form.Control.Feedback>
//               </Form.Group>
//             </Col>
//             <Col md={4}>
//               <Form.Group controlId="formAddressLine1">
//                 <Form.Label>Address Line 1</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="address_line_1"
//                   value={formData.address_line_1}
//                   onChange={handleChange}
//                   isInvalid={!!errors.address_line_1}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   {errors.address_line_1}
//                 </Form.Control.Feedback>
//               </Form.Group>
//             </Col>
//           </Row>
//           <Row className="row-bottom">
//             <Col md={4}>
//               <Form.Group controlId="formAddressLine2">
//                 <Form.Label>Address Line 2</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="address_line_2"
//                   value={formData.address_line_2}
//                   onChange={handleChange}
//                   isInvalid={!!errors.address_line_2}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   {errors.address_line_2}
//                 </Form.Control.Feedback>
//               </Form.Group>
//             </Col>
//             <Col md={4}>
//               <Form.Group controlId="formState">
//                 <Form.Label>State</Form.Label>
//                 <Form.Control
//                   as="select"
//                   name="state"
//                   value={formData.state}
//                   onChange={handleStateChange}
//                   isInvalid={!!errors.state}
//                 >
//                   <option value="">Select State</option>
//                   {states.map((state) => (
//                     <option key={state.id} value={state.id}>
//                       {state.name}
//                     </option>
//                   ))}
//                 </Form.Control>
//                 <Form.Control.Feedback type="invalid">
//                   {errors.state}
//                 </Form.Control.Feedback>
//               </Form.Group>
//             </Col>
//             <Col md={4}>
//               <Form.Group controlId="formCity">
//                 <Form.Label>City</Form.Label>
//                 <CitySelect
//                   country={country}
//                   state={state}
//                   value={city}
//                   onChange={handleCityChange}
//                   className={`form-control ${errors.city ? "is-invalid" : ""}`}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   {errors.city}
//                 </Form.Control.Feedback>
//               </Form.Group>
//             </Col>
//           </Row>
//           <Row className="row-bottom">
//             <Col md={4}>
//               <Form.Group controlId="formZip">
//                 <Form.Label>PIN Code</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="zip"
//                   value={formData.zip}
//                   onChange={handleChange}
//                   isInvalid={!!errors.zip}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   {errors.zip}
//                 </Form.Control.Feedback>
//               </Form.Group>
//             </Col>
//           </Row>
//           <h4
//             className="mb-4 text-color"
//             style={{
//               width: "100%",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               backgroundColor: "#dcdaff",
//             }}
//           >
//             Bank Details
//           </h4>
//           <Row className="row-bottom">
//             <Col md={4}>
//               <Form.Group controlId="formAccountHolderName">
//                 <Form.Label>Account Holder's Name</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="accountHolderName"
//                   value={formData.accountHolderName}
//                   onChange={handleChange}
//                   isInvalid={!!errors.accountHolderName}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   {errors.accountHolderName}
//                 </Form.Control.Feedback>
//               </Form.Group>
//             </Col>
//             <Col md={4}>
//               <Form.Group controlId="formBankAccountNo">
//                 <Form.Label>Account No.</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="bank_account_no"
//                   value={formData.bank_account_no}
//                   onChange={handleChange}
//                   isInvalid={!!errors.bank_account_no}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   {errors.bank_account_no}
//                 </Form.Control.Feedback>
//               </Form.Group>
//             </Col>
//             <Col md={4}>
//               <Form.Group controlId="formConfirmAccountNo">
//                 <Form.Label>Confirm Account No.</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="confirmAccountNo"
//                   value={formData.confirmAccountNo}
//                   onChange={handleChange}
//                   isInvalid={!!errors.confirmAccountNo}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   {errors.confirmAccountNo}
//                 </Form.Control.Feedback>
//               </Form.Group>
//             </Col>
//           </Row>
//           <Row className="row-bottom">
//             <Col md={4}>
//               <Form.Group controlId="formIfscCode">
//                 <Form.Label>IFSC Code</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="ifsc_code"
//                   value={formData.ifsc_code}
//                   onChange={handleChange}
//                   isInvalid={!!errors.ifsc_code}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   {errors.ifsc_code}
//                 </Form.Control.Feedback>
//               </Form.Group>
//             </Col>
//           </Row>
//           <div className="d-flex justify-content-center">
//             <Button variant="primary" type="submit">
//               Submit
//             </Button>
//           </div>
//         </Form>
//       </div>
//     </div>
//   );
// };

// export default CompleteKYC;

// import React, { useState } from "react";
// import axios from "axios";
// import { useNavigate, useParams } from "react-router-dom";
// import { toast } from "react-toastify";
// import { Form, Button, Row, Col } from "react-bootstrap";
// import { baseUrl } from "../utils/baseUrl";
// // Ensure these components are defined or imported if they exist in your project
// // import StateSelect from "./StateSelect";
// // import CitySelect from "./CitySelect";

// const CompleteKYC = () => {
//   const token = sessionStorage.getItem("token");
//   const navigate = useNavigate();
//   const { clientId } = useParams();
//   const [formData, setFormData] = useState({
//     PanNumber: "",
//     dob: "",
//     address_line_1: "",
//     address_line_2: "",
//     state: "",
//     mobileNumber: "",
//     city: "",
//     zip: "",
//     bank_account_no: "",
//     accountHolderName: "",
//     ifsc_code: "",
//   });

//   const [errors, setErrors] = useState({});

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const validateForm = () => {
//     const newErrors = {};
//     Object.keys(formData).forEach((key) => {
//       if (!formData[key]) {
//         newErrors[key] = "This field is required";
//       }
//     });
//     setErrors(newErrors);
//     return Object.keys(newErrors).length === 0;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!validateForm()) return;

//     try {
//       const url = `${baseUrl}/client_kyc/${clientId}`;
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${token}`,
//       };

//       const payload = {
//         PanNumber: formData.PanNumber,
//         dob: formData.dob,
//         address_line_1: formData.address_line_1,
//         address_line_2: formData.address_line_2,
//         state: formData.state,
//         mobileNumber: formData.mobileNumber,
//         city: formData.city,
//         zip: formData.zip,
//         bank_account_no: formData.bank_account_no,
//         bank_account_name: formData.accountHolderName,
//         ifsc_code: formData.ifsc_code,
//       };

//       await axios.post(url, payload, { headers });
//       toast.success("KYC successful");
//       navigate("/client_table");
//     } catch (error) {
//       toast.error("KYC failed");
//       console.error("Error connecting to API", error);
//     }
//   };

//   return (
//     <div>
//       <Form onSubmit={handleSubmit}>
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//           }}
//         >
//           <h4 className="mb-4 center-text text-color">Complete KYC</h4>
//           <Button
//             variant="contained"
//             className="btn btn-primary"
//             onClick={() => navigate("/client_table")}
//             style={{
//               textTransform: "capitalize",
//               marginBottom: "1rem",
//               width: "8rem",
//               height: "2.7rem",
//               backgroundColor: "#4a2a7d",
//             }}
//           >
//             Cancel
//           </Button>
//         </div>
//         <h4
//           className="mb-4 text-color"
//           style={{
//             width: "100%",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             backgroundColor: "#dcdaff",
//           }}
//         >
//           PAN Details
//         </h4>
//         <Row className="row-bottom">
//           <Col md={4}>
//             <Form.Group controlId="formPanNumber">
//               <Form.Label>PAN No.</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="PanNumber"
//                 value={formData.PanNumber}
//                 onChange={handleChange}
//                 isInvalid={!!errors.PanNumber}
//               />
//               <Form.Control.Feedback type="invalid">
//                 {errors.PanNumber}
//               </Form.Control.Feedback>
//             </Form.Group>
//           </Col>
//           <Col md={4}>
//             <Form.Group controlId="formDob">
//               <Form.Label>DOB</Form.Label>
//               <Form.Control
//                 type="date"
//                 name="dob"
//                 value={formData.dob}
//                 onChange={handleChange}
//                 isInvalid={!!errors.dob}
//               />
//               <Form.Control.Feedback type="invalid">
//                 {errors.dob}
//               </Form.Control.Feedback>
//             </Form.Group>
//           </Col>
//           <Col md={4}>
//             <Form.Group controlId="formEmail">
//               <Form.Label>Email</Form.Label>
//               <Form.Control
//                 type="email"
//                 name="email"
//                 value={formData.email}
//                 onChange={handleChange}
//                 isInvalid={!!errors.email}
//                 readOnly
//               />
//               <Form.Control.Feedback type="invalid">
//                 {errors.email}
//               </Form.Control.Feedback>
//             </Form.Group>
//           </Col>
//         </Row>
//         <h4
//           className="mb-4 text-color"
//           style={{
//             width: "100%",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             backgroundColor: "#dcdaff",
//           }}
//         >
//           Personal Details
//         </h4>
//         <Row className="row-bottom">
//           <Col md={4}>
//             <Form.Group controlId="formName">
//               <Form.Label>Name</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="name"
//                 value={formData.name}
//                 onChange={handleChange}
//                 isInvalid={!!errors.name}
//               />
//               <Form.Control.Feedback type="invalid">
//                 {errors.name}
//               </Form.Control.Feedback>
//             </Form.Group>
//           </Col>
//           <Col md={4}>
//             <Form.Group controlId="formMobileNumber">
//               <Form.Label>Mobile No.</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="mobileNumber"
//                 value={formData.mobileNumber}
//                 onChange={handleChange}
//                 isInvalid={!!errors.mobileNumber}
//               />
//               <Form.Control.Feedback type="invalid">
//                 {errors.mobileNumber}
//               </Form.Control.Feedback>
//             </Form.Group>
//           </Col>
//           <Col md={4}>
//             <Form.Group controlId="formAddressLine1">
//               <Form.Label>Address</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="address_line_1"
//                 value={formData.address_line_1}
//                 onChange={handleChange}
//                 isInvalid={!!errors.address_line_1}
//               />
//               <Form.Control.Feedback type="invalid">
//                 {errors.address_line_1}
//               </Form.Control.Feedback>
//             </Form.Group>
//           </Col>
//         </Row>
//         <Row className="row-bottom">
//           <Col md={4}>
//             <Form.Group controlId="formAddressLine2">
//               <Form.Label>Landmark</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="address_line_2"
//                 value={formData.address_line_2}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//           <Col md={4}>
//             <Form.Group controlId="formState">
//               <Form.Label>State</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="state"
//                 value={formData.state}
//                 onChange={handleChange}
//                 isInvalid={!!errors.state}
//               />
//               <Form.Control.Feedback type="invalid">
//                 {errors.state}
//               </Form.Control.Feedback>
//             </Form.Group>
//           </Col>
//           <Col md={4}>
//             <Form.Group controlId="formCity">
//               <Form.Label>City</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="city"
//                 value={formData.city}
//                 onChange={handleChange}
//                 isInvalid={!!errors.city}
//               />
//               <Form.Control.Feedback type="invalid">
//                 {errors.city}
//               </Form.Control.Feedback>
//             </Form.Group>
//           </Col>
//           <Col md={4}>
//             <Form.Group controlId="formZip">
//               <Form.Label>PIN Code</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="zip"
//                 value={formData.zip}
//                 onChange={handleChange}
//                 isInvalid={!!errors.zip}
//               />
//               <Form.Control.Feedback type="invalid">
//                 {errors.zip}
//               </Form.Control.Feedback>
//             </Form.Group>
//           </Col>
//         </Row>
//         <h4
//           className="mb-4 text-color"
//           style={{
//             width: "100%",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             backgroundColor: "#dcdaff",
//           }}
//         >
//           Bank Details
//         </h4>
//         <Row className="row-bottom">
//           <Col md={4}>
//             <Form.Group controlId="formBankAccountNo">
//               <Form.Label>Bank Account No.</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="bank_account_no"
//                 value={formData.bank_account_no}
//                 onChange={handleChange}
//                 isInvalid={!!errors.bank_account_no}
//               />
//               <Form.Control.Feedback type="invalid">
//                 {errors.bank_account_no}
//               </Form.Control.Feedback>
//             </Form.Group>
//           </Col>
//           <Col md={4}>
//             <Form.Group controlId="formAccountHolderName">
//               <Form.Label>Account Holder Name</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="accountHolderName"
//                 value={formData.accountHolderName}
//                 onChange={handleChange}
//                 isInvalid={!!errors.accountHolderName}
//               />
//               <Form.Control.Feedback type="invalid">
//                 {errors.accountHolderName}
//               </Form.Control.Feedback>
//             </Form.Group>
//           </Col>
//           <Col md={4}>
//             <Form.Group controlId="formIfscCode">
//               <Form.Label>IFSC Code</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="ifsc_code"
//                 value={formData.ifsc_code}
//                 onChange={handleChange}
//                 isInvalid={!!errors.ifsc_code}
//               />
//               <Form.Control.Feedback type="invalid">
//                 {errors.ifsc_code}
//               </Form.Control.Feedback>
//             </Form.Group>
//           </Col>
//         </Row>
//         <Button
//           variant="contained"
//           type="submit"
//           className="btn btn-primary"
//           style={{
//             textTransform: "capitalize",
//             marginBottom: "1rem",
//             width: "8rem",
//             height: "2.7rem",
//             backgroundColor: "#4a2a7d",
//           }}
//         >
//           Submit
//         </Button>
//       </Form>
//     </div>
//   );
// };

// export default CompleteKYC;

// import React, { useState } from "react";
// import axios from "axios";
// import { useNavigate, useParams } from "react-router-dom";
// import { toast } from "react-toastify";
// import { baseUrl } from "../utils/baseUrl";

// const CompleteKYC = () => {
//   const token = sessionStorage.getItem("token");
//   const navigate = useNavigate();
//   const { clientId } = useParams();
//   const [formData, setFormData] = useState({
//     PanNumber: "",
//     dob: "",
//     address_line_1: "",
//     address_line_2: "",
//     state: "",
//     mobileNumber: "",
//     city: "",
//     zip: "",
//     bank_account_no: "",
//     accountHolderName: "",
//     ifsc_code: "",
//   });

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const url = `${baseUrl}/client_kyc/${clientId}`;
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${token}`,
//       };

//       const payload = {
//         PanNumber: formData.PanNumber,
//         dob: formData.dob,
//         address_line_1: formData.address_line_1,
//         address_line_2: formData.address_line_2,
//         state: formData.state,
//         mobileNumber: formData.mobileNumber,
//         city: formData.city,
//         zip: formData.zip,
//         bank_account_no: formData.bank_account_no,
//         bank_account_name: formData.accountHolderName,
//         ifsc_code: formData.ifsc_code,
//       };

//       await axios.post(url, payload, { headers });
//       toast.success("KYC successful");
//       navigate("/client_table");
//     } catch (error) {
//       toast.error("KYC failed");
//       console.error("Error connecting to API", error);
//     }
//   };

//   return (
//     <div>
//       <Form onSubmit={handleSubmit}>
//                 <div
//                   style={{
//                     display: "flex",
//                     justifyContent: "space-between",
//                     alignItems: "center",
//                   }}
//                 >
//                   <h4 className="mb-4 center-text text-color">Complete KYC</h4>
//                   <Button
//                     variant="contained"
//                     className="btn btn-primary"
//                     onClick={() => setIsEditing(false)}
//                     style={{
//                       textTransform: "capitalize",
//                       height: "2.5rem",
//                       marginBottom: "1rem",
//                       width: "8rem",
//                       height: "2.7rem",
//                       backgroundColor: "#4a2a7d",
//                     }}
//                   >
//                     Cancel
//                   </Button>
//                 </div>
//                 <h4
//                   className="mb-4 text-color"
//                   style={{
//                     width: "100%",
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     backgroundColor: "#dcdaff",
//                   }}
//                 >
//                   PAN Details
//                 </h4>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="formPanNumber">
//                       <Form.Label>PAN No.</Form.Label>
//                       <Form.Control
//                         type="text"
//                         name="PanNumber"
//                         value={formData.PanNumber}
//                         onChange={handleChange}
//                         isInvalid={!!errors.PanNumber}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         {errors.PanNumber}
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formDob">
//                       <Form.Label>DOB</Form.Label>
//                       <Form.Control
//                         type="date"
//                         name="dob"
//                         value={formData.dob}
//                         onChange={handleChange}
//                         isInvalid={!!errors.dob}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         {errors.dob}
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formEmail">
//                       <Form.Label>Email</Form.Label>
//                       <Form.Control
//                         type="email"
//                         name="email"
//                         value={formData.email}
//                         onChange={handleChange}
//                         isInvalid={!!errors.email}
//                         readOnly
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         {errors.email}
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <h4
//                   className="mb-4 text-color"
//                   style={{
//                     width: "100%",
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     backgroundColor: "#dcdaff",
//                   }}
//                 >
//                   Personal Details
//                 </h4>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="formName">
//                       <Form.Label>Name</Form.Label>
//                       <Form.Control
//                         type="text"
//                         name="name"
//                         value={formData.name}
//                         onChange={handleChange}
//                         isInvalid={!!errors.name}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         {errors.name}
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formMobileNumber">
//                       <Form.Label>Mobile No.</Form.Label>
//                       <Form.Control
//                         type="text"
//                         name="mobileNumber"
//                         value={formData.mobileNumber}
//                         onChange={handleChange}
//                         isInvalid={!!errors.mobileNumber}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         {errors.mobileNumber}
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formAddressLine1">
//                       <Form.Label>Address Line 1</Form.Label>
//                       <Form.Control
//                         type="text"
//                         name="address_line_1"
//                         value={formData.address_line_1}
//                         onChange={handleChange}
//                         isInvalid={!!errors.address_line_1}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         {errors.address_line_1}
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="formAddressLine2">
//                       <Form.Label>Address Line 2</Form.Label>
//                       <Form.Control
//                         type="text"
//                         name="address_line_2"
//                         value={formData.address_line_2}
//                         onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formState">
//                       <Form.Label>State</Form.Label>
//                       <StateSelect
//                         placeHolder={formData.state}
//                         type="text"
//                         name="state"
//                         countryid={country}
//                         onChange={(state) => handleStateChange(state)}
//                         value={formData.state}
//                         className="form-control"
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         {errors.state}
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formCity">
//                       <Form.Label>City</Form.Label>
//                       <CitySelect
//                         placeHolder={formData.city}
//                         type="text"
//                         name="city"
//                         countryid={country}
//                         stateid={state}
//                         onChange={(city) => handleCityChange(city)}
//                         value={formData.city}
//                         className="form-control"
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         {errors.city}
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formZip">
//                       <Form.Label>PIN Code</Form.Label>
//                       <Form.Control
//                         type="text"
//                         name="zip"
//                         value={formData.zip}
//                         onChange={handleChange}
//                         isInvalid={!!errors.zip}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         {errors.zip}
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <h4
//                   className="mb-4 text-color"
//                   style={{
//                     width: "100%",
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     backgroundColor: "#dcdaff",
//                   }}
//                 >
//                   Bank Details
//                 </h4>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="formAccountHolderName">
//                       <Form.Label>Account Holder Name</Form.Label>
//                       <Form.Control
//                         type="text"
//                         name="accountHolderName"
//                         value={formData.accountHolderName}
//                         onChange={handleChange}
//                         isInvalid={!!errors.accountHolderName}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         {errors.accountHolderName}
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formBankAccountNo">
//                       <Form.Label>Bank Account No.</Form.Label>
//                       <Form.Control
//                         type="text"
//                         name="bank_account_no"
//                         value={formData.bank_account_no}
//                         onChange={handleChange}
//                         isInvalid={!!errors.bank_account_no}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         {errors.bank_account_no}
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formConfirmAccountNo">
//                       <Form.Label>Confirm Account No.</Form.Label>
//                       <Form.Control
//                         type="text"
//                         name="confirmAccountNo"
//                         value={formData.confirmAccountNo}
//                         onChange={handleChange}
//                         isInvalid={!!errors.confirmAccountNo}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         {errors.confirmAccountNo}
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formIfscCode">
//                       <Form.Label>IFSC Code</Form.Label>
//                       <Form.Control
//                         type="text"
//                         name="ifsc_code"
//                         value={formData.ifsc_code}
//                         onChange={handleChange}
//                         isInvalid={!!errors.ifsc_code}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         {errors.ifsc_code}
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <div className="text-end">
//                   <Button
//                     type="submit"
//                     variant="contained"
//                     className="btn btn-primary"
//                     style={{
//                       textTransform: "capitalize",
//                       height: "2.5rem",
//                       marginBottom: "1rem",
//                       width: "8rem",
//                       height: "2.7rem",
//                       backgroundColor: "#4a2a7d",
//                     }}
//                   >
//                     Update
//                   </Button>
//                 </div>
//               </Form>
//     </div>
//   );
// };

// export default CompleteKYC;

// import axios from "axios";
// import React, { useState } from "react";
// import { Form, Button, Row, Col } from "react-bootstrap";
// import {
//   CountrySelect,
//   StateSelect,
//   CitySelect,
// } from "react-country-state-city";
// import "react-country-state-city/dist/react-country-state-city.css";
// import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { baseUrl } from "../utils/baseUrl";

// const CompleteKYC = () => {
//   const [country, setCountryid] = useState(101);
//   const [state, setStateid] = useState(0);
//   const [city, setCityid] = useState(0);
//   const [formData, setFormData] = useState({
//     PanNumber,
//     dob,
//     email,
//     name,
//     mobileNumber,
//     address_line_1,
//     address_line_2,
//     state:state,
//     city:city,
//     zip:zip,
//     accountHolderName,
//     bank_account_no,
//     confirmAccountNo,
//     ifsc_code,
//   });
//   const [errors, setErrors] = useState({});
//   const navigate = useNavigate();

//   const handleCountryChange = (country) => {
//     setCountryid(country.id);
//     setStateid(0);
//     setCityid(0);
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (validate()) {
//       kycApi();
//     }
//   };

//   const token = sessionStorage.getItem("token");

//   const kycApi = async () => {
//     try {
//       const url = `${baseUrl}/client_kyc/${clientId}`;
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${token}`,
//       };

//       const payload = {
//         PanNumber: formData.PanNumber,
//         dob: formData.dob,
//         address_line_1: formData.address_line_1,
//         address_line_2: formData.address_line_2,
//         state: formData.state,
//         mobileNumber: formData.mobileNumber,
//         city: formData.city,
//         zip: formData.zip,
//         bank_account_no: formData.bank_account_no,
//         bank_account_name: formData.accountHolderName,
//         ifsc_code: formData.ifsc_code,
//       };

//       await axios.post(url, payload, { headers });
//       toast.success("KYC successful");
//       navigate("/client_table");
//     } catch (error) {
//       toast.error("KYC failed");
//       console.error("Error connecting to API", error);
//     }
//   };

//   return (
//     <div className="registration-form" style={{ padding: "0rem" }}>
//       <div className="col-lg-8 profile-bottom" style={{ width: "98%" }}>
//         <div
//           className="profile-show"
//           style={{
//             padding: "1rem",
//             border: "1px solid",
//             borderRadius: "8px",
//           }}
//         >
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "space-between",
//               alignItems: "center",
//             }}
//           >
//             <h4 className="mb-4 center-text text-color">Complete KYC</h4>
//           </div>
//         </div>

//         <Form
//           onSubmit={handleSubmit}
//           className="kyc-form"
//           style={{
//             padding: "1rem",
//             border: "1px solid",
//             borderRadius: "8px",
//           }}
//         >
//           <h4 className="mb-4 center-text text-color">Complete KYC</h4>
//           <h4
//             className="mb-4 text-color"
//             style={{
//               width: "100%",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               backgroundColor: "#dcdaff",
//             }}
//           >
//             PAN Details
//           </h4>
//           <Row className="row-bottom">
//             <Col md={4}>
//               <Form.Group controlId="formPanNumber">
//                 <Form.Label>PAN No.</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="PanNumber"
//                   value={formData.PanNumber}
//                   onChange={handleChange}
//                   isInvalid={!!errors.PanNumber}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   {errors.PanNumber}
//                 </Form.Control.Feedback>
//               </Form.Group>
//             </Col>
//             <Col md={4}>
//               <Form.Group controlId="formDob">
//                 <Form.Label>DOB</Form.Label>
//                 <Form.Control
//                   type="date"
//                   name="dob"
//                   value={formData.dob}
//                   onChange={handleChange}
//                   isInvalid={!!errors.dob}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   {errors.dob}
//                 </Form.Control.Feedback>
//               </Form.Group>
//             </Col>
//             <Col md={4}>
//               <Form.Group controlId="formEmail">
//                 <Form.Label>Email</Form.Label>
//                 <Form.Control
//                   type="email"
//                   name="email"
//                   value={formData.email}
//                   onChange={handleChange}
//                   isInvalid={!!errors.email}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   {errors.email}
//                 </Form.Control.Feedback>
//               </Form.Group>
//             </Col>
//           </Row>
//           <h4
//             className="mb-4 text-color"
//             style={{
//               width: "100%",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               backgroundColor: "#dcdaff",
//             }}
//           >
//             Personal Details
//           </h4>
//           <Row className="row-bottom">
//             <Col md={4}>
//               <Form.Group controlId="formName">
//                 <Form.Label>Name</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="name"
//                   value={formData.name}
//                   onChange={handleChange}
//                   isInvalid={!!errors.name}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   {errors.name}
//                 </Form.Control.Feedback>
//               </Form.Group>
//             </Col>
//             <Col md={4}>
//               <Form.Group controlId="formMobileNumber">
//                 <Form.Label>Mobile No.</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="mobileNumber"
//                   value={formData.mobileNumber}
//                   onChange={handleChange}
//                   isInvalid={!!errors.mobileNumber}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   {errors.mobileNumber}
//                 </Form.Control.Feedback>
//               </Form.Group>
//             </Col>
//             <Col md={4}>
//               <Form.Group controlId="formAddressLine1">
//                 <Form.Label>Address Line 1</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="address_line_1"
//                   value={formData.address_line_1}
//                   onChange={handleChange}
//                   isInvalid={!!errors.address_line_1}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   {errors.address_line_1}
//                 </Form.Control.Feedback>
//               </Form.Group>
//             </Col>
//             <Col md={4}>
//               <Form.Group controlId="formAddressLine2">
//                 <Form.Label>Address Line 2</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="address_line_2"
//                   value={formData.address_line_2}
//                   onChange={handleChange}
//                 />
//               </Form.Group>
//             </Col>
//             <Col md={4}>
//               <Form.Group controlId="formState">
//                 <Form.Label>State</Form.Label>
//                 <StateSelect
//                   value={state}
//                   countryId={country}
//                   onChange={handleStateChange}
//                   style={{ height: "36px" }}
//                 />
//               </Form.Group>
//             </Col>
//             <Col md={4}>
//               <Form.Group controlId="formCity">
//                 <Form.Label>City</Form.Label>
//                 <CitySelect
//                   value={city}
//                   stateId={state}
//                   countryId={country}
//                   onChange={handleCityChange}
//                   style={{ height: "36px" }}
//                 />
//               </Form.Group>
//             </Col>
//             <Col md={4}>
//               <Form.Group controlId="formZip">
//                 <Form.Label>PIN Code</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="zip"
//                   value={formData.zip}
//                   onChange={handleChange}
//                   isInvalid={!!errors.zip}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   {errors.zip}
//                 </Form.Control.Feedback>
//               </Form.Group>
//             </Col>
//           </Row>
//           <h4
//             className="mb-4 text-color"
//             style={{
//               width: "100%",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               backgroundColor: "#dcdaff",
//             }}
//           >
//             Bank Details
//           </h4>
//           <Row className="row-bottom">
//             <Col md={4}>
//               <Form.Group controlId="formAccountHolderName">
//                 <Form.Label>Account Holder Name</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="accountHolderName"
//                   value={formData.accountHolderName}
//                   onChange={handleChange}
//                   isInvalid={!!errors.accountHolderName}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   {errors.accountHolderName}
//                 </Form.Control.Feedback>
//               </Form.Group>
//             </Col>
//             <Col md={4}>
//               <Form.Group controlId="formBankAccountNo">
//                 <Form.Label>Account No.</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="bank_account_no"
//                   value={formData.bank_account_no}
//                   onChange={handleChange}
//                   isInvalid={!!errors.bank_account_no}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   {errors.bank_account_no}
//                 </Form.Control.Feedback>
//               </Form.Group>
//             </Col>
//             <Col md={4}>
//               <Form.Group controlId="formConfirmAccountNo">
//                 <Form.Label>Confirm Account No.</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="confirmAccountNo"
//                   value={formData.confirmAccountNo}
//                   onChange={handleChange}
//                   isInvalid={!!errors.confirmAccountNo}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   {errors.confirmAccountNo}
//                 </Form.Control.Feedback>
//               </Form.Group>
//             </Col>
//             <Col md={4}>
//               <Form.Group controlId="formIfscCode">
//                 <Form.Label>IFSC Code</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="ifsc_code"
//                   value={formData.ifsc_code}
//                   onChange={handleChange}
//                   isInvalid={!!errors.ifsc_code}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   {errors.ifsc_code}
//                 </Form.Control.Feedback>
//               </Form.Group>
//             </Col>
//           </Row>
//           <Button
//             variant="primary"
//             type="submit"
//             className="mt-3"
//             style={{ width: "100%", backgroundColor: "#4a2a7d" }}
//           >
//             Submit KYC
//           </Button>
//         </Form>
//       </div>
//     </div>
//   );
// };

// export default CompleteKYC;
