import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Modal from "react-modal";
import Button from "@mui/material/Button";

import EditIcon from "@mui/icons-material/Edit";
import moment from "moment/moment";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import { mkConfig, generateCsv, download } from "export-to-csv";

import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { Col, Container, Row } from "react-bootstrap";
import { baseUrl } from "../utils/baseUrl";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableSkeleton from "../utils/TableSkeleton";

const columnHelper = createMRTColumnHelper();

const columns = [
  columnHelper.accessor("name", {
    header: "Name",
    size: 30,
  }),
  columnHelper.accessor("Email", {
    header: "Email",
    size: 30,
  }),
  columnHelper.accessor("mobileNo", {
    header: "Mobile No",
    size: 30,
  }),
  columnHelper.accessor("createdAt", {
    header: "Created At",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>
        {moment(cell.row.original.createdAt.split("T")[0]).format("DD-MM-YYYY")}
      </div>
    ),
  }),
];

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});
const Client = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const token = sessionStorage.getItem("token");
  const [loading, setLoading] = useState(true);
  const [employeeList, setEmployeeList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [tagModalIsOpen, setTagModalIsOpen] = useState(false);
  const [viewModalIsOpen, setViewModalIsOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedClient, setSelectedClient] = useState("");
  const [Name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [designation, setDesignation] = useState("");
  const [taggedClients, setTaggedClients] = useState([]);
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const [NewMobile, setNewMobile] = useState("");
  const [clientCreateData, setclientCreateData] = useState("");
  const [clientCreatedDate, setclientCreatedDate] = useState([]);

  useEffect(() => {
    fetchClients();
  }, []);

  const convertToMMDDYYYY = (isoDateString) => {
    const date = new Date(isoDateString);
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // getMonth() returns 0-based month
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    setclientCreateData(`${month}/${day}/${year}`);
    return `${month}/${day}/${year}`;
  };

  const fetchClients = async () => {
    try {
      const url = `${baseUrl}/Client_List`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { headers });
      const employees = response.data.data;
      setLoading(false);
      console.log("Client list=", employees);
      employees?.map((item) => {
        setclientCreatedDate(item.createdAt);
      });
      setEmployeeList(employees);
      console.log("employee");
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const fetchEmployees = async () => {
    try {
      const url = `${baseUrl}/Employee_List`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { headers });
      const clients = response.data.data;
      console.log("Employee list=", response);
      clients?.map((item) => {
        setclientCreatedDate(item.createdAt);
        console.log(item.createdAt);
      });
      setClientList(clients);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const TagclientApi = async () => {
    try {
      const url = `${baseUrl}/tag`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        client_name: selectedEmployee.name,
        employee_name: selectedClient,
      };

      console.log("request body of client tag", requestBody);
      const response = await axios.post(url, requestBody, { headers });
      const data = response.data;
      console.log("client tag api response", data);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const employeeClienttagged = async (employee_id) => {
    console.log("employee _id", employee_id);
    try {
      const url = `${baseUrl}/employeetagged/${employee_id}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { headers });
      const taggedClientsData = response.data.data;
      console.log("Tagged employee data:", taggedClientsData);
      setTaggedClients(taggedClientsData);
    } catch (error) {
      console.error("Error fetching tagged clients data:", error);
    }
  };

  const updateClient = async (clientId) => {
    console.log("client id id in update", clientId);
    try {
      const url = `${baseUrl}/client_update/${clientId}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        name: Name,
        Email: email,
        Designation: designation,
      };

      console.log("request body of update", requestBody);
      const response = await axios.post(url, requestBody, { headers });
      const data = response.data;
      console.log("update api response", response);
      if (data.error == true) {
        toast.error(data.message);
      } else {
        toast.success(data.message);

        setClient({
          name: "",
          email: "",
          Designation: "",
        });
      }
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  // -------------------------------create new employee----------------------
  const createClientApi = async () => {
    try {
      const url = `${baseUrl}/client_create`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const payload = {
        name: client.name,
        Email: client.email,
        mobileNo: client.mobileNo,
      };

      const response = await axios.post(url, payload, { headers });
      const data = response.data;
      console.log("create new Client response", data);

      if (data.error == true) {
        toast.error(data.message);
      } else {
        toast.success(data.message);

        setClient({
          name: "",
          email: "",
          mobileNo: "",
        });
      }
    } catch (error) {
      console.error("Error connecting to API", error);
      toast.error("failed to create client");
    }
    fetchClients();
  };

  // ------------------------end---------------------------------

  const openEditModal = (employee) => {
    setSelectedEmployee(employee);
    setName(employee.name);
    setEmail(employee.Email);
    setDesignation(employee.Designation);
    setMobile(employee.PhoneNo);
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setSelectedEmployee(null);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMobilechange = (e) => {
    setMobile(e.target.value);
  };

  const handleDesignationChange = (e) => {
    setDesignation(e.target.value);
  };

  const handleSave = (employee) => {
    updateClient(employee._id);
    closeEditModal();
    fetchClients();
    if (employee.error == true) {
      toast.error(employee.message);
    } else {
      toast.success(employee.message);
    }
  };

  const openTagModal = async (employee) => {
    setSelectedEmployee(employee);
    await fetchEmployees();
    setTagModalIsOpen(true);
  };
  const handleExportData = () => {
    const rowData = employeeList.map((client) => ({
      name: client.name,
      email: client.Email,
      mobileNo: client.mobileNo,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => ({
      name: row.original.name,
      email: row.original.Email,
      mobileNo: row.original.mobileNo,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data: employeeList,
    enableRowSelection: true,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    enableRowActions: true,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },
    renderRowActions: ({ cell, row, table }) => [
      <Box sx={{ display: "flex" }}>
        <IconButton onClick={() => openEditModal(row.original)}>
          <EditIcon color="error" />
        </IconButton>

        <IconButton onClick={() => openEditModal(row.original)}>
          <Link to="/client_kyc" state={row.original}>
            <Button style={{ marginRight: "30px" }} variant="contained">
              KYC
            </Button>
          </Link>
        </IconButton>
      </Box>,
    ],
    positionToolbarAlertBanner: "bottom",
    paginationDisplayMode: "pages",

    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          onClick={() => handleExportData(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>

        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });

  const closeTagModal = () => {
    setTagModalIsOpen(false);
    setSelectedEmployee(null);
  };

  const handleTag = async () => {
    TagclientApi();
    closeTagModal();
    fetchClients();
  };

  const openViewModal = async (employee) => {
    setSelectedEmployee(employee);
    await employeeClienttagged(employee._id);
    setViewModalIsOpen(true);
  };

  const closeViewModal = () => {
    console.log("Closing view modal");
    setViewModalIsOpen(false);
    setTaggedClients([]);
  };

  const [client, setClient] = useState({
    name: "",
    email: "",
    mobileNo: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    mobileNo: "",
  });

  const handleClientChange = (e) => {
    const { id, value } = e.target;
    setClient({ ...client, [id]: value });
    setErrors({ ...errors, [id]: "" });
  };

  const validateForm = () => {
    let formValid = true;
    let newErrors = {};

    if (!client.name) {
      formValid = false;
      newErrors.name = "Name is required";
    }

    if (!client.email) {
      formValid = false;
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(client.email)) {
      formValid = false;
      newErrors.email = "Email is not valid";
    }

    if (!client.mobileNo) {
      formValid = false;
      newErrors.mobileNo = "Mobile No. is required";
    } else if (!/^\d{10}$/.test(client.mobileNo)) {
      formValid = false;
      newErrors.mobileNo = "Mobile No. must be 10 digits";
    }

    setErrors(newErrors);
    return formValid;
  };

  const handleCreateClient = (e) => {
    e.preventDefault();
    if (validateForm()) {
      createClientApi();
    }
  };

  return (
    <div className="container-fluid">
      <div className="dashboard section">
        <ToastContainer />
        {selectedEmployee && (
          <Modal
            isOpen={editModalIsOpen}
            onRequestClose={closeEditModal}
            contentLabel="Edit Employee"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                width: "50%",
              },
            }}
          >
            <h3 className="text-center fw-bold form_header ">Edit Client</h3>
            <form>
              <div className="form-group p-2">
                <label className="label-color">Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={Name}
                  onChange={handleNameChange}
                />
              </div>
              <div className="form-group p-2">
                <label className="label-color">Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="Email"
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>

              <Button
                variant="contained"
                type="button"
                className="btn btn-secondary mt-2"
                style={{ float: "left" }}
                onClick={closeEditModal}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="button"
                className="btn btn-primary mt-2"
                style={{ float: "right" }}
                onClick={() => handleSave(selectedEmployee)}
              >
                Update
              </Button>
            </form>
          </Modal>
        )}

        {selectedEmployee && (
          <Modal
            isOpen={tagModalIsOpen}
            onRequestClose={closeTagModal}
            contentLabel="Tag Employee"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                width: "50%",
              },
            }}
          >
            <h3 className="text-center fw-bold">Tag Client</h3>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleTag();
              }}
            >
              <div className="form-group p-2">
                <label>Client Name</label>
                <input
                  type="text"
                  className="form-control p-2"
                  name="name"
                  value={selectedEmployee.name}
                  readOnly
                />
              </div>

              <div className="form-group p-2">
                <label>Employee Name</label>
                <select
                  className="form-control"
                  value={selectedClient}
                  onChange={handleClientChange}
                >
                  <option value="">Select Client</option>
                  {clientList.map((client) => (
                    <option key={client.id} value={client.name}>
                      {client.name}
                    </option>
                  ))}
                </select>
              </div>

              <button
                type="button"
                className="btn btn-secondary mt-2"
                style={{ float: "left" }}
                onClick={closeTagModal}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary mt-2"
                style={{ float: "right" }}
              >
                Tag
              </button>
            </form>
          </Modal>
        )}
        {/* *******************************************************VIEW MODAL**************************************************  */}
        <Modal
          isOpen={viewModalIsOpen}
          onRequestClose={closeViewModal}
          contentLabel="View Tagged Clients"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              width: "50%",
            },
          }}
        >
          <h3 className="text-center  form_header">Tagged Employee</h3>
          {taggedClients.length > 0 ? (
            taggedClients.map((taggedClient, index) => (
              <div className="row">
                <div className="col-sm-6 text-center">
                  <p
                    style={{
                      fontSize: "16px",
                      color: "#4a2a7d",
                      fontWeight: "bold",
                    }}
                  >
                    Employee Name:{" "}
                  </p>
                  <p
                    style={{
                      fontSize: "16px",
                      color: "#4a2a7d",
                      fontWeight: "bold",
                    }}
                  >
                    Employee Mobile:{" "}
                  </p>
                  <p
                    style={{
                      fontSize: "16px",
                      color: "#4a2a7d",
                      fontWeight: "bold",
                    }}
                  >
                    Employee Email:{" "}
                  </p>
                </div>
                <div className="col-sm-5 text-left">
                  <p
                    style={{
                      fontSize: "16px",
                      color: "#4a2a7d",
                      fontWeight: "bold",
                    }}
                  >
                    {taggedClient.name}
                  </p>
                  <p
                    style={{
                      fontSize: "16px",
                      color: "#4a2a7d",
                      fontWeight: "bold",
                    }}
                  >
                    {taggedClient.PhoneNo}
                  </p>
                  <p
                    style={{
                      fontSize: "16px",
                      color: "#4a2a7d",
                      fontWeight: "bold",
                    }}
                  >
                    {taggedClient.Email}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center">
              No Emolpyee tagged with this Client.
            </div>
          )}
          <Button
            variant="contained"
            type="button"
            className="btn btn-secondary mt-2"
            onClick={closeViewModal}
          >
            Close
          </Button>
        </Modal>
        {/* create new model */}
        <Modal
          isOpen={createModalIsOpen}
          // onRequestClose={closeCreateModal}
          contentLabel="Create New Employee"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              width: "50%",
            },
          }}
        >
          {/* ........................... */}
          <h3 className="text-center fw-bold form_header">Create New Client</h3>
          <form>
            <div className="row">
              <div className="col">
                <div className="form-group p-2">
                  <label>Full Name</label>
                  <input
                  // type="text"
                  // onChange={handleNewEmployeeNameChange}
                  // required
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group p-2">
                  <label>Email</label>
                  <input
                  // type="email"
                  //  onChange={handleNewEmployeeEmailChange}
                  // required
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group p-2">
                  <label>Mobile No</label>
                  <input
                    // type="number"
                    // value={newEmployeeName}
                    // onChange={handleNewEmployeeMobileChange}
                    required
                  />
                </div>
              </div>
              {/* <div className='col'>
                            <div className="form-group p-2">
                                <label>Designation</label>
                                <input
                                    type="email"
                                    className="form-control"

                                    onChange={handlenewDesignantion}
                                    required
                                />
                            </div>
                        </div> */}
            </div>

            <Button
              variant="contained"
              type="button"
              className="btn btn-secondary mt-2"
              style={{ float: "left" }}
              // onClick={closeCreateModal}
            >
              Cancel
            </Button>
            <Button
              type="button"
              variant="contained"
              className="btn btn-primary mt-2"
              style={{ float: "right" }}
              // onClick={handleCreateEmployee}
            >
              Create
            </Button>
          </form>
        </Modal>
      </div>
      <div className="dashboard section">
        <Card className="mb-4" style={{ width: "100%", height: "90%" }}>
          <h2 className="TABLE_HEADING"> ADD CLIENT</h2>
          <Container fluid>
            <Row className="mb-4">
              <Col xs={12}>
                <Box
                  component="form"
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    margin: "0.5rem",
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        sx={{ marginBottom: 1 }}
                        id="name"
                        label="Name"
                        required
                        fullWidth
                        size="small"
                        variant="outlined"
                        type="text"
                        value={client.name}
                        onChange={handleClientChange}
                        error={!!errors.name}
                        helperText={errors.name}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        sx={{ marginBottom: 1 }}
                        id="email"
                        label="Email"
                        required
                        fullWidth
                        variant="outlined"
                        size="small"
                        type="email"
                        value={client.email}
                        onChange={handleClientChange}
                        error={!!errors.email}
                        helperText={errors.email}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        sx={{ marginBottom: 1 }}
                        id="mobileNo"
                        label="Mobile No."
                        required
                        fullWidth
                        size="small"
                        inputProps={{ maxLength: 10 }}
                        variant="outlined"
                        type="text"
                        value={client.mobileNo}
                        onChange={handleClientChange}
                        error={!!errors.mobileNo}
                        helperText={errors.mobileNo}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={3}
                      sx={{ marginTop: "0.3rem" }}
                    >
                      <Button
                        variant="contained"
                        style={{ width: "100%" }}
                        onClick={handleCreateClient}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Col>
            </Row>
          </Container>
        </Card>
        <Row>
          <Col xs={12}>
            {loading ? <TableSkeleton /> : <MaterialReactTable table={table} />}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Client;

// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import Modal from "react-modal";
// import { DataGrid } from "@mui/x-data-grid";
// import { DateRangePicker } from "rsuite";
// import Paper from "@mui/material/Paper";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TablePagination from "@mui/material/TablePagination";
// import TableRow from "@mui/material/TableRow";
// import Button from "@mui/material/Button";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";

// import EditIcon from "@mui/icons-material/Edit";
// import Divider from "@mui/material/Divider";
// import ArchiveIcon from "@mui/icons-material/Archive";
// import FileCopyIcon from "@mui/icons-material/FileCopy";
// import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import Box from "@mui/material/Box";
// import TextField from "@mui/material/TextField";
// import AddIcon from "@mui/icons-material/Add";
// import { FormControl, Input, InputLabel, Typography } from "@mui/material";
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";

// // import { ToastContainer, toast } from 'react-toastify';
// // import 'react-toastify/dist/ReactToastify.css';

// // const notify = () => toast("Save Successfully");

// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

// const rows = [
//   createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
//   createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
//   createData("Eclair", 262, 16.0, 24, 6.0),
//   createData("Cupcake", 305, 3.7, 67, 4.3),
//   createData("Gingerbread", 356, 16.0, 49, 3.9),
//   createData("Gingerbread", 356, 16.0, 49, 3.9),
//   createData("Gingerbread", 356, 16.0, 49, 3.9),
//   createData("Gingerbread", 356, 16.0, 49, 3.9),
//   createData("Gingerbread", 356, 16.0, 49, 3.9),
//   createData("Gingerbread", 356, 16.0, 49, 3.9),
// ];
// // Modal.setAppElement("#root"); // Required for accessibility

// // const columns = [
// //   { field: 'id', headerName: 'ID',  },
// //   { field: 'Name', headerName: 'Name', },
// //   { field: 'Email', headerName: 'Email', },
// //   { field: 'Mobile No', headerName: 'Mobile No', },
// //   { field: 'Edit', headerName: 'Edit',},
// //   { field: 'Tag', headerName: 'Tag',},
// //   { field: 'Tagged Employee', headerName: 'Tagged Employee', },
// //   {
// //     field: 'age',
// //     headerName: 'Age',
// //     type: 'number',
// //     width: 90,
// //   },
// //   {
// //     field: 'fullName',
// //     headerName: 'Full name',
// //     description: 'This column has a value getter and is not sortable.',
// //     sortable: false,
// //     width: 160,
// //     valueGetter: (value, row) => `${row.firstName || ''} ${row.lastName || ''}`,
// //   },
// // ];

// // const rows = [
// //   { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
// //   { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
// //   { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
// //   { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
// //   { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
// //   { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
// //   { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
// //   { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
// //   { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
// // ];

// // const initialValues = {
// //   fname: "",
// //   phone: "",
// //   email: "",
// // };

// const Client = () => {
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const open = Boolean(anchorEl);
//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//   // const handleClose = () => {
//   //   setAnchorEl(null);
//   // };

//   const token = sessionStorage.getItem("token");
//   console.log("token", token);

//   // const [values, setValues] = useState(initialValues);
//   const [employeeList, setEmployeeList] = useState([]);
//   const [clientList, setClientList] = useState([]);
//   const [editModalIsOpen, setEditModalIsOpen] = useState(false);
//   const [tagModalIsOpen, setTagModalIsOpen] = useState(false);
//   const [viewModalIsOpen, setViewModalIsOpen] = useState(false);
//   const [selectedEmployee, setSelectedEmployee] = useState(null);
//   const [selectedClient, setSelectedClient] = useState("");
//   // const [Name, setName] = useState("");
//   // const [email, setEmail] = useState("");
//   // const [mobile, setMobile] = useState("");
//   const [designation, setDesignation] = useState("");
//   const [taggedClients, setTaggedClients] = useState([]);
//   const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
//   const [newEmployeeName, setNewEmployeeName] = useState("");
//   const [newEmployeeEmail, setNewEmployeeEmail] = useState("");
//   const [NewMobile, setNewMobile] = useState("");
//   const [clientCreateData, setclientCreateData] = useState("");
//   const [clientCreatedDate, setclientCreatedDate] = useState([]);

//   // -------------create new employee----------------

//   const [fullName, setFullName] = useState("");
//   const [NewEmail, setNewEmail] = useState("");
//   const [NewDesign, setNewDesign] = useState("");

//   // const [errors, setErrors] = useState({ name: '', email: '', mobile: '' });

//   useEffect(() => {
//     fetchClients();
//   }, []);
//   console.log("clientCreatedDate", clientCreatedDate);

//   const handleChange = (e) => {
//     // const { name, value ,mobile } = e.target;
//     // // setValues({
//     // //   ...values,
//     // //   [name]: value,
//     // // });
//     // setNewMobile(e.target.value);
//     // setName(e.target.value);
//     // setEmail(e.target.value);
//   };

//   const convertToMMDDYYYY = (isoDateString) => {
//     const date = new Date(isoDateString);
//     const month = (date.getMonth() + 1).toString().padStart(2, "0"); // getMonth() returns 0-based month
//     const day = date.getDate().toString().padStart(2, "0");
//     const year = date.getFullYear();
//     setclientCreateData(`${month}/${day}/${year}`);
//     return `${month}/${day}/${year}`;
//   };

//   console.log("convert", clientCreateData); // Output: 05/28/2024

//   const fetchClients = async () => {
//     try {
//       const url = `${baseUrl}/Client_List";
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${token}`,
//       };
//       const response = await axios.get(url, { headers });
//       const employees = response.data.data;
//       console.log("Client list=", employees);
//       employees?.map((item) => {
//         setclientCreatedDate(item.createdAt);
//       });
//       setEmployeeList(employees);
//       console.log("employee");
//     } catch (error) {
//       console.error("Error connecting to API", error);
//     }
//   };

//   const fetchEmployees = async () => {
//     try {
//       const url = `${baseUrl}/Employee_List";
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${token}`,
//       };
//       const response = await axios.get(url, { headers });
//       const clients = response.data.data;
//       console.log("Employee list=", response);
//       clients?.map((item) => {
//         setclientCreatedDate(item.createdAt);
//         console.log(item.createdAt);
//       });
//       setClientList(clients);
//     } catch (error) {
//       console.error("Error connecting to API", error);
//     }
//   };

//   console.log("taggedClients", taggedClients);
//   const TagclientApi = async () => {
//     try {
//       const url = `${baseUrl}/tag";
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${token}`,
//       };

//       const requestBody = {
//         client_name: selectedEmployee.name,
//         employee_name: selectedClient,
//       };

//       console.log("request body of client tag", requestBody);
//       const response = await axios.post(url, requestBody, { headers });
//       const data = response.data;
//       console.log("client tag api response", data);
//     } catch (error) {
//       console.error("Error connecting to API", error);
//     }
//   };

//   const employeeClienttagged = async (employee_id) => {
//     console.log("employee _id", employee_id);
//     try {
//       const url = `https://app.batuk.in/employeetagged/${employee_id}`;
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${token}`,
//       };
//       const response = await axios.get(url, { headers });
//       const taggedClientsData = response.data.data;
//       console.log("Tagged employee data:", taggedClientsData);
//       setTaggedClients(taggedClientsData);
//     } catch (error) {
//       console.error("Error fetching tagged clients data:", error);
//     }
//   };

//   // const updateClient = async (clientId) => {
//   //   console.log("client id id in update", clientId);
//   //   try {
//   //     const url = `https://app.batuk.in/client_update/${clientId}`;
//   //     const headers = {
//   //       "Content-Type": "application/json",
//   //       Accept: "application/json",
//   //       Authorization: `Bearer ${token}`,
//   //     };

//   //     const requestBody = {
//   //       name: Name,
//   //       Email: email,
//   //       Designation: designation,
//   //       // Designation: mobile // Changed from phone to designation
//   //     };

//   //     console.log("request body of update", requestBody);
//   //     const response = await axios.post(url, requestBody, { headers });
//   //     const data = response.data;
//   //     console.log("update api response", response);
//   //   } catch (error) {
//   //     console.error("Error connecting to API", error);
//   //   }
//   // };

//   // -------------------------------create new employee----------------------
//   // const CreateNewEmp = async () => {
//   //   try {
//   //     const url = `${baseUrl}/client_create";
//   //     console.log(url);
//   //     const headers = {
//   //       "Content-Type": "application/json",
//   //       Accept: "application/json",
//   //       Authorization: `Bearer ${token}`,
//   //     };

//   //     const requestBody = {
//   //       name: newEmployeeName,
//   //       Email: newEmployeeEmail,
//   //       mobileNo: NewMobile,
//   //     };

//   //     console.log("request body of create new client", requestBody);

//   //     const response = await axios.post(url, requestBody, { headers });
//   //     const data = response.data;
//   //     console.log("create new Client response", data);
//   //   } catch (error) {
//   //     console.error("Error connecting to API", error);
//   //   }
//   //   fetchClients();
//   // };

//   const handleNewEmployeeMobileChange = (e) => {
//     setNewMobile(e.target.value);
//   };

//   const handlenewDesignantion = (e) => {
//     setNewDesign(e.target.value);
//   };

//   // ------------------------end---------------------------------

//   // const openEditModal = (employee) => {
//   //   setSelectedEmployee(employee);
//   //   setName(employee.name);
//   //   setEmail(employee.Email);
//   //   setDesignation(employee.Designation);
//   //   setMobile(employee.PhoneNo); // Set the designation from employee data
//   //   setEditModalIsOpen(true);
//   // };

//   const closeEditModal = () => {
//     setEditModalIsOpen(false);
//     setSelectedEmployee(null);
//   };

//   const handleNameChange = (e) => {
//     // setName(e.target.value);
//   };

//   const handleEmailChange = (e) => {
//     // setEmail(e.target.value);
//   };

//   const handleMobilechange = (e) => {
//     // setMobile(e.target.value);
//   };

//   const handleDesignationChange = (e) => {
//     setDesignation(e.target.value);
//   };

//   const handleSave = (employee) => {
//     // updateClient(employee._id);
//     // console.log('Updated employee details:', selectedEmployee);
//     closeEditModal();
//     fetchClients();
//   };

//   const openTagModal = async (employee) => {
//     setSelectedEmployee(employee);
//     await fetchEmployees();
//     setTagModalIsOpen(true);
//   };

//   const closeTagModal = () => {
//     setTagModalIsOpen(false);
//     setSelectedEmployee(null);
//   };

//   const handleClientChange = (e) => {
//     setSelectedClient(e.target.value);
//   };

//   const handleTag = async () => {
//     TagclientApi();
//     closeTagModal();
//     fetchClients();
//   };

//   const openViewModal = async (employee) => {
//     setSelectedEmployee(employee);
//     await employeeClienttagged(employee._id);
//     setViewModalIsOpen(true);
//   };

//   const closeViewModal = () => {
//     console.log("Closing view modal");
//     setViewModalIsOpen(false);
//     setTaggedClients([]);
//   };

//   // const openCreateModal = () => {
//   //   setCreateModalIsOpen(true);
//   // };

//   // const closeCreateModal = () => {
//   //   setCreateModalIsOpen(false);
//   // };

//   const handleNewEmployeeNameChange = (e) => {
//     setNewEmployeeName(e.target.value);
//   };

//   const handleNewEmployeeEmailChange = (e) => {
//     setNewEmployeeEmail(e.target.value);
//   };

//   // const handleCreateEmployee = (e) => {
//   //   e.preventDefault();

//   //   validation();
//   //   CreateNewEmp();
//   //   // closeCreateModal();
//   // };

//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(5);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const slicedRows = rows.slice(
//     page * rowsPerPage,
//     page * rowsPerPage + rowsPerPage
//   );
//   const [query, setQuery] = useState("");

//   const handleInputChange = (e) => {
//     setQuery(e.target.value);
//   };

//   const handleSearch = (e) => {
//     e.preventDefault();
//     console.log("data input search", employeeList);
//     const filteredDates = employeeList?.filter((dateString) => {
//       console.log("created at=======================", dateString);
//       return query == dateString.name || dateString.Email || dateString.PhoneNo;
//     });

//     console.log("filtered name", filteredDates);
//     setfilteredClients(filteredDates);
//   };

//   const [dateRange, setDateRange] = useState([null, null]);
//   const dateRangePickerRef = useRef();

//   useEffect(() => {
//     // Example of using querySelector to get the value when component mounts or updates
//     const getDateRangeValue = () => {
//       const dateRangeElement = document.querySelector("#date-range-picker");
//       if (dateRangeElement) {
//         console.log(
//           "Selected Date Range via querySelector:",
//           dateRangeElement.value
//         );
//       }
//     };

//     getDateRangeValue();
//   }, [dateRange]);

//   const [value, setValue] = React.useState([new Date(""), new Date("")]);

//   const datearr = [
//     "2024-05-28T08:04:06.028Z",
//     "2024-07-28T08:04:06.028Z",
//     "2024-01-28T08:04:06.028Z",
//     "2024-09-28T08:04:06.028Z",
//   ];
//   console.log("clientCreatedDate", clientCreatedDate);
//   var firstDate;
//   var lastDate;
//   const [filteredClients, setfilteredClients] = useState(employeeList);

//   useEffect(() => {
//     firstDate = convertToMMDDYYYY(value[0]);
//     lastDate = convertToMMDDYYYY(value[1]);
//     console.log("date range", firstDate, lastDate);
//     console.log(
//       "clientList==================================================",
//       clientList
//     );
//     const filteredDates = employeeList?.filter((dateString) => {
//       console.log("created at=======================", dateString);
//       const date = convertToMMDDYYYY(dateString.createdAt);
//       console.log("new da", date);
//       return date >= firstDate && date <= lastDate;
//     });

//     console.log("filteredDates", filteredDates);
//     setfilteredClients(filteredDates);
//   }, [value]);

//   // edit by jaspal client form
//   const [formdata, setformdata] = useState({});
//   const { name, Email, mobileNo } = formdata;
//   console.log("formdata: ", formdata);
//   const [formError, setformError] = useState({});
//   console.log("formError: ", formError);
//   const { eName, eEmail, eMobile } = formError;
//   const [buttonstate, setbuttonstate] = useState(false);
//   let err = {};

//   const regName = /^[a-zA-Z]+$/;
//   const regEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
//   const regMobile = /^\d{10}$/;

//   const f1 = () => {
//     if (Object.keys(formdata).length > 0) {
//       setformError({});
//     }
//   };

//   const validation = () => {
//     if (!name) {
//       err.eName = "Name is required";
//       setformError(err);
//     } else if (!regName.test(name)) {
//       err.eName = "Name should be text Only";
//       setformError(err);
//     } else if (!Email) {
//       err.eEmail = "Email is required";
//       setformError(err);
//     } else if (!regEmail.test(Email)) {
//       err.eEmail = "Email not valid";
//       setformError(err);
//     } else if (!mobileNo) {
//       err.eMobile = "Mobile no. is required";
//       setformError(err);
//     } else if (!regMobile.test(mobileNo)) {
//       err.mobileNo = "Mobile no. not valid";
//       setformError(err);
//     }
//   };
//   const handleClient = (e) => {
//     setformdata({
//       ...formdata,
//       [e.target.id]: e.target.value,
//     });
//   };
//   const handleCreateEmployee = (e) => {
//     e.preventDefault();
//     validation();
//     setbuttonstate(true);
//     // CreateNewEmp();

//     // closeCreateModal();
//   };

//   const CreateNewEmp = async () => {
//     try {
//       const url = `${baseUrl}/client_create";
//       console.log(url);
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${token}`,
//       };

//       const requestBody = {
//         name: newEmployeeName,
//         Email: newEmployeeEmail,
//         mobileNo: NewMobile,
//       };

//       console.log("request body of create new client", requestBody);

//       const response = await axios.post(url, requestBody, { headers });
//       const data = response.data;
//       console.log("create new Client response", data);
//     } catch (error) {
//       console.error("Error connecting to API", error);
//     }
//     fetchClients();
//   };

//   useEffect(() => {
//     if (Object.keys(formError).length === 0 && buttonstate === true) {
//       CreateNewEmp();
//     }
//   }, [buttonstate]);
//   return (
//     <>
//       <div className="container">
//         {/* Client List Edit update*/}

//         {selectedEmployee && (
//           <Modal
//             isOpen={editModalIsOpen}
//             onRequestClose={closeEditModal}
//             contentLabel="Edit Employee"
//             style={{
//               overlay: {
//                 backgroundColor: "rgba(0, 0, 0, 0.5)",
//               },
//               content: {
//                 top: "50%",
//                 left: "50%",
//                 right: "auto",
//                 bottom: "auto",
//                 marginRight: "-50%",
//                 transform: "translate(-50%, -50%)",
//                 width: "50%",
//               },
//             }}
//           >
//             <h3 className="text-center fw-bold form_header">Edit Client</h3>
//             <form>
//               <div className="form-group p-2">
//                 <label>User ID</label>
//                 <input
//                   type="text"
//                   name="name"
//                   value={selectedEmployee._id}
//                   // readOnly
//                 />
//               </div>

//               <div className="form-group p-2">
//                 <label>Name</label>
//                 <input
//                   type="text"
//                   name="name"
//                   value={Name}
//                   onChange={handleNameChange}
//                 />
//               </div>
//               <div className="form-group p-2">
//                 <label>Email</label>
//                 <input
//                   type="email"
//                   className="form-control"
//                   name="Email"
//                   value={email}
//                   onChange={handleEmailChange}
//                 />
//               </div>

//               <div className="form-group p-2">
//                 <label>Designation</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   name="designation"
//                   value={designation}
//                   onChange={handleDesignationChange}
//                 />
//               </div>

//               <Button
//                 variant="contained"
//                 type="button"
//                 className="btn btn-secondary mt-2"
//                 style={{ float: "left" }}
//                 onClick={closeEditModal}
//               >
//                 Cancel
//               </Button>
//               <Button
//                 variant="contained"
//                 type="button"
//                 className="btn btn-primary mt-2"
//                 style={{ float: "right" }}
//                 onClick={() => handleSave(selectedEmployee)}
//               >
//                 Update
//               </Button>
//             </form>
//           </Modal>
//         )}

//         {selectedEmployee && (
//           <Modal
//             isOpen={tagModalIsOpen}
//             onRequestClose={closeTagModal}
//             contentLabel="Tag Employee"
//             style={{
//               overlay: {
//                 backgroundColor: "rgba(0, 0, 0, 0.5)",
//               },
//               content: {
//                 top: "50%",
//                 left: "50%",
//                 right: "auto",
//                 bottom: "auto",
//                 marginRight: "-50%",
//                 transform: "translate(-50%, -50%)",
//                 width: "50%",
//               },
//             }}
//           >
//             <h3 className="text-center fw-bold">Tag Client</h3>
//             <form
//               onSubmit={(e) => {
//                 e.preventDefault();
//                 handleTag();
//               }}
//             >
//               <div className="form-group p-2">
//                 <label>Client Name</label>
//                 <input
//                   type="text"
//                   className="form-control p-2"
//                   name="name"
//                   value={selectedEmployee.name}
//                   readOnly
//                 />
//               </div>

//               <div className="form-group p-2">
//                 <label>Employee Name</label>
//                 <select
//                   className="form-control"
//                   value={selectedClient}
//                   onChange={handleClientChange}
//                 >
//                   <option value="">Select Client</option>
//                   {clientList.map((client) => (
//                     <option key={client.id} value={client.name}>
//                       {client.name}
//                     </option>
//                   ))}
//                 </select>
//               </div>

//               <button
//                 type="button"
//                 className="btn btn-secondary mt-2"
//                 style={{ float: "left" }}
//                 onClick={closeTagModal}
//               >
//                 Cancel
//               </button>
//               <button
//                 type="submit"
//                 className="btn btn-primary mt-2"
//                 style={{ float: "right" }}
//               >
//                 Tag
//               </button>
//             </form>
//           </Modal>
//         )}
//         {/* tagged employe */}
//         <Modal
//           isOpen={viewModalIsOpen}
//           onRequestClose={closeViewModal}
//           contentLabel="View Tagged Clients"
//           style={{
//             overlay: {
//               backgroundColor: "rgba(0, 0, 0, 0.5)",
//             },
//             content: {
//               top: "50%",
//               left: "50%",
//               right: "auto",
//               bottom: "auto",
//               marginRight: "-50%",
//               transform: "translate(-50%, -50%)",
//               width: "50%",
//             },
//           }}
//         >
//           <h3 className="text-center  form_header">Tagged Employee</h3>
//           {taggedClients.length > 0 ? (
//             taggedClients.map((taggedClient, index) => (
//               <div className="row">
//                 <div className="col-sm-6 text-center">
//                   <p
//                     style={{
//                       fontSize: "16px",
//                       color: "#4a2a7d",
//                       fontWeight: "bold",
//                     }}
//                   >
//                     Employee Name:{" "}
//                   </p>
//                   <p
//                     style={{
//                       fontSize: "16px",
//                       color: "#4a2a7d",
//                       fontWeight: "bold",
//                     }}
//                   >
//                     Employee Mobile:{" "}
//                   </p>
//                   <p
//                     style={{
//                       fontSize: "16px",
//                       color: "#4a2a7d",
//                       fontWeight: "bold",
//                     }}
//                   >
//                     Employee Email:{" "}
//                   </p>
//                 </div>
//                 <div className="col-sm-5 text-left">
//                   <p
//                     style={{
//                       fontSize: "16px",
//                       color: "#4a2a7d",
//                       fontWeight: "bold",
//                     }}
//                   >
//                     {taggedClient.name}
//                   </p>
//                   <p
//                     style={{
//                       fontSize: "16px",
//                       color: "#4a2a7d",
//                       fontWeight: "bold",
//                     }}
//                   >
//                     {taggedClient.PhoneNo}
//                   </p>
//                   <p
//                     style={{
//                       fontSize: "16px",
//                       color: "#4a2a7d",
//                       fontWeight: "bold",
//                     }}
//                   >
//                     {taggedClient.Email}
//                   </p>
//                 </div>
//               </div>
//             ))
//           ) : (
//             <div className="text-center">
//               No Emolpyee tagged with this Client.
//             </div>
//           )}
//           <Button
//             variant="contained"
//             type="button"
//             className="btn btn-secondary mt-2"
//             // onClick={closeViewModal}
//           >
//             Close
//           </Button>
//         </Modal>
//         {/* create new model */}
//         <Modal
//           isOpen={createModalIsOpen}
//           // onRequestClose={closeCreateModal}
//           contentLabel="Create New Employee"
//           style={{
//             overlay: {
//               backgroundColor: "rgba(0, 0, 0, 0.5)",
//             },
//             content: {
//               top: "50%",
//               left: "50%",
//               right: "auto",
//               bottom: "auto",
//               marginRight: "-50%",
//               transform: "translate(-50%, -50%)",
//               width: "50%",
//             },
//           }}
//         >
//           {/* ........................... */}
//           <h3 className="text-center fw-bold form_header">Create New Client</h3>
//           <form>
//             <div className="row">
//               <div className="col">
//                 <div className="form-group p-2">
//                   <label>Full Name</label>
//                   <input
//                   // type="text"
//                   // onChange={handleNewEmployeeNameChange}
//                   // required
//                   />
//                 </div>
//               </div>
//               <div className="col">
//                 <div className="form-group p-2">
//                   <label>Email</label>
//                   <input
//                     type="email"
//                     //  onChange={handleNewEmployeeEmailChange}
//                     // required
//                   />
//                 </div>
//               </div>
//             </div>
//             <div className="row">
//               <div className="col">
//                 <div className="form-group p-2">
//                   <label>Mobile No</label>
//                   <input
//                     // type="number"
//                     // value={newEmployeeName}
//                     // onChange={handleNewEmployeeMobileChange}
//                     required
//                   />
//                 </div>
//               </div>
//               {/* <div className='col'>
//                             <div className="form-group p-2">
//                                 <label>Designation</label>
//                                 <input
//                                     type="email"
//                                     className="form-control"

//                                     onChange={handlenewDesignantion}
//                                     required
//                                 />
//                             </div>
//                         </div> */}
//             </div>

//             <Button
//               variant="contained"
//               type="button"
//               className="btn btn-secondary mt-2"
//               style={{ float: "left" }}
//               // onClick={closeCreateModal}
//             >
//               Cancel
//             </Button>
//             <Button
//               type="button"
//               variant="contained"
//               className="btn btn-primary mt-2"
//               style={{ float: "right" }}
//               // onClick={handleCreateEmployee}
//             >
//               Create
//             </Button>
//           </form>
//         </Modal>
//       </div>

//       <div className="conainer">

//         <div className="row">
//         <FormControl>
//               {/* <InputLabel htmlFor="my-input">Name</InputLabel>
//               <Input id="name" />
//               <InputLabel htmlFor="my-input">Email</InputLabel>
//               <Input id="Email" />
//               <InputLabel htmlFor="my-input">Mobile</InputLabel>
//               <Input id="mobileNo" /> */}

//   <InputLabel htmlFor="my-input">Email address</InputLabel>
//   <Input id="my-input" aria-describedby="my-helper-text" />
// </FormControl>
//           <div className="col-sm-12">

//             {/* ADD CLIENT */}
//             {/* <form Create New Client> */}
//             {/* <Card sx={{ minWidth: 275, height: 160 }}>
//               <CardContent>
//                 <Typography variant="h6" style={{ mb: 2, height: 40 }}>
//                   ADD CLIENT
//                 </Typography>
//                 <form onSubmit={handleCreateEmployee}>
//                 <Box
//                   onSubmit={handleCreateEmployee}
//                   component="form"
//                   sx={{
//                     "& > :not(style)": { mt: 5, width: "35ch", mb: 4, m: 1 },
//                   }}
//                   // noValidate
//                   // autoComplete="off"
//                 >
//                   <TextField
//                     id="name"
//                     label={eName ? eName : "Name"}
//                     required
//                     fullWidth
//                     size="small"
//                     variant="outlined"
//                     type="text"
//                     // onChange={handleNewEmployeeNameChange}
//                     onChange={handleClient}
//                     onInput={f1}
//                   />

//                   <TextField
//                     id="Email"
//                     label="Email"
//                     required
//                     fullWidth
//                     variant="outlined"
//                     size="small"
//                     type="email"
//                     // onChange={handleNewEmployeeEmailChange}
//                     onChange={handleClient}
//                     onInput={f1}
//                   />

//                   <TextField
//                     id="mobileNo"
//                     label="Mobile No."
//                     required
//                     fullWidth
//                     variant="outlined"
//                     size="small"
//                     type="text"
//                     //  value={newEmployeeName}
//                     // onChange={handleNewEmployeeMobileChange}
//                     onChange={handleClient}
//                     onInput={f1}
//                   />

//                   <div>
//                       <button type="submit">Save</button>
//                     </div>

//                   <Button
//                     type="submit"
//                     variant="contained"
//                     style={{ width: 28 }}
//                     // onClick={handleCreateEmployee}
//                   >
//                     Save
//                   </Button>
//                 </Box>
//                 </form>
//               </CardContent>
//             </Card> */}

//             {/* <Button
//               variant="contained"
//               endIcon={<AddIcon sx={{ color: "white !important" }} />}
//               // onClick={openCreateModal}
//               sx={{ marginBottom: "20px", textTransform: "capitalize",marginTop:"11px"}}>
//               Create New Client
//             </Button> */}
//           </div>
//           <div className="col-sm-6 d-flex justify-content-end">
//             <form>
//               {/* <DateRangePicker
//                 character=" – "
//                 style={{
//                   color: "#4a2a7d !important",
//                   border: " solid 2px #4a2a7d",
//                   marginRight: "20px",
//                 }}
//                 value={value >= 1 ? value : null}
//                 onChange={setValue}
//                 placeholder="select date"
//               /> */}
//               {/* <input
//                 type="text"
//                 placeholder="Search..."
//                 value={query}
//                 onChange={handleInputChange}
//                 id="searchInput"
//               />
//               <Button
//                 variant="contained"
//                 type="button"
//                 className="btn btn-primary"
//                 id="searchSubmit"
//                 onClick={handleSearch}>
//                 Search
//               </Button> */}
//             </form>
//           </div>
//           <div className="col-sm-12 client">
//             <Paper>
//               <TableContainer>
//                 <Table>
//                   <TableHead
//                     sx={{ backgroundColor: "#F2F3F7", color: "black" }}
//                   >
//                     <TableRow>
//                       <TableCell align="center">#</TableCell>
//                       <TableCell align="center">Name</TableCell>
//                       <TableCell align="center">Email</TableCell>
//                       <TableCell align="center">Mobile No</TableCell>
//                       <TableCell align="center">Tagged Employee</TableCell>
//                       <TableCell align="center">Action</TableCell>
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     {/* =============================================================== */}

//                     {query !== ""
//                       ? filteredClients.map((row, index) => (
//                           <TableRow
//                             key={index}
//                             sx={{
//                               "&:last-child td, &:last-child th": { border: 0 },
//                               backgroundColor: "#ffff",
//                             }}
//                           >
//                             <TableCell align="center">{index + 1}</TableCell>
//                             <TableCell align="center">{row.name}</TableCell>
//                             <TableCell align="center">{row.Email}</TableCell>
//                             <TableCell align="center">{row.mobileNo}</TableCell>
//                             <TableCell align="center">
//                               {/* ============================== */}

//                               {console.log(
//                                 "taggedClients",
//                                 taggedClients.length == 0
//                               )}
//                               {/* {  taggedClients.length === 0 ? "No Emolpyee tagged with this Client.":<Button
//                                 id="demo-positioned-button"
//                                 variant="contained"
//                                 aria-controls={
//                                   open ? "demo-positioned-menu" : undefined
//                                 }
//                                 aria-haspopup="true"
//                                 aria-expanded={open ? "true" : undefined}
//                                 onClick={() => openViewModal(row)}
//                                 disableElevation
//                                >
//                                 View Tagged Employee
//                               </Button>} */}
//                               <Button
//                                 id="demo-positioned-button"
//                                 variant="contained"
//                                 aria-controls={
//                                   open ? "demo-positioned-menu" : undefined
//                                 }
//                                 aria-haspopup="true"
//                                 aria-expanded={open ? "true" : undefined}
//                                 onClick={() => openViewModal(row)}
//                                 disableElevation
//                               >
//                                 View
//                               </Button>
//                               {/* ============================== */}
//                             </TableCell>
//                             <TableCell align="center">
//                               <Button
//                                 style={{ marginRight: "20px" }}
//                                 variant="contained"
//                                 onClick={() => openTagModal(row)}
//                               >
//                                 Tag Employee
//                               </Button>
//                               <Button
//                                 style={{ marginRight: "20px" }}
//                                 variant="contained"
//                                 // onClick={() => openEditModal(row)}
//                               >
//                                 Edit
//                               </Button>
//                               {/* <Button
//                              style={{marginRight:"20px"}}
//                               variant="contained"
//                                 // onClick={() => openEditModal(row)}
//                                 >
//                                 Delete
//                               </Button> */}
//                             </TableCell>
//                           </TableRow>
//                         ))
//                       : employeeList.map((row, index) => (
//                           <TableRow
//                             key={index}
//                             sx={{
//                               "&:last-child td, &:last-child th": { border: 0 },
//                               backgroundColor: "#ffff",
//                             }}
//                           >
//                             <TableCell align="center">{index + 1}</TableCell>
//                             <TableCell align="center">{row.name}</TableCell>
//                             <TableCell align="center">{row.Email}</TableCell>
//                             <TableCell align="center">{row.mobileNo}</TableCell>
//                             <TableCell align="center">
//                               {/*
//                           {   taggedClients.length === 0 ? "No Emolpyee tagged with this Client.":<Button
//                                 id="demo-positioned-button"
//                                 variant="contained"
//                                 aria-controls={
//                                   open ? "demo-positioned-menu" : undefined
//                                 }
//                                 aria-haspopup="true"
//                                 aria-expanded={open ? "true" : undefined}
//                                 onClick={() => openViewModal(row)}
//                                 disableElevation
//                                >
//                                 View Tagged Employee
//                               </Button>} */}

//                               <Button
//                                 id="demo-positioned-button"
//                                 variant="contained"
//                                 aria-controls={
//                                   open ? "demo-positioned-menu" : undefined
//                                 }
//                                 aria-haspopup="true"
//                                 aria-expanded={open ? "true" : undefined}
//                                 onClick={() => openViewModal(row)}
//                                 disableElevation
//                               >
//                                 View
//                               </Button>
//                             </TableCell>
//                             <TableCell align="center">
//                               <Button
//                                 style={{ marginRight: "20px" }}
//                                 variant="contained"
//                                 // onClick={() => openEditModal(row)}
//                               >
//                                 Edit
//                               </Button>
//                               <Button
//                                 style={{ marginRight: "30px" }}
//                                 variant="contained"
//                                 onClick={() => openTagModal(row)}
//                               >
//                                 Tag Employee
//                               </Button>

//                               {/* <Button
//                                 variant="contained"
//                                 style={{marginRight:"20px"}}
//                                 // onClick={() => openEditModal(row)}
//                                 >
//                                Delete
//                               </Button> */}
//                             </TableCell>
//                           </TableRow>
//                         ))}

//                     {/* ================================================================ */}
//                   </TableBody>
//                 </Table>
//               </TableContainer>
//               <TablePagination
//                 rowsPerPageOptions={[5, 10, 25]}
//                 component="div"
//                 count={rows.length}
//                 rowsPerPage={rowsPerPage}
//                 page={page}
//                 onPageChange={handleChangePage}
//                 onRowsPerPageChange={handleChangeRowsPerPage}
//               />
//             </Paper>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Client;

// ===============================================================================================================
