import React from "react";
import NavBar1 from "../../components/Navbar";

const Blogs = () => {
  return (
    <>
      <NavBar1 />
      <div>Blogs</div>
    </>
  );
};

export default Blogs;
