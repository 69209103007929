import React, { useEffect, useState } from "react";
import "../assets/css/addressDetails.css";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Form, Row, Col, Button } from "react-bootstrap";
import { baseUrl } from "../utils/baseUrl";
// import moment from "moment/moment";

const AddressDetails = () => {
  const token = sessionStorage.getItem("token");
  const { clientId } = useParams();
  const { state } = useLocation();
  const {
    productName,
    productPrice,
    productImage,
    metalType,
    productId,
    quantity,
    weight,
  } = state || {};
  console.log(state, "state>>>>>>>>>>");

  const [currentStep, setCurrentStep] = useState(1);
  const [billingInfo, setBillingInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    dateOfBirth: "",
    mobileNumber: "",
    panCardNumber: "",
    customerAddress: "",
    landMark: "",
    stateName: "",
    cityName: "",
    postalCode: "",
  });
  const [shippingInfo, setShippingInfo] = useState({
    customerAddress: "",
    landMark: "",
    stateName: "",
    cityName: "",
    postalCode: "",
  });
  const [copyBilling, setCopyBilling] = useState(false);
  const [invalidFields, setInvalidFields] = useState({});
  const [clientList, setClientList] = useState([]);
  const [clientCreatedDate, setClientCreatedDate] = useState("");
  const [stateData, setStateData] = useState([0]);
  const [citiesData, setCitiesData] = useState([]);
  const [stateId, setStateId] = useState("");
  const [selectedStateName, setSelectedStateName] = useState("");
  const navigate = useNavigate();

  const handleBillingChange = (e) => {
    const { name, value } = e.target;
    setBillingInfo({ ...billingInfo, [name]: value });
    if (e.target.name == "stateName") {
      setStateId(e.target.value);
    }
  };
  useEffect(() => {
    if (stateId) {
      fetchCity(stateId);
    }
  }, [stateId]);

  const handleShippingChange = (e) => {
    const { name, value } = e.target;
    setShippingInfo({ ...shippingInfo, [name]: value });
  };

  const handleCopyBilling = (e) => {
    setCopyBilling(e.target.checked);
    if (e.target.checked) {
      console.log(billingInfo);
      setShippingInfo({
        customerAddress: billingInfo.customerAddress,
        landMark: billingInfo.landMark,
        stateName: billingInfo.stateName,
        cityName: billingInfo.cityName,
        postalCode: billingInfo.postalCode,
      });
    } else {
      setShippingInfo({
        customerAddress: "",
        landMark: "",
        stateName: "",
        cityName: "",
        postalCode: "",
      });
    }
  };

  const validateBillingInfo = () => {
    const {
      firstName,
      lastName,
      email,
      mobileNumber,
      panCardNumber,
      customerAddress,
      landMark,
      stateName,
      cityName,
      postalCode,
    } = billingInfo;

    let invalidFields = {};
    if (!firstName) invalidFields.firstName = true;
    if (!lastName) invalidFields.lastName = true;
    if (!email) invalidFields.email = true;
    if (!mobileNumber) invalidFields.mobileNumber = true;
    if (!panCardNumber) invalidFields.panCardNumber = true;
    if (!customerAddress) invalidFields.customerAddress = true;
    if (!landMark) invalidFields.landMark = true;
    if (!stateName) invalidFields.stateName = true;
    if (!cityName) invalidFields.cityName = true;
    if (!postalCode) invalidFields.postalCode = true;

    setInvalidFields(invalidFields);
    return Object.keys(invalidFields).length === 0;
  };

  const validateShippingInfo = () => {
    const { customerAddress, landMark, stateName, cityName, postalCode } =
      shippingInfo;

    let invalidFields = {};
    if (!customerAddress) invalidFields.shippingCustomerAddress = true;
    if (!landMark) invalidFields.shippingLandMark = true;
    if (!stateName) invalidFields.shippingStateName = true;
    if (!cityName) invalidFields.shippingCityName = true;
    if (!postalCode) invalidFields.shippingPostalCode = true;

    setInvalidFields(invalidFields);
    return Object.keys(invalidFields).length === 0;
  };

  const handleNextStep = () => {
    if (currentStep === 1 && validateBillingInfo()) {
      setCurrentStep(currentStep + 1);
    } else if (currentStep === 2 && validateShippingInfo()) {
      setCurrentStep(currentStep + 1);
    } else if (currentStep === 3) {
      createOrder();
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    try {
      const url = `${baseUrl}/Client_List`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { headers });

      // Check if the status is 1 and show the popup
      const clientData = response.data.data;
      console.log("Client list=", clientData);

      const filteredClient = clientData.find(
        (client) => client._id === clientId
      );

      // if (filteredClient.status === 0 || filteredClient.status === 1) {
      //   Swal.fire({
      //     title: "Incomplete KYC",
      //     text: "Please complete your KYC.",
      //     icon: "warning",
      //     confirmButtonText: "Complete KYC",
      //     didClose: () => {
      //       navigate(`/complete-kyc/${clientId}/${productId}`);
      //     },
      //   });
      // }
      if (filteredClient) {
        setClientCreatedDate(filteredClient.createdAt);
        setBillingInfo({
          firstName: filteredClient.name.split(" ")[0],
          lastName: filteredClient.name.split(" ")[1],
          panCardNumber: filteredClient.PanNumber,
          postalCode: "",
          mobileNumber: filteredClient.mobileNo,
          email: filteredClient.Email,
          dob: filteredClient.dob,
          stateName: "",
          cityName: "",
        });
      } else {
        console.error(`No client found with id ${clientId}`);
      }
      setClientList(clientData);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  useEffect(() => {
    const fetchStates = async (stateId) => {
      try {
        const url = `${baseUrl}/augmontGold/states`;
        const response = await axios.get(url);
        const statesData = response.data.data;
        setStateData(statesData);
        console.log(stateId, "///////////////");
        const filteredState = statesData.filter((state) => state.id == stateId);

        if (filteredState) {
          setSelectedStateName(filteredState[0].name);
        }
      } catch (error) {
        console.error("Error connecting to API", error);
      }
    };

    fetchStates(stateId);
  }, [stateId]);

  const fetchCity = async (id) => {
    try {
      const url = `${baseUrl}/augmontGold/city/${id}`;
      const response = await axios.get(url);
      const cityData = response.data.data;

      setCitiesData(cityData);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const createOrder = async () => {
    const orderDetails = {
      productDetails: [
        {
          productId: String(productId),
          quantity: Number(quantity),
          paymentTypeId: 4,
          product_img: productImage,
          metal_type: metalType,
          productName: productName,
          weight: weight,
          finalOrderPrice: productPrice,
        },
      ],
      panCardNumber: billingInfo.panCardNumber,
      dateOfBirth: billingInfo.dob,
      mobileNumber: billingInfo.mobileNumber,
      firstName: billingInfo.firstName,
      lastName: billingInfo.lastName,
      email: billingInfo.email,
      customerAddress: billingInfo.customerAddress,
      landMark: billingInfo.landMark,
      postalCode: billingInfo.postalCode.toString(),
      stateName: selectedStateName,
      // reference: "kuch5353bhi",
      cityName: billingInfo.cityName,
      shippingAddress: shippingInfo.customerAddress,
      shippingLandMark: shippingInfo.landMark,
      shippingPostalCode: shippingInfo.postalCode.toString(),
      shippingStateName: selectedStateName,
      shippingCityName: shippingInfo.cityName,
    };

    console.log("Order Details:", orderDetails);

    try {
      const url = `${baseUrl}/emi/partner_portal/order/create/${clientId}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.post(url, orderDetails, { headers });
      console.log(response.data.data, "response data..................");

      if (response.status === 200) {
        const orderData = response.data.data[0];
        Swal.fire({
          title: "Order Created Successfully",
          text: `Order ID: ${orderData.order_no}`,
          icon: "success",
        });
        navigate("/spot-gold");
      }
    } catch (error) {
      if (error.response) {
        console.error("Error creating order:", error.response.data);
        Swal.fire({
          title: "Order Creation Failed",
          text: `Error: ${error.response.data.message || error.message}`,
          icon: "error",
        });
      } else {
        console.error("Error creating order:", error.message);
        Swal.fire({
          title: "Order Creation Failed",
          text: `Error: ${error.message}`,
          icon: "error",
        });
      }
    }
  };

  const getValidationClass = (field) => {
    return invalidFields[field] ? "is-invalid" : "";
  };

  return (
    <div className="" style={{ width: "100%", padding: "1rem" }}>
      <div className="row">
        <aside className="col-lg-6">
          <div className="border rounded-4 mb-3 d-flex justify-content-center">
            <img
              style={{
                maxWidth: "70%",
                maxHeight: "100vh",
                margin: "auto",
              }}
              className="rounded-4 fit"
              src={productImage}
              alt={productName}
            />
          </div>
          <div className="border rounded-4 mb-3 d-flex justify-content-center">
            <div className="row p-2">
              <dt className="col-4">Product:</dt>
              <dd className="col-8">{productName}</dd>
              <dt className="col-4">Price:</dt>
              <dd className="col-8">₹&nbsp;{productPrice}</dd>
              <dt className="col-4">Quantity:</dt>
              <dd className="col-8">{quantity}</dd>
              <dt className="col-4">Metal Type:</dt>
              <dd className="col-8">{metalType}</dd>
            </div>
          </div>
        </aside>
        <main className="col-lg-6">
          <div className="checkout-container">
            <div className="header">
              <button
                className={`step-button ${currentStep === 1 ? "active" : ""}`}
                onClick={() => setCurrentStep(1)}
                disabled={currentStep < 1}
                style={{ borderRadius: "8px" }}
              >
                Billing Info
              </button>
              <button
                className={`step-button ${currentStep === 2 ? "active" : ""}`}
                onClick={() => setCurrentStep(2)}
                disabled={currentStep < 2}
                style={{ borderRadius: "8px" }}
              >
                Shipping Info
              </button>
              <button
                className={`step-button ${currentStep === 3 ? "active" : ""}`}
                onClick={() => setCurrentStep(3)}
                disabled={currentStep < 3}
                style={{ borderRadius: "8px" }}
              >
                Payment
              </button>
            </div>

            {currentStep === 1 && (
              <div className="form-container">
                <Form>
                  <Row className="row-bottom">
                    <Col md={6}>
                      <Form.Group controlId="formfirstName">
                        <Form.Label>First Name</Form.Label>

                        <Form.Control
                          type="text"
                          name="firstName"
                          value={billingInfo.firstName}
                          onChange={handleBillingChange}
                          className={getValidationClass("firstName")}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter your first name.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formlastName">
                        <Form.Label>Last Name</Form.Label>

                        <Form.Control
                          type="text"
                          name="lastName"
                          value={billingInfo.lastName}
                          onChange={handleBillingChange}
                          className={getValidationClass("lastName")}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter your last name.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="row-bottom">
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          disabled
                          value={billingInfo.email}
                          onChange={handleBillingChange}
                          className={getValidationClass("email")}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid email address.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Mobile Number</Form.Label>
                        <Form.Control
                          type="text"
                          name="mobileNumber"
                          value={billingInfo.mobileNumber}
                          onChange={handleBillingChange}
                          className={getValidationClass("mobileNumber")}
                          required
                          pattern="\d{10}"
                          maxLength={10}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter your mobile number.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="row-bottom">
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>PAN Card Number</Form.Label>
                        <Form.Control
                          type="text"
                          name="panCardNumber"
                          value={billingInfo.panCardNumber}
                          onChange={handleBillingChange}
                          className={getValidationClass("panCardNumber")}
                          required
                          pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}"
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter your PAN card number.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Date Of Birth</Form.Label>
                        <Form.Control
                          type="date"
                          name="dob"
                          value={billingInfo.dob}
                          onChange={handleBillingChange}
                          className={getValidationClass("dob")}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter your address.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="row-bottom">
                    <Col md={12}>
                      <Form.Group>
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          type="text"
                          name="customerAddress"
                          value={billingInfo.customerAddress}
                          onChange={handleBillingChange}
                          className={getValidationClass("customerAddress")}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter your address.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="row-bottom">
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Landmark</Form.Label>

                        <Form.Control
                          type="text"
                          name="landMark"
                          value={billingInfo.landMark}
                          onChange={handleBillingChange}
                          className={getValidationClass("landMark")}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a landmark.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          as="select"
                          name="stateName"
                          value={billingInfo.stateName}
                          onChange={handleBillingChange}
                        >
                          <option value="">Select State</option>
                          {stateData.map((val, i) => (
                            <option key={i} value={val.id}>
                              {val.name}
                            </option>
                          ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please enter your state.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="row-bottom">
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>City</Form.Label>

                        <Form.Control
                          as="select"
                          name="cityName" // Add name attribute here
                          value={billingInfo.cityName} // Controlled component value
                          onChange={handleBillingChange} // Handle change event
                        >
                          <option value="">Select City</option>
                          {citiesData.map((val) => (
                            <option key={val.id} value={val.name}>
                              {val.name}
                            </option>
                          ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please enter your city.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Postal Code</Form.Label>

                        <Form.Control
                          type="text"
                          name="postalCode"
                          value={billingInfo.postalCode}
                          onChange={handleBillingChange}
                          className={getValidationClass("postalCode")}
                          maxLength={6}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter your postal code.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>

                <Row className="row-bottom">
                  <Col md={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      <button
                        className="step-button"
                        style={{
                          borderRadius: "8px",
                          backgroundColor: "#4a148c",
                          color: "white",
                        }}
                        onClick={handleNextStep}
                      >
                        Continue
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
            {currentStep === 2 && (
              <div className="form-container">
                <Form>
                  <Row className="row-bottom">
                    <Col md={12}>
                      <Form.Group className="checkbox-custom">
                        <Form.Check
                          type="checkbox"
                          id="copyBilling"
                          label="&nbsp; Same as Shipping Address"
                          checked={copyBilling}
                          onChange={handleCopyBilling}
                          custom
                          style={{ padding: "0" }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="row-bottom">
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Address</Form.Label>

                        <Form.Control
                          type="text"
                          name="customerAddress"
                          value={shippingInfo.customerAddress}
                          onChange={handleShippingChange}
                          className={getValidationClass(
                            "shippingCustomerAddress"
                          )}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter your shipping address.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Landmark</Form.Label>

                        <Form.Control
                          type="text"
                          name="landMark"
                          value={shippingInfo.landMark}
                          onChange={handleShippingChange}
                          className={getValidationClass("shippingLandMark")}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a shipping landmark.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="row-bottom">
                    <Col md={6}>
                      <Form.Group controlId="formShippingState">
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          as="select"
                          name="stateName"
                          value={shippingInfo.stateName}
                          onChange={handleShippingChange}
                        >
                          <option value="">Select State</option>
                          {stateData.map((val) => (
                            <option key={val.id} value={val.id || val.name}>
                              {val.name}
                            </option>
                          ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please enter your state.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formShippingCity">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          as="select"
                          name="cityName"
                          value={shippingInfo.cityName}
                          onChange={handleShippingChange}
                        >
                          <option value="">Select City</option>
                          {citiesData.map((val) => (
                            <option key={val.id} value={val.name}>
                              {val.name}
                            </option>
                          ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please enter your city.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="row-bottom">
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Postal Code</Form.Label>

                        <Form.Control
                          type="text"
                          name="postalCode"
                          value={shippingInfo.postalCode}
                          onChange={handleShippingChange}
                          className={getValidationClass("shippingPostalCode")}
                          maxLength={6}
                          pattern="\d{6}"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter your shipping postal code.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <Button
                    style={{
                      backgroundColor: "#4a2a7d",
                      border: "none",
                      width: "10rem",
                      height: "45px",
                    }}
                    onClick={handleNextStep}
                  >
                    Next
                  </Button>
                </div>
              </div>
            )}
            {currentStep === 3 && (
              <div className="payment-container">
                <div className="border rounded-4 mb-3">
                  <h4 style={{ display: "flex", justifyContent: "center" }}>
                    Billing Address
                  </h4>
                  <div className="row p-2">
                    <dt className="col-4">Name:</dt>
                    <dd className="col-8">
                      {billingInfo.firstName}&nbsp;
                      {billingInfo.lastName}
                    </dd>
                    <dt className="col-4">Address:</dt>
                    <dd className="col-8">{billingInfo.customerAddress}</dd>
                    <dt className="col-4">Landmark:</dt>
                    <dd className="col-8">{billingInfo.landMark}</dd>
                    <dt className="col-4">City:</dt>
                    <dd className="col-8">{billingInfo.cityName}</dd>
                    <dt className="col-4">State:</dt>
                    <dd className="col-8">{selectedStateName}</dd>
                    <dt className="col-4">Pin Code:</dt>
                    <dd className="col-8">{shippingInfo.postalCode}</dd>
                  </div>
                </div>

                {copyBilling && (
                  <>
                    <div className="border rounded-4 mb-3">
                      <h4 style={{ display: "flex", justifyContent: "center" }}>
                        Shipping Info (Same as Billing Info)
                      </h4>
                      <div className="row p-2">
                        <dt className="col-4">Address:</dt>
                        <dd className="col-8">
                          {shippingInfo.customerAddress}
                        </dd>
                        <dt className="col-4">Landmark:</dt>
                        <dd className="col-8">{shippingInfo.landMark}</dd>
                        <dt className="col-4">City:</dt>
                        <dd className="col-8">{shippingInfo.cityName}</dd>
                        <dt className="col-4">State:</dt>
                        <dd className="col-8">{selectedStateName}</dd>
                        <dt className="col-4">Pin Code:</dt>
                        <dd className="col-8">{shippingInfo.postalCode}</dd>
                      </div>
                    </div>
                  </>
                )}

                {!copyBilling && (
                  <>
                    <div className="border rounded-4 mb-3">
                      <h4 style={{ display: "flex", justifyContent: "center" }}>
                        Shipping Info
                      </h4>
                      <div className="row p-2">
                        <dt className="col-4">Address:</dt>
                        <dd className="col-8">
                          {shippingInfo.customerAddress}
                        </dd>
                        <dt className="col-4">Landmark:</dt>
                        <dd className="col-8">{shippingInfo.landMark}</dd>
                        <dt className="col-4">City:</dt>
                        <dd className="col-8">{shippingInfo.cityName}</dd>
                        <dt className="col-4">State:</dt>
                        <dd className="col-8">{selectedStateName}</dd>
                        <dt className="col-4">Pin Code:</dt>
                        <dd className="col-8">{shippingInfo.postalCode}</dd>
                      </div>
                    </div>
                  </>
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <Button variant="success" onClick={createOrder}>
                    Create Order
                  </Button>
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default AddressDetails;
