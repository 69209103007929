import React, { useEffect, useState } from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import "../assets/css/header.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import "../assets/css/signup.css";
import Ci from "../assets/images/Curated Icons.png";
import Cd from "../assets/images/Completely digitalised.png";
import Bs from "../assets/images/Bank Grade Security.png";
import Hc from "../assets/images/Help Center.png";
import image1 from "../assets/images/1.png";
import image2 from "../assets/images/2.png";
import image3 from "../assets/images/3.png";
import NavBar1 from "../components/Navbar";
import { baseUrl } from "../utils/baseUrl";

const Login = () => {
  const navigate = useNavigate();

  const [emailOrUsername, setEmailOrUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ emailOrUsername: "", password: "" });
  const [authError, setAuthError] = useState(""); // State for authentication error

  const isEmail = (input) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(input);
  };

  const validate = () => {
    let emailError = "";
    let passwordError = "";

    if (!emailOrUsername) {
      emailError = "Email/Username is required";
    } else if (!isEmail(emailOrUsername) && emailOrUsername.length < 3) {
      emailError = "Username must be at least 3 characters long";
    }

    if (!password) {
      passwordError = "Password is required";
    } else if (password.length < 6) {
      passwordError = "Password must be at least 6 characters long";
    }

    if (emailError || passwordError) {
      setErrors({ emailOrUsername: emailError, password: passwordError });
      return false;
    }

    return true;
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const isValid = validate();
    if (!isValid) return;

    let loginIdentifier = emailOrUsername;
    if (isEmail(emailOrUsername)) {
      loginIdentifier = emailOrUsername.toLowerCase();
    } else {
      loginIdentifier = emailOrUsername.toUpperCase();
    }

    try {
      const response = await axios.post(
        `${baseUrl}/login`,
        {
          email: loginIdentifier,
          password: password,
        },
        {
          withCredentials: true,
        }
      );

      const { auth, message, token } = response.data;

      if (auth) {
        sessionStorage.setItem("token", token);
        sessionStorage.setItem("message", message);
        if (
          message === "partner login successful" ||
          message === "employee login successful"
        ) {
          navigate("/");
        }
      } else {
        setAuthError(message || "Authentication failed"); // Set authentication error
      }
    } catch (error) {
      setAuthError("Wrong Credentials. Please try again."); // Set authentication error
    }
  };

  const token = sessionStorage.getItem("token");

  useEffect(() => {
    if (token) {
      handleShow();
    }
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <NavBar1 />
      <Container fluid className="otp-form-container">
        <Row
          className="d-flex align-items-center justify-content-center vh-100"
          style={{ marginTop: "1.5rem" }}
        >
          <Col
            xl={5}
            lg={5}
            md={12}
            sm={12}
            className="text-center bg-light-primary full-height"
          >
            <Carousel
              controls={false}
              indicators={false}
              interval={3000}
              pause={false}
              className="mt-xl-10 mt-lg-5 mt-md-3 mt-sm-1"
            >
              <Carousel.Item>
                <img
                  className="d-block w-100 img-height"
                  src={image1}
                  alt="First slide"
                  style={{ marginTop: "10rem" }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100 img-height"
                  src={image2}
                  alt="Second slide"
                  style={{ marginTop: "10rem" }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100 img-height"
                  src={image3}
                  alt="Third slide"
                  style={{ marginTop: "10rem" }}
                />
              </Carousel.Item>
            </Carousel>
          </Col>
          <Col xl={7} lg={7} md={12} sm={12}>
            <div
              className="otp-form d-flex flex-column justify-content-center"
              style={{ height: "80vh" }}
            >
              <div
                className="form-center"
                style={{ marginTop: "11rem", marginBottom: "3rem" }}
              >
                <h2>Get Started</h2>
                <p>Enter your email or username to login your account.</p>
                <div
                  className="p-4 mx-auto"
                  style={{ width: "100%", maxWidth: "24rem" }}
                >
                  <form onSubmit={submitHandler}>
                    <div className="mb-3">
                      <input
                        style={{
                          border: "2px solid #4a2a7d",
                          borderRadius: "8px",
                          fontSize: "15px",
                          width: "100%",
                        }}
                        type="text"
                        placeholder="Enter email/username"
                        value={emailOrUsername}
                        onChange={(e) => setEmailOrUsername(e.target.value)}
                        id="name"
                      />
                      {errors.emailOrUsername && (
                        <div className="text-danger">
                          {errors.emailOrUsername}
                        </div>
                      )}
                    </div>
                    <div className="mb-3">
                      <input
                        style={{
                          border: "2px solid #4a2a7d",
                          borderRadius: "8px",
                          fontSize: "15px",
                          width: "100%",
                        }}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        placeholder="Password"
                        id="password"
                      />
                      {errors.password && (
                        <div className="text-danger">{errors.password}</div>
                      )}
                    </div>
                    {authError && (
                      <div className="text-danger mb-3">{authError}</div>
                    )}
                    <p
                      style={{
                        marginTop: "1rem",
                        fontSize: "15px",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <Link to="/forgotpassword" className="text-color">
                        Forgot Password?
                      </Link>
                    </p>
                    <button
                      className="btn py-2 w-100"
                      style={{
                        backgroundColor: "#4a2a7d",
                        color: "white",
                        borderRadius: "30px",
                      }}
                    >
                      Login
                    </button>
                  </form>
                </div>
                <Row className="features">
                  <Col
                    xs={6}
                    sm={6}
                    md={3}
                    className="d-flex justify-content-center mb-3 mb-md-0"
                  >
                    <img
                      src={Ci}
                      className="bottom-logo img-fluid"
                      alt="Curated Investing"
                    />
                  </Col>
                  <Col
                    xs={6}
                    sm={6}
                    md={3}
                    className="d-flex justify-content-center mb-3 mb-md-0"
                  >
                    <img
                      src={Cd}
                      className="bottom-logo img-fluid"
                      alt="Completely Digitalised"
                    />
                  </Col>
                  <Col
                    xs={6}
                    sm={6}
                    md={3}
                    className="d-flex justify-content-center mb-3 mb-md-0"
                  >
                    <img
                      src={Bs}
                      className="bottom-logo img-fluid"
                      alt="Bank Grade Security"
                    />
                  </Col>
                  <Col
                    xs={6}
                    sm={6}
                    md={3}
                    className="d-flex justify-content-center mb-3 mb-md-0"
                  >
                    <img
                      src={Hc}
                      className="bottom-logo img-fluid"
                      alt="Help Center"
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
