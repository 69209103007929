import React from "react";
import NavBar1 from "../../components/Navbar";

const Contact = () => {
  return (
    <>
      <NavBar1 />
      <div>Contact</div>
    </>
  );
};

export default Contact;
