import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box, IconButton, TextField } from "@mui/material";
import { Form, Row, Col } from "react-bootstrap";
import Button from "@mui/material/Button";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { baseUrl } from "../../utils/baseUrl";
import Modal from "react-modal";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import TableSkeleton from "../../utils/TableSkeleton";

const columnHelper = createMRTColumnHelper();

const columns = [
  columnHelper.accessor("MerchantOrder_id", {
    header: "Order Number",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>
        {cell.row.original.MerchantOrder_id}
      </div>
    ),
  }),
  columnHelper.accessor("client_name", {
    header: "Client",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>{cell.row.original.client_name}</div>
    ),
  }),
  columnHelper.accessor("metal_type", {
    header: "Metal Type",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>{cell.row.original.metal_type}</div>
    ),
  }),
  columnHelper.accessor("status", {
    header: "Status",
    size: 40,
    Cell: (cell) => (
      <span
        style={{
          backgroundColor: "rgb(74 42 125)",
          padding: "7px 14px",
          borderRadius: "12px",
          fontSize: "13px",
          color: "white",
        }}
      >
        {cell.row.original.status}
      </span>
    ),
  }),
  columnHelper.accessor("amount", {
    header: "Amount",
    size: 40,
    Cell: (cell) => "₹ " + cell.row.original.orderdetails[0].finalOrderPrice,
  }),
  columnHelper.accessor("createdAt", {
    header: "Created At",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>
        {moment(cell.row.original.updatedAt.split("T")[0]).format("DD-MM-YYYY")}
      </div>
    ),
  }),
];

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

const OrderHistoryTable = () => {
  const Token = sessionStorage.getItem("token");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [orderHistory, setOrderHistory] = useState([]);
  const [cancelDetails, setCancelDetails] = useState({
    reason: "",
    customerName: "",
    customerAccountNo: "",
    ifscCode: "",
  });
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [bankDetails, setBankDetails] = useState("");
  const [orderDetails, setOrderDetails] = useState({});

  console.log(bankDetails, "client data id ????????????");

  useEffect(() => {
    getOrderHistory();
  }, []);

  const getOrderHistory = async () => {
    try {
      const url = `${baseUrl}/emi/partner_portal/getOrderSummary`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      const spotgold = response.data.data.ExecutedOrder;
      console.log(
        "spotGold ordered History getOrederedSummary",
        response.data.data.ExecutedOrder
      );
      setLoading(false);
      setOrderHistory(spotgold);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const has24HoursPassed = (updatedAt) => {
    const orderTime = new Date(updatedAt);
    const currentTime = new Date();
    const timeDifference = currentTime - orderTime;
    const hoursPassed = timeDifference / (1000 * 60 * 60);
    return hoursPassed >= 24;
  };

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => ({
      MerchantOrder_id: row.original.MerchantOrder_id,
      client_name: row.original.client_name,
      metalType: row.original.metalType,
      amount: row.original.orderdetails[0].finalOrderPrice,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const rowData = orderHistory.map((data) => ({
      MerchantOrder_id: data.MerchantOrder_id,
      client_name: data.client_name,
      metal_type: data.metal_type,
      amount: data.orderdetails[0].finalOrderPrice,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data: orderHistory,
    enableRowSelection: true,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    enableRowActions: true,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },
    renderRowActions: ({ row }) => [
      <Box key={row.id}>
        <IconButton
          onClick={() =>
            handleOpenDialog(
              row.original.MerchantOrder_id,
              row.original.client_id
            )
          }
          style={{
            backgroundColor: has24HoursPassed(row.original.updatedAt)
              ? "gray"
              : "rgb(74 42 125)",
            padding: "7px 14px",
            borderRadius: "12px",
            fontSize: "13px",
            color: "white",
          }}
          disabled={has24HoursPassed(row.original.updatedAt)}
        >
          Cancel
        </IconButton>
      </Box>,
    ],
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          style={{ backgroundColor: "none" }}
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });

  const handleOpenDialog = (MerchantOrder_id, clientId) => {
    setSelectedOrderId(MerchantOrder_id);
    // setCreateModalIsOpen(true);
    cancelOrderDetails(MerchantOrder_id, clientId);
    localStorage.setItem("clientId", clientId);
  };

  const closeCreateModal = () => {
    setCreateModalIsOpen(false);
    setSelectedOrderId(null);
    setCancelDetails({
      reason: "",
      customerName: "",
      customerAccountNo: "",
      ifscCode: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCancelDetails((prev) => ({ ...prev, [name]: value }));
  };

  const cancelOrderDetails = async (MerchantOrder_id, clientId) => {
    try {
      const url = `${baseUrl}/emi/partner_portal/cancelOrder/${MerchantOrder_id}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      setBankDetails(response.data.data[2].bankDetails);
      setOrderDetails(response.data.data[0].orderDetails);
      if (response.data.data[2].bankDetails == null) {
        Swal.fire({
          title: "Incomplete KYC",
          text: "Please complete your KYC.",
          icon: "warning",
          confirmButtonText: "Complete KYC",
          didClose: () => {
            navigate(`/complete-kyc/${clientId}`);
          },
        });

        return;
      }
      setCreateModalIsOpen(true);
    } catch (error) {
      console.error("Error fetching order details", error);
    }
  };

  const handleCancelOrder = async (selectedOrderId) => {
    try {
      const url = `${baseUrl}/emi/partner_portal/cancelMerchantOrder/${selectedOrderId}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const data = {
        reason: cancelDetails.reason,
        customerName: cancelDetails.customerName,
        customerAccountNo: cancelDetails.customerAccountNo,
        ifscCode: cancelDetails.ifscCode,
      };
      const response = await axios.put(url, data, { headers });

      closeCreateModal();
      if (response.data.error == false) {
        Swal.fire({
          title: "Cancelled!",
          text: "The order has been cancelled.",
          icon: "success",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.message,
        });
      }

      getOrderHistory();
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };
  return (
    <>
      {loading ? <TableSkeleton /> : <MaterialReactTable table={table} />}
      {createModalIsOpen && (
        <Modal
          isOpen={createModalIsOpen}
          onRequestClose={closeCreateModal}
          contentLabel="Cancel Order"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              width: "50%",
            },
          }}
        >
          <h3 className="text-center fw-bold form_header">Cancel Order</h3>
          <form>
            <div className="row">
              <div className="col">
                <Row className="row-bottom">
                  <Col md={6}>
                    <Form.Label
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <h5 style={{ fontSize: "16px" }}>Product :</h5>
                      <h5 style={{ fontSize: "16px" }}>
                        {orderDetails.product?.productName}
                      </h5>
                    </Form.Label>
                  </Col>
                  <Col md={6}>
                    <Form.Label
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <h5 style={{ fontSize: "16px" }}>Paid Amount : </h5>
                      <h5 style={{ fontSize: "16px" }}>
                        ₹&nbsp;
                        {orderDetails?.product?.productPrice[0]
                          ?.finalProductPrice -
                          orderDetails?.product?.productPrice[0]
                            ?.cancelationChargePer}
                      </h5>
                    </Form.Label>
                  </Col>
                </Row>
                <Row className="row-bottom">
                  <Col md={6}>
                    <Form.Label
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <h5 style={{ fontSize: "16px" }}>
                        Cancelation charge :{" "}
                      </h5>
                      <h5 style={{ fontSize: "16px" }}>
                        ₹&nbsp;
                        {
                          orderDetails?.product?.productPrice[0]
                            ?.cancelationChargePer
                        }
                      </h5>
                    </Form.Label>
                  </Col>
                  <Col md={6}>
                    <Form.Label
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <h5 style={{ fontSize: "16px" }}>Amount : </h5>
                      <h5 style={{ fontSize: "16px" }}>
                        ₹&nbsp;
                        {
                          orderDetails?.product?.productPrice[0]
                            ?.finalProductPrice
                        }
                      </h5>
                    </Form.Label>
                  </Col>
                </Row>
                <hr />
                <Row className="row-bottom">
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Reason</Form.Label>
                      <Form.Control
                        name="reason"
                        type="text"
                        required
                        value={cancelDetails.reason}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter a reason.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formCustomerName">
                      <Form.Label>Customer Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="customerName"
                        value={cancelDetails.customerName}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="row-bottom">
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Customer Account No.</Form.Label>
                      <Form.Control
                        name="customerAccountNo"
                        type="text"
                        value={cancelDetails.customerAccountNo}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter the account number.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formIFSCCode">
                      <Form.Label>IFSC Code</Form.Label>
                      <Form.Control
                        type="text"
                        name="ifscCode"
                        value={cancelDetails.ifscCode}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <div
                    className="text-center mt-3"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#4d16a4",
                        color: "white",
                        border: "none",
                      }}
                      onClick={closeCreateModal}
                      className="me-2"
                    >
                      Exit
                    </Button>
                    <Button
                      style={{
                        backgroundColor: "#4d16a4",
                        color: "white",
                        border: "none",
                      }}
                      onClick={() => handleCancelOrder(selectedOrderId)}
                    >
                      Cancel Order
                    </Button>
                  </div>
                </Row>
              </div>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
};

export default OrderHistoryTable;
