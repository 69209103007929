import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { Addclient } from "../redux/clientDataSlice";
import { IconButton } from "@mui/material";
import {
  Delete as DeleteIcon,
  GroupAdd as GroupAddIcon,
  RemoveRedEye,
} from "@mui/icons-material";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box } from "@mui/material";
import { mkConfig } from "export-to-csv";
import { baseUrl } from "../utils/baseUrl";

const columnHelper = createMRTColumnHelper();

const columns = [
  columnHelper.accessor("name", {
    header: "Client Name",
    size: 40,
  }),
  columnHelper.accessor("mobileNo", {
    header: "Mobile Number",
    size: 40,
  }),
];

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});
const CreateNewPurposal = () => {
  const [clientList, setClientList] = useState([]);
  const Token = sessionStorage.getItem("token");
  const [employees, setEmployees] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    FetchClientList();
  }, []);

  const table = useMaterialReactTable({
    columns,
    data: employees,
    enableRowSelection: false,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    enableRowActions: true,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },
    renderRowActions: ({ cell }) => [
      <Box>
        <Button
          type="button"
          variant="contained"
          onClick={() => addPorposal(cell.row.original)}
          style={{ textTransform: "none" }}
        >
          Place Order
        </Button>
      </Box>,
    ],
  });
  const FetchClientList = async () => {
    try {
      const url = `${baseUrl}/KycClientList`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      const clients = response.data.data;
      setClientList(clients);
      const employees = response.data.data;
      // setprogressData(response.data.data.PendingProposals);
      employees?.map((index, item) => {
        // console.log("Employee list=", employees.index.id);
        //  setclientCreatedDate(item.createdAt);

        // employees.set("slno",++item)
        index.slno = ++item;
      });

      setEmployees(employees);
      console.log("Client list=", clients);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const dispatch = useDispatch();

  const addPorposal = (client) => {
    console.log("client", client);
    if (client._id !== "") {
      dispatch(Addclient(client));
      navigate("/final_create", { state: { client } });
    }
  };

  return (
    <MaterialReactTable table={table} />
    // <div className="container-fluid p-2">
    //   <div className="row">
    //     <div className="col-md-12">
    //       <h4 className="fw-bold form_header">Clients List</h4>
    //     </div>
    //   </div>
    //   <div className="row">
    //     <div className="col-md-12">
    //       <Paper>
    //         <TableContainer>
    //           <Table>
    //             <TableHead sx={{ backgroundColor: "#F2F3F7", color: "black" }}>
    //               <TableRow>
    //                 <TableCell align="center">Sr.No</TableCell>
    //                 <TableCell align="center">Name</TableCell>
    //                 <TableCell align="center">Mobile No</TableCell>

    //                 <TableCell align="center">Add Proposal</TableCell>
    //               </TableRow>
    //             </TableHead>
    //             <TableBody>
    //               {/* =============================================================== */}

    //               {clientList &&
    //                 clientList.map((row, index) => (
    //                   <TableRow
    //                     key={row.name}
    //                     sx={{
    //                       "&:last-child td, &:last-child th": { border: 0 },
    //                       backgroundColor: "#ffff",
    //                     }}>
    //                     <TableCell align="center">{index + 1}</TableCell>
    //                     <TableCell align="center">{row.name}</TableCell>

    //                     <TableCell align="center">{row.mobileNo}</TableCell>

    //                     <TableCell align="center">
    //                       <Button type="button" variant="contained" onClick={()=>addPorposal(row)}>Add Proposal</Button>
    //                     </TableCell>
    //                   </TableRow>
    //                 ))}

    //               {/* ================================================================ */}
    //             </TableBody>
    //           </Table>
    //         </TableContainer>
    //         <TablePagination
    //           rowsPerPageOptions={[5, 10, 25]}
    //           component="div"
    //           count={15}
    //           rowsPerPage={rowsPerPage}
    //           page={page}
    //           onPageChange={handleChangePage}
    //           onRowsPerPageChange={handleChangeRowsPerPage}
    //         />
    //       </Paper>
    //     </div>
    //   </div>
    // </div>
  );
};

export default CreateNewPurposal;
