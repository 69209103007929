import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box, IconButton, TextField } from "@mui/material";
import { Form, Row, Col } from "react-bootstrap";
import Button from "@mui/material/Button";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { baseUrl } from "../../utils/baseUrl";
import Modal from "react-modal";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import TableSkeleton from "../../utils/TableSkeleton";

const columnHelper = createMRTColumnHelper();

const columns = [
  columnHelper.accessor("id", {
    header: "FD Id",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>{cell.row.original.data.id}</div>
    ),
  }),
  columnHelper.accessor("userAccountName", {
    header: "Client",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>
        {cell.row.original.data.userAccountName}
      </div>
    ),
  }),
  columnHelper.accessor("schemaName", {
    header: "Scheme Name",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>
        {cell.row.original.data.schemaName}
      </div>
    ),
  }),
  columnHelper.accessor("status", {
    header: "Status",
    size: 40,
    Cell: (cell) => (
      <span
        style={{
          backgroundColor: "rgb(74 42 125)",
          padding: "7px 14px",
          borderRadius: "12px",
          fontSize: "13px",
          color: "white",
        }}
      >
        {cell.row.original.data.status}
      </span>
    ),
  }),
  columnHelper.accessor("goldLease", {
    header: "Quantity (gm)",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>
        {cell.row.original.data.goldLease}gm
      </div>
    ),
  }),
  columnHelper.accessor("interestRate", {
    header: "Interest Rate (%)",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>
        {cell.row.original.data.interestRate}%
      </div>
    ),
  }),

  columnHelper.accessor("startDate", {
    header: "Start Date",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>
        {moment(cell.row.original.data.startDate).format("DD-MM-YYYY")}
      </div>
    ),
  }),
  columnHelper.accessor("endDate", {
    header: "End Date",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>
        {moment(cell.row.original.data.endDate).format("DD-MM-YYYY")}
      </div>
    ),
  }),
  columnHelper.accessor("closeDate", {
    header: "Close Date",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>
        {cell.row.original.data?.closeDate}
      </div>
    ),
  }),
];

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

const CancelledFd = () => {
  const Token = sessionStorage.getItem("token");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [closeFd, setCloseFd] = useState([]);

  useEffect(() => {
    getFdHistory();
  }, []);

  const getFdHistory = async () => {
    try {
      const url = `${baseUrl}/partnerPortal/fd/orderList`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      const closedFD = response.data.data?.FdClosed;
      console.log("Closed FD Data", response.data.data.FdClosed);
      setLoading(false);
      setCloseFd(closedFD);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => ({
      id: row.original.data.id,
      userAccountName: row.original.data.userAccountName,
      schemaName: row.original.data.schemaName,
      goldLease: row.original.data.goldLease,
      interestRate: row.original.data.interestRate,
      closeDate: row.original.data.closeDate,
      startDate: row.original.data.startDate,
      endDate: row.original.data.endDate,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const rowData = closeFd?.map((data) => ({
      id: data.data.id,
      userAccountName: data.data.userAccountName,
      schemaName: data.data.schemaName,
      goldLease: data.data.goldLease,
      interestRate: data.data.interestRate,
      closeDate: data.data.closeDate,
      startDate: data.data.startDate,
      endDate: data.data.endDate,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data: closeFd,
    enableRowSelection: true,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          style={{ backgroundColor: "none" }}
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });
  return (
    <>{loading ? <TableSkeleton /> : <MaterialReactTable table={table} />}</>
  );
};

export default CancelledFd;
