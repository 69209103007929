import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import moment from "moment/moment";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../utils/baseUrl";
import kycDone from "../assets/images/kycDone.png";

const KYCForm = () => {
  const token = sessionStorage.getItem("token");
  const location = useLocation();
  const clientStatus = location.state.status;
  const [isEditing, setIsEditing] = useState(false);

  const [stateData, setStateData] = useState([0]);
  const [citiesData, setCitiesData] = useState([]);
  const [stateId, setStateId] = useState("");
  const [singleStateId, setSingleStateId] = useState("");
  const [selectedStateName, setSelectedStateName] = useState("");

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    PanNumber: location.state.PanNumber,
    dob: location.state.dob,
    email: location.state.Email,
    name: location.state.name,
    mobileNumber: location.state.mobileNo,
    address_line_1: location.state.address_line_1,
    address_line_2: location.state.address_line_2,
    state: location.state.state,
    city: location.state.city,
    zip: location.state.zip,
    accountHolderName: location.state.name,
    bank_account_no: location.state.bank_account_no,
    confirmAccountNo: location.state.bank_account_no,
    ifsc_code: location.state.ifsc_code,
  });

  useEffect(() => {
    if (stateId) {
      fetchCity(stateId);
    }
  }, [stateId]);

  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (e.target.name == "stateName") {
      setStateId(e.target.value);
    }
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData.PanNumber) tempErrors.PanNumber = "PAN No. is required";
    if (!formData.dob) tempErrors.dob = "DOB is required";
    if (!formData.email) tempErrors.email = "Email is required";
    if (!formData.name) tempErrors.name = "Name is required";
    if (!formData.mobileNumber)
      tempErrors.mobileNumber = "Mobile No. is required";
    if (!formData.address_line_1)
      tempErrors.address_line_1 = "Address Line 1 is required";
    if (!formData.stateName) tempErrors.state = "State is required";
    if (!formData.cityName) tempErrors.city = "City is required";
    if (!formData.zip) tempErrors.zip = "PIN Code is required";
    if (!formData.accountHolderName)
      tempErrors.accountHolderName = "Account Holder's Name is required";
    if (!formData.bank_account_no)
      tempErrors.bank_account_no = "Account No. is required";
    if (formData.bank_account_no !== formData.confirmAccountNo)
      tempErrors.confirmAccountNo = "Account numbers do not match";
    if (!formData.ifsc_code) tempErrors.ifsc_code = "IFSC Code is required";

    setErrors(tempErrors);
    return "kkkkkkkkkkkk", Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("hiiiiiiiiiiiiiiiii");
    console.log("hi888888888888888", formData);
    if (validate()) {
      console.log("hi888888888888888", formData);
      kycApi();
    }
  };

  const kycApi = async () => {
    try {
      const url = `${baseUrl}/client_kyc/${location.state._id}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const payload = {
        PanNumber: formData.PanNumber,
        dob: formData.dob,
        address_line_1: formData.address_line_1,
        address_line_2: formData.address_line_2,
        state: selectedStateName || formData.state,
        mobileNumber: formData.mobileNumber,
        city: formData.cityName || formData.city,
        zip: formData.zip,
        bank_account_no: formData.bank_account_no,
        bank_account_name: formData.accountHolderName,
        ifsc_code: formData.ifsc_code,
      };

      console.log("object", payload);
      const response = await axios.post(url, payload, { headers });
      if (response.data.message == "User not created") {
        toast.error(response.data.err.pincode[0].message);
      } else {
        toast.success("KYC successful");
        navigate("/client_table");
      }

      setFormData({
        PanNumber: location.state.PanNumber,
        dob: location.state.dob,
        email: location.state.Email,
        name: location.state.name,
        mobileNumber: location.state.mobileNo,
        address_line_1: location.state.address_line_1,
        address_line_2: location.state.address_line_2,
        state: location.state.state,
        city: location.state.city,
        zip: location.state.zip,
        accountHolderName: location.state.name,
        bank_account_no: location.state.bank_account_no,
        confirmAccountNo: location.state.bank_account_no,
        ifsc_code: location.state.ifsc_code,
      });
    } catch (error) {
      console.log(error, "error>>>>>>>>>>");
      toast.error(error.response.data.message || error.message);
      console.error("Error connecting to API", error);
    }
  };

  useEffect(() => {
    const fetchStates = async (stateId) => {
      try {
        const url = `${baseUrl}/augmontGold/states`;
        const response = await axios.get(url);
        const statesData = response.data.data;
        setStateData(statesData);
        console.log(stateId, "///////////////");
        const filteredState = statesData.filter((state) => state.id == stateId);

        if (filteredState) {
          setSelectedStateName(filteredState[0].name);
        }
      } catch (error) {
        console.error("Error connecting to API", error);
      }
    };

    fetchStates(stateId);
  }, [stateId]);

  const fetchCity = async (id) => {
    console.log("llooooooooooooooooooooooo", id);
    try {
      const url = `${baseUrl}/augmontGold/city/${id}`;
      const response = await axios.get(url);
      const cityData = response.data.data;

      setCitiesData(cityData);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  return (
    <>
      <div className="registration-form" style={{ padding: "0rem" }}>
        <div className="col-lg-8 profile-bottom" style={{ width: "98%" }}>
          {!isEditing ? (
            <div
              className="profile-show"
              style={{
                padding: "1rem",
                border: "1px solid",
                borderRadius: "8px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h4 className="mb-4 center-text text-color">Complete KYC</h4>

                {clientStatus === 3 ? (
                  <div
                    style={{
                      width: "8rem",
                      height: "2.7rem",
                    }}
                  >
                    <img
                      src={kycDone}
                      alt=""
                      srcSet=""
                      style={{ marginTop: "-33px" }}
                    />
                  </div>
                ) : (
                  <Button
                    variant="contained"
                    className="btn btn-primary"
                    onClick={() => setIsEditing(true)}
                    style={{
                      textTransform: "capitalize",
                      height: "2.5rem",
                      marginBottom: "1rem",
                      width: "8rem",
                      height: "2.7rem",
                      backgroundColor: "#4a2a7d",
                      border: "none",
                    }}
                  >
                    Update KYC
                  </Button>
                )}
              </div>
              <h4
                className="mb-4 text-color"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#dcdaff",
                }}
              >
                PAN Details
              </h4>
              <div className="profile-text">
                <Row className="row-bottom">
                  <Col md={4}>
                    <Form.Group controlId="formPanNumber">
                      <Form.Label>PAN No.</Form.Label>
                      <h5 style={{ fontSize: "16px" }}>{formData.PanNumber}</h5>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formDob">
                      <Form.Label>DOB</Form.Label>
                      <h5 style={{ fontSize: "16px" }}>{formData.dob}</h5>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formEmail">
                      <Form.Label>Email</Form.Label>
                      <h5 style={{ fontSize: "16px" }}>{formData.email}</h5>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              <h4
                className="mb-4 text-color"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#dcdaff",
                }}
              >
                Personal Details
              </h4>
              <div className="profile-text">
                <Row className="row-bottom">
                  <Col md={4}>
                    <Form.Group controlId="formName">
                      <Form.Label>Name</Form.Label>
                      <h5 style={{ fontSize: "16px" }}>{formData.name}</h5>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formMobileNumber">
                      <Form.Label>Mobile No.</Form.Label>
                      <h5 style={{ fontSize: "16px" }}>
                        {formData.mobileNumber}
                      </h5>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formAddressLine1">
                      <Form.Label>Address Line 1</Form.Label>
                      <h5 style={{ fontSize: "16px" }}>
                        {formData.address_line_1}
                      </h5>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="row-bottom">
                  <Col md={4}>
                    <Form.Group controlId="formAddressLine2">
                      <Form.Label>Address Line 2</Form.Label>
                      <h5 style={{ fontSize: "16px" }}>
                        {formData.address_line_2}
                      </h5>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formState">
                      <Form.Label>State</Form.Label>
                      <h5 style={{ fontSize: "16px" }}>{formData.state}</h5>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formCity">
                      <Form.Label>City</Form.Label>
                      <h5 style={{ fontSize: "16px" }}>{formData.city}</h5>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formZip">
                      <Form.Label>PIN Code</Form.Label>
                      <h5 style={{ fontSize: "16px" }}>{formData.zip}</h5>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              <h4
                className="mb-4 text-color"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#dcdaff",
                }}
              >
                Bank Details
              </h4>
              <div className="profile-text">
                <Row className="row-bottom">
                  <Col md={4}>
                    <Form.Group controlId="formAccountHolderName">
                      <Form.Label>Account Holder Name</Form.Label>
                      <h5 style={{ fontSize: "16px" }}>
                        {formData.accountHolderName}
                      </h5>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formBankAccountNo">
                      <Form.Label>Bank Account No.</Form.Label>
                      <h5 style={{ fontSize: "16px" }}>
                        {formData.bank_account_no}
                      </h5>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formConfirmAccountNo">
                      <Form.Label>Confirm Account No.</Form.Label>
                      <h5 style={{ fontSize: "16px" }}>
                        {formData.confirmAccountNo}
                      </h5>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formIfscCode">
                      <Form.Label>IFSC Code</Form.Label>
                      <h5 style={{ fontSize: "16px" }}>{formData.ifsc_code}</h5>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </div>
          ) : (
            <div
              className="profile-edit"
              style={{
                padding: "1rem",
                border: "1px solid",
                borderRadius: "8px",
              }}
            >
              <Form onSubmit={handleSubmit}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h4 className="mb-4 center-text text-color">Complete KYC</h4>
                  <Button
                    variant="contained"
                    className="btn btn-primary"
                    onClick={() => setIsEditing(false)}
                    style={{
                      textTransform: "capitalize",
                      height: "2.5rem",
                      marginBottom: "1rem",
                      width: "8rem",
                      height: "2.7rem",
                      backgroundColor: "#4a2a7d",
                    }}
                  >
                    Cancel
                  </Button>
                </div>
                <h4
                  className="mb-4 text-color"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#dcdaff",
                  }}
                >
                  PAN Details
                </h4>
                <Row className="row-bottom">
                  <Col md={4}>
                    <Form.Group controlId="formPanNumber">
                      <Form.Label>PAN No.</Form.Label>
                      <Form.Control
                        type="text"
                        name="PanNumber"
                        value={formData.PanNumber}
                        onChange={handleChange}
                        isInvalid={!!errors.PanNumber}
                        maxLength={10}
                        pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.PanNumber}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  {/* {formData.dob ? (
                    <Col md={4}>
                      <Form.Group controlId="formDob">
                        <Form.Label>DOB</Form.Label>
                        <Form.Control
                          type="text"
                          name="dob"
                          value={formData.dob}
                          isInvalid={!!errors.dob}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.dob}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  ) : ( */}
                  <Col md={4}>
                    <Form.Group controlId="formDob">
                      <Form.Label>DOB</Form.Label>
                      <Form.Control
                        type="date"
                        name="dob"
                        value={formData.dob}
                        onChange={handleChange}
                        isInvalid={!!errors.dob}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.dob}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  {/* )} */}

                  <Col md={4}>
                    <Form.Group controlId="formEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        isInvalid={!!errors.email}
                        disabled={!!formData.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <h4
                  className="mb-4 text-color"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#dcdaff",
                  }}
                >
                  Personal Details
                </h4>
                <Row className="row-bottom">
                  <Col md={4}>
                    <Form.Group controlId="formName">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        isInvalid={!!errors.name}
                        disabled={!!formData.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formMobileNumber">
                      <Form.Label>Mobile No.</Form.Label>
                      <Form.Control
                        type="text"
                        name="mobileNumber"
                        value={formData.mobileNumber}
                        onChange={handleChange}
                        isInvalid={!!errors.mobileNumber}
                        disabled={!!formData.name}
                        maxLength={10}
                        pattern="\d{10}"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.mobileNumber}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formAddressLine1">
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        type="text"
                        name="address_line_1"
                        value={formData.address_line_1}
                        onChange={handleChange}
                        isInvalid={!!errors.address_line_1}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.address_line_1}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="row-bottom">
                  <Col md={4}>
                    <Form.Group controlId="formAddressLine2">
                      <Form.Label>Landmark</Form.Label>
                      <Form.Control
                        type="text"
                        name="address_line_2"
                        value={formData.address_line_2}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  {/* {formData.state ? (
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          name="stateName"
                          onChange={handleChange}
                          value={formData.state}
                          readOnly
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.stateName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  ) : ( */}
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        as="select"
                        name="stateName"
                        onChange={handleChange}
                        defaultValue={singleStateId}
                      >
                        <option value="">Select State</option>
                        {stateData.map((val, i) => (
                          <option key={i} value={val.id}>
                            {val.name}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.stateName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  {/* )} */}
                  {/* {formData.city ? (
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          name="city"
                          onChange={handleChange}
                          value={formData.city}
                          readOnly
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.city}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  ) : ( */}
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        as="select"
                        name="cityName"
                        onChange={handleChange}
                        defaultValue={formData.city}
                        className="form-control"
                      >
                        <option value="">Select City</option>
                        {citiesData.map((val) => (
                          <option key={val.id} value={val.name}>
                            {val.name}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.city}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  {/* )} */}

                  <Col md={4}>
                    <Form.Group controlId="formZip">
                      <Form.Label>PIN Code</Form.Label>
                      <Form.Control
                        type="text"
                        name="zip"
                        value={formData.zip}
                        maxLength={6}
                        pattern="\d{6}"
                        onChange={handleChange}
                        isInvalid={!!errors.zip}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.zip}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <h4
                  className="mb-4 text-color"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#dcdaff",
                  }}
                >
                  Bank Details
                </h4>
                <Row className="row-bottom">
                  <Col md={4}>
                    <Form.Group controlId="formAccountHolderName">
                      <Form.Label>Account Holder Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="accountHolderName"
                        value={formData.accountHolderName}
                        onChange={handleChange}
                        isInvalid={!!errors.accountHolderName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.accountHolderName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formBankAccountNo">
                      <Form.Label>Bank Account No.</Form.Label>
                      <Form.Control
                        type="text"
                        name="bank_account_no"
                        value={formData.bank_account_no}
                        onChange={handleChange}
                        isInvalid={!!errors.bank_account_no}
                        pattern="\d{9,18}"
                        maxLength={18}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.bank_account_no}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formConfirmAccountNo">
                      <Form.Label>Confirm Account No.</Form.Label>
                      <Form.Control
                        type="text"
                        name="confirmAccountNo"
                        value={formData.confirmAccountNo}
                        onChange={handleChange}
                        isInvalid={!!errors.confirmAccountNo}
                        pattern="\d{9,18}"
                        maxLength={18}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.confirmAccountNo}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formIfscCode">
                      <Form.Label>IFSC Code</Form.Label>
                      <Form.Control
                        type="text"
                        name="ifsc_code"
                        value={formData.ifsc_code}
                        onChange={handleChange}
                        isInvalid={!!errors.ifsc_code}
                        maxLength={11}
                        pattern="^[A-Za-z]{4}[a-zA-Z0-9]{7}$"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.ifsc_code}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <div className="text-end">
                  <Button
                    type="submit"
                    variant="contained"
                    className="btn btn-primary"
                    style={{
                      textTransform: "capitalize",
                      height: "2.5rem",
                      marginBottom: "1rem",
                      width: "8rem",
                      height: "2.7rem",
                      backgroundColor: "#4a2a7d",
                    }}
                  >
                    Update
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default KYCForm;

// import axios from "axios";
// import React, { useState } from "react";
// import { Form, Button, Row, Col } from "react-bootstrap";
// import {
//   CountrySelect,
//   StateSelect,
//   CitySelect,
// } from "react-country-state-city";
// import "react-country-state-city/dist/react-country-state-city.css";
// import { useLocation, useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { baseUrl } from "../utils/baseUrl";

// const KYCForm = () => {
//   const location = useLocation();
//   const [country, setCountryid] = useState(101);
//   const [state, setStateid] = useState(0);
//   const [city, setCityid] = useState(0);

//   const handleCountryChange = (country) => {
//     setCountryid(country.id);
//     setStateid(0);
//     setCityid(0);
//   };

//   const handleStateChange = (state) => {
//     setStateid(state.id);
//     formData.state = state.name;
//     setCityid(0); // Reset city selection when state changes
//   };

//   const handleCityChange = (city) => {
//     setCityid(city.id);
//     formData.city = city.name;
//   };

//   // console.log("location+++++++++++++++++++++",location);

//   const navigate = useNavigate();

//   const [formData, setFormData] = useState({
//     PanNumber: location.state.PanNumber,
//     dob: location.state.dob,
//     email: location.state.Email,
//     name: location.state.name,
//     mobileNumber: location.state.mobileNo,
//     address_line_1: location.state.address_line_1,
//     address_line_2: location.state.address_line_2,
//     state: location.state.state,
//     city: location.state.city,
//     zip: location.state.zip,
//     accountHolderName: location.state.name,
//     bank_account_no: location.state.bank_account_no,
//     confirmAccountNo: location.state.bank_account_no,
//     ifsc_code: location.state.ifsc_code,
//   });

//   const [errors, setErrors] = useState({});
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const validate = () => {
//     let tempErrors = {};
//     if (!formData.PanNumber) tempErrors.PanNumber = "PAN No. is required";
//     if (!formData.dob) tempErrors.dob = "DOB is required";
//     if (!formData.email) tempErrors.email = "Email is required";
//     if (!formData.name) tempErrors.name = "Name is required";
//     if (!formData.mobileNumber)
//       tempErrors.mobileNumber = "Mobile No. is required";
//     if (!formData.address_line_1)
//       tempErrors.address_line_1 = "Address Line 1 is required";
//     if (!formData.state) tempErrors.state = "State is required";
//     if (!formData.city) tempErrors.city = "City is required";
//     if (!formData.zip) tempErrors.zip = "PIN Code is required";
//     if (!formData.accountHolderName)
//       tempErrors.accountHolderName = "Account Holder's Name is required";
//     if (!formData.bank_account_no)
//       tempErrors.bank_account_no = "Account No. is required";
//     if (formData.bank_account_no !== formData.confirmAccountNo)
//       tempErrors.confirmAccountNo = "Account numbers do not match";
//     if (!formData.ifsc_code) tempErrors.ifsc_code = "IFSC Code is required";

//     setErrors(tempErrors);
//     return Object.keys(tempErrors).length === 0;
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (validate()) {
//       console.log("Form submitted successfully", formData);
//       kycApi();
//     }
//   };

//   const token = sessionStorage.getItem("token");

//   const kycApi = async () => {
//     try {
//       // const url = `${baseUrl}/client_kyc";
//       const url = `${baseUrl}/client_kyc/${location.state._id}`;
//       console.log("url++++++++++++++++++++++", url);
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${token}`,
//       };

//       const payload = {
//         PanNumber: formData.PanNumber,
//         // name: formData.name,
//         dob: formData.dob,
//         address_line_1: formData.address_line_1,
//         address_line_2: formData.address_line_2,
//         state: formData.state,
//         mobileNumber: formData.mobileNumber,
//         city: formData.city,
//         zip: formData.zip,
//         bank_account_no: formData.bank_account_no,
//         bank_account_name: formData.accountHolderName,
//         ifsc_code: formData.ifsc_code,
//       };

//       const response = await axios.post(url, payload, { headers });
//       toast.success("KYC successful");
//       console.log("response: ", response);

//       setFormData({
//         PanNumber: location.state.PanNumber,
//         dob: location.state.dob,
//         email: location.state.Email,
//         name: location.state.name,
//         mobileNumber: location.state.mobileNo,
//         address_line_1: location.state.address_line_1,
//         address_line_2: location.state.address_line_2,
//         state: location.state.state,
//         city: location.state.city,
//         zip: location.state.zip,
//         accountHolderName: location.state.name,
//         bank_account_no: location.state.bank_account_no,
//         confirmAccountNo: location.state.bank_account_name,
//         ifsc_code: location.state.ifsc_code,
//       });

//       navigate("/client_table");
//     } catch (error) {
//       toast.error("KYC failed");
//       console.error("Error connecting to API", error);
//     }
//   };

//   return (
//     <>
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <div className="card" style={{ width: "54rem" }}>
//           <div className="">
//             <div className="registration-form" style={{ padding: "0rem" }}>
//               <div
//                 style={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                 }}
//               >
//                 <h4 className="mb-4 center-text text-color">Complete KYC</h4>
//                 <div
//                   style={{
//                     display: "flex",
//                     justifyContent: "flex-end",
//                     alignItems: "center",
//                   }}
//                 >
//                   <Button
//                     variant="contained"
//                     className="btn btn-primary"
//                     // onClick={() => setIsEditing(true)}
//                     style={{
//                       textTransform: "capitalize",
//                       height: "2.5rem",
//                       marginBottom: "1rem",
//                       width: "8rem",
//                     }}
//                   >
//                     Update KYC
//                   </Button>
//                 </div>
//               </div>

//               <h4
//                 className="mb-4 center-text text-color"
//                 style={{
//                   width: "100%",
//                   display: "flex",
//                   justifyContent: "center",
//                   alignItems: "center",
//                   backgroundColor: "#dcdaff",
//                 }}
//               >
//                 PAN Details
//               </h4>
//               <Form onSubmit={handleSubmit}>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="formPanNumber">
//                       <Form.Label>PAN No.</Form.Label>
//                       <h5 style={{ fontSize: "16px" }}>{formData.PanNumber}</h5>
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formDob">
//                       <Form.Label>DOB</Form.Label>
//                       <h5 style={{ fontSize: "16px" }}>{formData.dob}</h5>
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formEmail">
//                       <Form.Label>Email</Form.Label>
//                       <h5 style={{ fontSize: "16px" }}>{formData.email}</h5>
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <div className="mt-4 text-center"></div>
//                 <h4
//                   className="mb-4 center-text text-color "
//                   style={{
//                     width: "100%",
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     backgroundColor: "#dcdaff",
//                   }}
//                 >
//                   Billing address
//                 </h4>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="formName">
//                       <Form.Label>Name</Form.Label>
//                       <h5 style={{ fontSize: "16px" }}>{formData.name}</h5>
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formPhone">
//                       <Form.Label>Mobile No.</Form.Label>
//                       <h5 style={{ fontSize: "16px" }}>
//                         {formData.mobileNumber}
//                       </h5>
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formAddress1">
//                       <Form.Label>Address Line 1</Form.Label>
//                       <h5 style={{ fontSize: "16px" }}>
//                         {formData.address_line_1}
//                       </h5>
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="formAddress2">
//                       <Form.Label>Address Line 2</Form.Label>
//                       <h5 style={{ fontSize: "16px" }}>
//                         {formData.address_line_2}
//                       </h5>
//                     </Form.Group>
//                   </Col>

//                   <Col md={4}>
//                     <Form.Group controlId="formState">
//                       <Form.Label>State</Form.Label>
//                       <h5 style={{ fontSize: "16px" }}>{formData.state}</h5>
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formCity">
//                       <Form.Label>City</Form.Label>
//                       <h5 style={{ fontSize: "16px" }}>{formData.city}</h5>
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="formPin">
//                       <Form.Label>PIN Code</Form.Label>
//                       <h5 style={{ fontSize: "16px" }}>{formData.zip}</h5>
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <div className="mt-4 text-center"></div>
//                 <h4
//                   className="mb-4 center-text text-color"
//                   style={{
//                     width: "100%",
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     backgroundColor: "#dcdaff",
//                   }}
//                 >
//                   Bank Detail
//                 </h4>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="accountHolderName">
//                       <Form.Label>Account Holder's Name</Form.Label>
//                       <h5 style={{ fontSize: "16px" }}>
//                         {formData.accountHolderName}
//                       </h5>
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="accountNo">
//                       <Form.Label>Account No.</Form.Label>
//                       <h5 style={{ fontSize: "16px" }}>
//                         {formData.bank_account_no}
//                       </h5>
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="confirmAccountNo">
//                       <Form.Label>Confirm Account No.</Form.Label>
//                       <h5 style={{ fontSize: "16px" }}>
//                         {formData.confirmAccountNo}
//                       </h5>
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="ifscCode">
//                       <Form.Label>IFSC Code</Form.Label>
//                       <h5 style={{ fontSize: "16px" }}>{formData.ifsc_code}</h5>
//                     </Form.Group>
//                   </Col>
//                 </Row>

//                <h4 className="mb-4 center-text text-color">PAN Details</h4>
//               <Form onSubmit={handleSubmit}>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="formPanNumber">
//                       <Form.Label>PAN No.</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter PAN No."
//                         name="PanNumber"
//                         value={formData.PanNumber}
//                         onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formDob">
//                       <Form.Label>DOB</Form.Label>
//                       <Form.Control
//                         type="date"
//                         placeholder="dd-mm-yyyy"
//                         name="dob"
//                         value={formData.dob}
//                         onChange={handleChange}
//                       />
//                       {errors.dob && (
//                         <small className="text-danger">{errors.dob}</small>
//                       )}
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formEmail">
//                       <Form.Label>Email</Form.Label>
//                       <Form.Control
//                         type="email"
//                         placeholder="Enter Email"
//                         name="email"
//                         value={formData.email}
//                         onChange={handleChange}
//                         disabled
//                       />
//                       {errors.email && (
//                         <small className="text-danger">{errors.email}</small>
//                       )}
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <div className="mt-4 text-center"></div>
//                 <h4 className="mb-4 center-text text-color ">
//                   Billing address
//                 </h4>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="formName">
//                       <Form.Label>Name</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter Full Name"
//                         name="name"
//                         value={formData.name}
//                         onChange={handleChange}
//                         disabled
//                       />
//                       {errors.name && (
//                         <small className="text-danger">{errors.name}</small>
//                       )}
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formPhone">
//                       <Form.Label>Mobile No.</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter Mobile No"
//                         name="mobileNumber"
//                         value={formData.mobileNumber}
//                         onChange={handleChange}
//                         disabled
//                       />
//                       {errors.mobileNumber && (
//                         <small className="text-danger">
//                           {errors.mobileNumber}
//                         </small>
//                       )}
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formAddress1">
//                       <Form.Label>Address Line 1</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter Your Address"
//                         name="address_line_1"
//                         value={formData.address_line_1}
//                         onChange={handleChange}
//                       />
//                       {errors.address_line_1 && (
//                         <small className="text-danger">
//                           {errors.address_line_1}
//                         </small>
//                       )}
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="formAddress2">
//                       <Form.Label>Address Line 2</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter Your Address"
//                         name="address_line_2"
//                         value={formData.address_line_2}
//                         onChange={handleChange}
//                       />
//                     </Form.Group>
//                   </Col>

//                   <Col md={4}>
//                     <Form.Group controlId="formState">
//                       <Form.Label>State</Form.Label>
//                       <StateSelect
//                         placeHolder={formData.state}
//                         countryid={country}
//                         onChange={(state) => handleStateChange(state)}
//                         value={formData.state}
//                         className="form-control"
//                       />
//                       {errors.state && (
//                         <small className="text-danger">{errors.state}</small>
//                       )}
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="formCity">
//                       <Form.Label>City</Form.Label>
//                       <CitySelect
//                         placeHolder={formData.city}
//                         countryid={country}
//                         stateid={state}
//                         onChange={(city) => handleCityChange(city)}
//                         value={formData.city}
//                         className="form-control"
//                       />
//                       {errors.city && (
//                         <small className="text-danger">{errors.city}</small>
//                       )}
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="formPin">
//                       <Form.Label>PIN Code</Form.Label>
//                       <Form.Control
//                         type="number"
//                         placeholder="Enter PIN Code"
//                         name="zip"
//                         value={formData.zip}
//                         onChange={handleChange}
//                       />
//                       {errors.zip && (
//                         <small className="text-danger">{errors.zip}</small>
//                       )}
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <div className="mt-4 text-center"></div>
//                 <h4 className="mb-4 center-text text-color">Bank Detail</h4>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="accountHolderName">
//                       <Form.Label>Account Holder's Name</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter Account Holder's Name"
//                         name="accountHolderName"
//                         value={formData.accountHolderName}
//                         onChange={handleChange}
//                         disabled
//                       />
//                       {errors.accountHolderName && (
//                         <small className="text-danger">
//                           {errors.accountHolderName}
//                         </small>
//                       )}
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="accountNo">
//                       <Form.Label>Account No.</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter Account No."
//                         name="bank_account_no"
//                         value={formData.bank_account_no}
//                         onChange={handleChange}
//                       />
//                       {errors.bank_account_no && (
//                         <small className="text-danger">
//                           {errors.bank_account_no}
//                         </small>
//                       )}
//                     </Form.Group>
//                   </Col>
//                   <Col md={4}>
//                     <Form.Group controlId="confirmAccountNo">
//                       <Form.Label>Confirm Account No.</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Confirm Account No."
//                         name="confirmAccountNo"
//                         value={formData.confirmAccountNo}
//                         onChange={handleChange}
//                       />
//                       {errors.confirmAccountNo && (
//                         <small className="text-danger">
//                           {errors.confirmAccountNo}
//                         </small>
//                       )}
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <Row className="row-bottom">
//                   <Col md={4}>
//                     <Form.Group controlId="ifscCode">
//                       <Form.Label>IFSC Code</Form.Label>
//                       <Form.Control
//                         type="text"
//                         placeholder="Enter IFSC Code"
//                         name="ifsc_code"
//                         value={formData.ifsc_code}
//                         onChange={handleChange}
//                       />
//                       {errors.ifsc_code && (
//                         <small className="text-danger">
//                           {errors.ifsc_code}
//                         </small>
//                       )}
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <div
//                   style={{
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     marginTop: "4rem",
//                   }}
//                 >
//                   <Button
//                     className="submit-btn"
//                     type="submit"
//                     style={{
//                       backgroundColor: "#4a2a7d",
//                       borderRadius: "30px",
//                       width: "20rem",
//                     }}
//                   >
//                     Submit
//                   </Button>
//                 </div>
//               </Form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default KYCForm;
