import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
// import 'rsuite/DateRangePicker/styles/index.css';
import "./comman.scss";
import Overview from "./pages/Overview";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
// import { BrowserRouter } from "react-router-dom";
import { useEffect } from "react";
import Client from "./pages/Client";
import Employee from "./pages/Employee";
import Purposal from "./pages/Purposal";
import CreateNewPurposal from "./pages/createNewPurposal";
import FinalCreate from "./pages/FinalCreate";
import AboutBatukPartener from "./pages/AboutBatukPartener";
import PersonalDetails from "./pages/PersonalDetails";
import ResetPassword from "./pages/ResetPassword";
import "sweetalert2/src/sweetalert2.scss";
import Header from "./components/Header";
import Earning from "./pages/Earnings";
import Report from "./pages/Report";
import DashBoard from "./pages/DashBoard";
import KycForm from "./pages/KYCForm";
import About from "./pages/PublicRoutes/About";
import Blogs from "./pages/PublicRoutes/Blogs";
import Faq from "./pages/PublicRoutes/Faq";
import Insights from "./pages/PublicRoutes/Insights";
import Contact from "./pages/PublicRoutes/Contact";
import IndiRegister from "./pages/IndiRegister";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import UploadFile from "./pages/UploadFile";
// import Spotgold from "./pages/Spotgold";
import ProductDetails from "./pages/ProductDetails";
import AddressDetails from "./pages/AddressDetails";
import SpotGold from "./pages/spotGold/SpotGold";
import CreateNewOrder from "./pages/spotGold/CreateNewOrder";
import Product from "./pages/spotGold/Product";
import Sip from "./pages/Sip/Sip";
import CreateNewSIP from "./pages/Sip/CreateNewSIP";
import FinalCreateSip from "./pages/Sip/FinalCreateSip";
import CompleteKYC from "./pages/CompleteKYC";
import StartFd from "./pages/fixed_deposit/StartFd";
import GoldFd from "./pages/fixed_deposit/GoldFd";
import CreateGoldFd from "./pages/fixed_deposit/CreateGoldFd";
import GoldFdDetails from "./pages/fixed_deposit/GoldFdDetails";

function App() {
  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const publicRoutes = [
      "/login",
      "/register",
      "/indRegister",
      "/about",
      "/blogs",
      "/faq",
      "/insights",
      "/contact",
      "/forgotpassword",
    ];
    const currentPath = location.pathname;

    if (!token && !publicRoutes.includes(currentPath)) {
      navigate("/login");
    }
  }, [token, location, navigate]);

  return (
    <>
      <Routes>
        <Route path="/login" element={<Header />} />
        <Route path="/about" element={<About />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/insights" element={<Insights />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/IndRegister" element={<IndiRegister />} />
        <Route path="/" element={<DashBoard />}>
          <Route index element={<Overview />} />
          <Route path="client_table" element={<Client />} />
          <Route
            path="/complete-kyc/:clientId/:productId"
            element={<CompleteKYC />}
          />
          <Route path="/complete-kyc/:clientId" element={<CompleteKYC />} />
          <Route path="/client_kyc" element={<KycForm />} />
          <Route path="employee_table" element={<Employee />} />
          <Route path="/sip" element={<Sip />} />
          <Route path="/gold-fd" element={<GoldFd />} />
          <Route path="/start-gold-fd" element={<StartFd />} />
          <Route path="/create-gold-fd/:clientId" element={<CreateGoldFd />} />
          <Route
            path="/create-gold-fd/:clientId/:id"
            element={<GoldFdDetails />}
          />

          <Route path="/proposal" element={<Purposal />} />
          <Route path="/create_proposal" element={<CreateNewPurposal />} />
          <Route path="/create_order" element={<CreateNewOrder />} />
          <Route path="/create-sip" element={<CreateNewSIP />} />
          <Route path="/final_create" element={<FinalCreate />} />
          <Route
            path="/final-create-sip/:clientId"
            element={<FinalCreateSip />}
          />
          <Route path="/profile" element={<PersonalDetails />} />
          <Route path="aboutbatukpartener" element={<AboutBatukPartener />} />
          <Route path="/personaldetails" element={<PersonalDetails />} />
          <Route path="/earning" element={<Earning />} />
          <Route path="/reports" element={<Report />} />
          <Route path="/upload-file" element={<UploadFile />} />
          <Route path="/spot-gold" element={<SpotGold />} />

          <Route path="/product/:clientId" element={<Product />} />

          <Route path="/product/:clientId/:id" element={<ProductDetails />} />
          <Route
            path="/product/:clientId/:id/address-details"
            element={<AddressDetails />}
          />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
