import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box, IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { baseUrl } from "../../utils/baseUrl";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import TableSkeleton from "../../utils/TableSkeleton";

const columnHelper = createMRTColumnHelper();

const columns = [
  columnHelper.accessor("id", {
    header: "FD Id",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>{cell.row.original.data.id}</div>
    ),
  }),
  columnHelper.accessor("userAccountName", {
    header: "Client",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>
        {cell.row.original.data.userAccountName}
      </div>
    ),
  }),
  columnHelper.accessor("schemaName", {
    header: "Scheme Name",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>
        {cell.row.original.data.schemaName}
      </div>
    ),
  }),
  columnHelper.accessor("status", {
    header: "Status",
    size: 40,
    Cell: (cell) => (
      <span
        style={{
          backgroundColor: "rgb(74 42 125)",
          padding: "7px 14px",
          borderRadius: "12px",
          fontSize: "13px",
          color: "white",
        }}
      >
        {cell.row.original.data.status}
      </span>
    ),
  }),
  columnHelper.accessor("goldLease", {
    header: "Quantity (gm)",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>
        {cell.row.original.data.goldLease}gm
      </div>
    ),
  }),
  columnHelper.accessor("interestRate", {
    header: "Interest Rate (%)",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>
        {cell.row.original.data.interestRate}%
      </div>
    ),
  }),

  columnHelper.accessor("startDate", {
    header: "Start Date",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>
        {moment(cell.row.original.data.startDate).format("DD-MM-YYYY")}
      </div>
    ),
  }),
  columnHelper.accessor("endDate", {
    header: "End Date",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>
        {moment(cell.row.original.data.endDate).format("DD-MM-YYYY")}
      </div>
    ),
  }),
];

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

const FdHistory = () => {
  const Token = sessionStorage.getItem("token");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [fdHistory, setFdHistory] = useState([]);
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);

  useEffect(() => {
    getFdHistory();
  }, []);

  const getFdHistory = async () => {
    try {
      const url = `${baseUrl}/partnerPortal/fd/orderList`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });

      // Log the response to understand its structure
      console.log("API Response:", response.data);

      const activeFD = response.data.data?.FdActive || [];

      // Check and log if transactionId exists in the expected path
      activeFD.forEach((fd) => {
        console.log("Transaction ID:", fd.data?.transactionId);
      });

      setLoading(false);
      setFdHistory(activeFD);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => ({
      id: row.original.data.id,
      userAccountName: row.original.data.userAccountName,
      schemaName: row.original.data.schemaName,
      goldLease: row.original.data.goldLease,
      interestRate: row.original.data.interestRate,
      startDate: row.original.data.startDate,
      endDate: row.original.data.endDate,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const rowData = fdHistory?.map((data) => ({
      id: data.data.id,
      userAccountName: data.data.userAccountName,
      schemaName: data.data.schemaName,
      goldLease: data.data.goldLease,
      interestRate: data.data.interestRate,
      startDate: data.data.startDate,
      endDate: data.data.endDate,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data: fdHistory,
    enableRowSelection: true,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    enableRowActions: true,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },
    renderRowActions: ({ row }) => [
      <Box key={row.id}>
        <IconButton
          onClick={() => {
            Swal.fire({
              title: "Are you sure?",
              text: "Are you sure you want to close this FD?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, close it!",
            }).then((result) => {
              if (result.isConfirmed) {
                handleCancelOrder(row.original.data.transactionId);
              }
            });
          }}
          style={{
            backgroundColor: "rgb(74 42 125)",
            width: "6rem",
            padding: "7px 14px",
            borderRadius: "12px",
            fontSize: "13px",
            color: "white",
          }}
        >
          Close FD
        </IconButton>
      </Box>,
    ],
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          style={{ backgroundColor: "none" }}
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });

  const handleCancelOrder = async (transactionId) => {
    try {
      const url = `${baseUrl}/partnerPortal/fd/preorderClose/${transactionId}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.put(url, {}, { headers });
      console.log("Order cancelled", response);

      Swal.fire("Closed!", "Your FD has been closed.", "success");

      // Refresh FD history after cancellation
      getFdHistory();
    } catch (error) {
      console.error("Error cancelling the order", error);
      Swal.fire("Error", "Could not cancel the FD. Please try again.", "error");
    }
  };

  return (
    <>
      <Box sx={{ margin: "2%" }}>
        {loading ? (
          <TableSkeleton columns={columns.length} />
        ) : (
          <MaterialReactTable table={table} />
        )}
      </Box>
    </>
  );
};

export default FdHistory;