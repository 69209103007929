import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box } from "@mui/material";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { baseUrl } from "../../utils/baseUrl";
import moment from "moment/moment";
import TableSkeleton from "../../utils/TableSkeleton";

const columnHelper = createMRTColumnHelper();

const columns = [
  columnHelper.accessor("MerchantOrder_id", {
    header: "Order Number",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>
        {cell.row.original.MerchantOrder_id}
      </div>
    ),
  }),
  columnHelper.accessor("client_name", {
    header: "Client",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>{cell.row.original.client_name}</div>
    ),
  }),
  columnHelper.accessor("metal_type", {
    header: "Metal Type",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>{cell.row.original.metal_type}</div>
    ),
  }),
  columnHelper.accessor("status", {
    header: "Status",
    size: 40,
    Cell: (cell) => (
      <span
        style={{
          backgroundColor: "rgb(74 42 125)",
          padding: " 7px 14px",
          borderRadius: "12px",
          fontSize: "13px",
          color: "white",
        }}
      >
        {cell.row.original.status}
      </span>
    ),
  }),
  columnHelper.accessor("amount", {
    header: "Amount",
    size: 40,
    Cell: (cell) => "₹ " + cell.row.original.orderdetails[0].finalOrderPrice,
  }),
  columnHelper.accessor("createdAt", {
    header: "Cancelled At",
    size: 40,
    Cell: (cell) => (
      <div style={{ textAlign: "center" }}>
        {moment(cell.row.original.createdAt.split("T")[0]).format("DD-MM-YYYY")}
      </div>
    ),
  }),
];

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});
const OrderCancelTable = () => {
  const Token = sessionStorage.getItem("token");
  const [loading, setLoading] = useState(true);
  const [orderCancel, setOrderCancel] = useState([]);
  useEffect(() => {
    getCancelAPI();
  }, []);

  const getCancelAPI = async () => {
    try {
      const url = `${baseUrl}/emi/partner_portal/getOrderSummary`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      console.log("spot gold In Progress Api data", response.data);
      const spotgold = response.data.data?.CancelledOrder;
      setLoading(false);
      setOrderCancel(spotgold);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => ({
      MerchantOrder_id: row.original.MerchantOrder_id,
      client_name: row.original.client_name,
      metalType: row.original.metalType,
      amount: row.original.rderdetails[0].finalOrderPrice,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const rowData = orderCancel.map((data) => ({
      MerchantOrder_id: data.MerchantOrder_id,
      client_name: data.client_name,
      metal_type: data.metal_type,
      amount: data.orderdetails[0].finalOrderPrice,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };
  const table = useMaterialReactTable({
    columns,
    data: orderCancel,
    enableRowSelection: true,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    enableRowActions: false,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          onClick={() => handleExportData(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>

        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });
  const deletePurposal = (row) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4A2A7D",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const url = `${baseUrl}/proposalDelete/${row._id}`;
          const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Token}`,
          };
          const response = await axios.delete(url, { headers });
          console.log("In excuteds update Api data", response);
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Your Purposal has been deleted.",
            icon: "success",
            customClass: {
              confirmButton: "my-custom-button-class",
            },
          });
          setOrderCancel();
        } catch (error) {
          console.error("Error connecting to API", error);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Your Purposal is safe",
          icon: "error",
        });
      }
    });
  };

  return (
    <>{loading ? <TableSkeleton /> : <MaterialReactTable table={table} />}</>
  );
};

export default OrderCancelTable;
