import React, { useEffect, useState } from "react";
import "../assets/css/prodetails.css";
import axios from "axios";
import { useParams, useNavigate, Link } from "react-router-dom";
import noImage from "../assets/images/no_image.png";
import { baseUrl } from "../utils/baseUrl";
import { Button } from "react-bootstrap";

const ProductDetails = () => {
  const { clientId, id } = useParams();

  const token = sessionStorage.getItem("token");
  const [productDetails, setProductDetails] = useState(null);
  const [category, setCategory] = useState("");
  const [quantityValue, setQuantityValue] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const navigate = useNavigate();

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (url) => {
    setSelectedImage(url);
  };

  useEffect(() => {
    const getProductDetails = async () => {
      try {
        const url = `${baseUrl}/emi/partner_portal/product/${id}`;
        const headers = {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        };
        const response = await axios.get(url, { headers });
        setCategory(response.data.data.subCategory.category);
        setProductDetails(response.data.data);
        console.log(
          response.data.data.productPrice[0].finalProductPrice,
          "product price>>>>>>>>>"
        );
        setTotalPrice(response.data.data.productPrice[0].finalProductPrice);
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };
    getProductDetails();
  }, [id, token]);

  const handleBuyNow = () => {
    navigate(`/product/${clientId}/${id}/address-details`, {
      state: {
        productName: productDetails.productName,
        productPrice: totalPrice,
        productImage: productDetails.productImage,
        metalType: category.metalType.metalType,
        productId: productDetails.id,
        quantity: quantityValue,
        weight: productDetails.weight,
      },
      
    });
  };

  const decreaseQuantity = () => {
    if (quantityValue > 1) {
      setQuantityValue(quantityValue - 1);
      setTotalPrice(
        (quantityValue - 1) * productDetails.productPrice[0].finalProductPrice
      );
    }
  };

  const increaseQuantity = () => {
    setQuantityValue(quantityValue + 1);
    setTotalPrice(
      (quantityValue + 1) * productDetails.productPrice[0].finalProductPrice
    );
  };

  if (!productDetails) {
    return <p>Loading product details...</p>;
  }
  return (
    <div className="" style={{ width: "100%", padding: "1rem" }}>
      <div className="row">
        <aside className="col-lg-6">
          {selectedImage ? (
            <div
              className="border rounded-4 mb-3 d-flex justify-content-center"
              style={{ height: "78%" }}
            >
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  margin: "auto",
                }}
                className="rounded-4 fit"
                src={selectedImage}
                alt="Selected"
              />
            </div>
          ) : (
            <div
              className="border rounded-4 mb-3 d-flex justify-content-center"
              style={{ height: "78%" }}
            >
              <Link
                data-fslightbox="mygalley"
                className="rounded-4"
                target="_blank"
                data-type="image"
                to={
                  productDetails.productImage == "0"
                    ? noImage
                    : productDetails.productImage
                }
              >
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    margin: "auto",
                  }}
                  className="rounded-4 fit"
                  src={
                    productDetails.productImage == "0"
                      ? noImage
                      : productDetails.productImage
                  }
                />
              </Link>
            </div>
          )}
          <div
            className=""
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="d-flex justify-content-center mb-3">
              <div data-fslightbox="mygalley" className="border mx-1 rounded-2">
                <img
                  width="60"
                  height="60"
                  className="rounded-2"
                  src={
                    productDetails.productImage == "0"
                      ? noImage
                      : productDetails.productImage
                  }
                />
              </div>
            </div>
            {productDetails?.productImages?.map((url, i) => (
              <div className="d-flex justify-content-center mb-3" key={i}>
                <div
                  data-fslightbox="mygalley"
                  className="border mx-1 rounded-2"
                >
                  <img
                    width="60"
                    height="60"
                    className="rounded-2"
                    src={url.URL}
                    onClick={() => handleImageClick(url.URL)}
                  />
                </div>
              </div>
            ))}
          </div>
        </aside>
        <main className="col-lg-6">
          <div className="ps-lg-3">
            <h4 className="title text-dark">{productDetails.productName}</h4>
            <div className="mb-3">
              <span className="h5">
                &#8377; {productDetails.productPrice[0].finalProductPrice} (All
                Incl.)
              </span>
            </div>
            <p>{productDetails.metaDescription}</p>

            <div className="row">
              <dt className="col-4">Category</dt>
              <dd className="col-8">{category.categoryName}</dd>

              <dt className="col-4">Metal Type</dt>
              <dd className="col-8">{category.metalType.metalType}</dd>

              <dt className="col-4">Weight</dt>
              <dd className="col-8">{productDetails.weight} gm</dd>

              <dt className="col-4">Metal Purity</dt>
              <dd className="col-8">{category.metalType.metalFitness}</dd>

              <dt className="col-4">Sku Id</dt>
              <dd className="col-8">{productDetails.sku}</dd>
            </div>

            <hr />

            <div className="row">
              <div className="col-md-6 col-6">
                <label className="mb-2 d-block">Quantity</label>
                <div className="input-group mb-3" style={{ width: "100%" }}>
                  <button
                    className="btn btn-white border border-secondary px-3"
                    type="button"
                    id="button-addon1"
                    data-mdb-ripple-color="dark"
                    onClick={decreaseQuantity}
                  >
                    <i className="fas fa-minus">-</i>
                  </button>
                  <input
                    type="text"
                    className="form-control text-center border border-secondary"
                    placeholder="14"
                    value={quantityValue}
                    aria-label="Example text with button addon"
                    aria-describedby="button-addon1"
                  />
                  <button
                    className="btn btn-white border border-secondary px-3"
                    type="button"
                    id="button-addon2"
                    data-mdb-ripple-color="dark"
                    onClick={increaseQuantity}
                  >
                    <i className="fas fa-plus">+</i>
                  </button>
                </div>
              </div>
              <div class="col-md-6 col-6">
                <label className="mb-2 d-block">&nbsp;</label>
                <Button
                  style={{
                    backgroundColor: "#4A2A7D",
                    color: "white",
                    padding: "5px 5px",
                    borderRadius: "5px",
                    border: "none",
                    width: "100%",
                    height: "49%",
                  }}
                  onClick={handleBuyNow}
                >
                  Buy now
                </Button>
              </div>
            </div>
          </div>
        </main>
      </div>
      {/* <div
        className="row"
        style={{
          marginTop: "1rem",
          backgroundColor: "#dcdaff",
          height: "2.5rem",
          borderRadius: "5px",
        }}
      >
        <h5
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            color: "#4A2A7D",
          }}
        >
          Suggestions
        </h5>
      </div> */}
    </div>
  );
};

export default ProductDetails;
